.info-icon{
    margin-right: 10px;
}
.star{
    display:-webkit-inline-box;
}
.star p{
    margin-left:5px;
    font-size: 14px;
}
.main-welcome-head{
    font-size: 1.3rem;
    color: #000e5e;
    font-family: 'Poppins', sans-serif;
}