.RegModalOverly {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.RegSuccessContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70vh;
  text-align: center;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
}

.rightMark {
  position: absolute;
  top: -22%;
  background-color: #fff;
  border-radius: 50%;
  font-size: 105px;
  color: #00ff00;
}

.RegSuccessMessage {
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 38px;
}

.RegSuccessDescription {
  max-width: 600px;
  margin: 5px;
}

.RegSuccessDescription p {
  padding: 15px;
}