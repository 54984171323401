.socialIcn i {
    color: #fff;
    font-size: 20px;
    transition: .5s all;
}

.socialIcn i:hover {
    /* box-shadow: 0 0 50px 15px #efff7a; */
    color: #dcd2f0;
    transition: .5s all;
}

.socialIcn {
    margin-right: 16px;
}