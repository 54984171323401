.prgm-container {
  padding: 20px;
}

.search-bar {
  width: 90%;
  padding: 5px;
  margin: 0px 50px 30px 50px;
}

.filters-container {
  display: inline-flex;
  padding: 5px;

  margin: 0px 50px 50px 50px;
}

.filter {
  flex: 1;
  margin-right: 10px;
}

.filter label {
  display: flex;
}

.frontProgramCards-container {
  margin: 20px;
  /* display: grid; */
  /* grid-template-columns: repeat(auto-fill, minmax(312px, 1fr)); */
  /* gap: 20px; */
  display: flex;
  flex-wrap: wrap;
  background-color: #f5f5f5;
}

.frontPrgmLogo {
  /* border-radius: 50%; */
  overflow: hidden;
  display: flex;
  justify-content: center;
  height: 76px;
  width: 100%;
}

.frontPrgmLogo img {
  border-radius: 0px !important;
  object-fit: contain !important;
}

.programTitle {}

.frontProgramCard {
  /* height: 277px; */
  border: 1px solid #cccccc;
  border-radius: 10px;
  padding: 12px;
  background-color: #fff;
}

.frontProgramCard-heading {
  font-size: 1.05rem;
  text-align: center;
  margin-top: 2px;
  margin-bottom: 10px;
  color: #6941c6;
}

.frontProgramCard-heading img {
  width: 30px;
  height: 30px;
}

.frontProgramCard-image {
  padding: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  background-size: cover;
  object-fit: cover;
  width: 100%;
  height: 120px;
  border-radius: 15px;
  border: 3px;
  border-color: #6941c6;
  border-style: solid;
}

.PTitle {
  margin-bottom: 4px;
  color: #030303;
  font-weight: 500;
  font-size: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.pDescriptionText {
  color: #030303;
}

.pDescriptionDetails {
  height: 5rem;
  overflow: hidden;
}

.pDescriptionDetails,
.pDescriptionDetails * {
  font-size: 0.85rem !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.pId {
  color: #8a8a8c;
}

.researcherProfiles {
  display: flex;
  position: relative;
}

.profileResearcher2 {
  position: absolute;
  left: 10px;
}

.profileResearcher3 {
  position: absolute;
  left: 20px;
}

.profileResearcher4 {
  position: absolute;
  left: 30px;
}

.profileResearcher5 {
  width: 100%;
  height: 100%;
  background-color: #e1e1e1;
  position: absolute;
  left: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.pFrontStatus {
  border-radius: 8px;
  background-color: #ccf8dc;
  color: #00b31c;
  font-weight: 600;
  display: flex;
  justify-content: center;
  padding: 5px 25px;
}

.researcherProfiles>div {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  overflow: hidden;
}

.researcherProfiles img {
  height: 100%;
  width: 100%;
}

.frontProgramCard-image img {
  width: 95%;
  height: 95%;
  /* object-fit: cover; */
}

.frontProgramCard-title {
  margin-top: 10px;
  font-size: 1.1rem;
  color: #1a1463;
  text-align: center;
  font-weight: 500;
}

.frontProgramCard-description {
  margin-top: 10px;
  color: black;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* Limit to 3 lines */
  -webkit-box-orient: vertical;
  margin-bottom: 0;
}

.underline {
  height: 2px;
  background-color: #ccc;
  margin-bottom: 10px;
}

.frontProgramCard-underline {
  border-bottom: 2px solid #ccc;
  height: 165px;
}

.frontProgramCard-footer {
  text-align: right;
  font-style: normal;
  color: #999;
  margin: 0;
  font-size: 14px !important;
  height: 25px;
}

.header-option {
  background-color: #f9f9f9;
  color: black;
}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 412px) {
  .header-option .col-6 {
    width: 100%;
  }

  .frontProgramCards-container {
    flex-direction: column;
    margin: 8px;
  }

  .frontProgramCards-container .responsePrgmCard {
    width: 100%;
  }

  .prgmCardItem {
    flex-direction: row !important;
  }

  .uui_footer .container>.row {
    flex-direction: row;
  }

  .footerLogoBug {
    width: 94% !important;
    position: relative !important;
  }

  .uui-footer07_link-list {
    display: flex;
    flex-direction: column;
  }
}