.tableHeader{
  text-align: start;
}

.sequenceRow{
  text-align: center;
}
.gotoView{
  color: #5A78F0;
  font-weight: 600;
}