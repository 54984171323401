.devider::after, .devider::before{
    content: "";
    display: inline-block;
    width: 50%;
    height: 2px;
    position: relative;
    vertical-align: middle;
    background: red;
}
.text-black {
    color: black;
}
#logout h4 {
    letter-spacing: normal;
    font-weight: 500;
    font-size: 1.35rem;
    line-height: 1.5;
}
#logout .brand-name{
    color: blueviolet;
    font-weight: 600;
    line-height: 2.65rem;
}
#logout p{
    font-size: 14px;;
}