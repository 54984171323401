.demoTitleDiv {
	padding: 20px 36px;
}

.demoTitleDivTitle h5 {
	color: #2c0000;
}

.demoTitleDivTitle span {
	color: #22b14c;
	font-size: 16px;
}

.mainDivTopPart {
	padding: 0;
	display: flex;
	overflow: hidden;
	background-color: #fff;
	border-radius: 9px;
	box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
		rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
		rgba(0, 0, 0, 0.07) 0px 16px 16px;
}

.iBar {
	display: flex;
	padding: 0;
	border: 2px solid #5fa0ce;
	overflow: hidden;
	border-radius: 7px;
}

.iBarIconDiv {
	display: flex;
	width: 6%;
	padding: 13px;
	background-color: rgb(6, 120, 184);
	align-items: center;
	justify-content: center;
}

.iBarIconDiv i {
	color: #fff;
	font-size: 29px;
}

.flagRow {
	display: flex;
	justify-content: space-between;
}

.flagRowChild {
	margin-top: 5px;
}

.flagRowChildI {
	width: 32px;
	/* margin-right: 10px; */
	color: #0777b9;
}

.flagRowChildIStart {
	width: 30px;

}

.flagRowChildIStart>div {
	height: 20px;
	display: flex;
	font-size: 10px;
	background: #0777b9;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	color: white;
	width: 20px;
	margin-right: 10px;
}

.flagRowChildIHand {
	width: 30px;

}

.flagRowChildIHand>div {
	height: 20px;
	display: flex;
	font-size: 10px;
	background: #0777b9;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	color: white;
	width: 20px;
	margin-right: 10px;
}

.flagRowChildText {
	margin: 0;
}

.flagRowChildText p {
	color: #000;
	font-size: 15px;
	font-weight: 600;
	margin-bottom: .7rem;
	margin-right: 10px;
}

.flagRowChildText span {
	color: #787878;
	font-size: 13px;
	margin-bottom: .7rem;
	font-weight: 500;
}

.viewAgreementBtn {
	background-color: #6ab04c;
	color: #fff;
	border: 1px solid #e0e0e0;
	font-size: 12px;
	border-radius: 9px;
	padding: 8px 13px;
}

.manageBy {
	margin-top: 20px;
	display: flex;
}

.manageByImg {
	height: 20px;
	width: 20px;
	margin-right: 10px;
}

.manageByImg img {
	height: 100%;
	width: 100%;
}

.titlehr {
	margin-left: -35px;
	margin-right: -35px;
	border: 2px solid #0a0a00;
}

.submitReportBtn {
	background-color: #6ab04c;
	color: #fff;
	border-radius: 7px;
	padding: 8px 29px;
	font-weight: 600;
	cursor: pointer;
}

:is(.submitReportBtn, .viewAgreementBtn) {
	transition: 0.4s all;
}

:is(.submitReportBtn, .viewAgreementBtn):hover {
	background-color: #0878B8;
	transition: 0.4s all;
}

.demoTitleDiv2 {
	padding: 20px;
	z-index: 3;
	background-color: #a349a4;
}

.demoTextP {
	width: 15%;
	background-color: #d7e7f4;
	/* padding: 20px; */
	color: #000;
	border: 1px solid #000;
	border-radius: 3px;
}

.demoTextP p {
	margin-bottom: 0px;
}

.tabBarResearcher {
	background-color: transparent;
	border-radius: 0px;
}

.currTab {
	color: #000 !important;
	animation: 0.2s ease;
	background: none !important;
	border-bottom: 2px solid #fd6e0f !important;
}

.prgmTabLink {
	color: #585858;
	font-weight: 500;
	border-radius: 0px;
	background-color: inherit;
}

.prgmDescSpan {
	font-size: 14px;
}

.p1Level {
	border: 1px solid #d95654;
	padding: 2px 5px;
	background-color: #f8dfde;
	border-radius: 5px;
	color: #000;
	font-size: 13px;
	font-weight: 700;
}

.p2Level {
	border: 1px solid #ff9252;
	padding: 2px 5px;
	background-color: #fde7d6;
	border-radius: 5px;
	color: #8b0000;
	font-size: 13px;
	font-weight: 700;
}

.p3Level {
	border: 1px solid #f4c480;
	padding: 2px 5px;
	background-color: #fdf2e3;
	border-radius: 5px;
	color: #8b0000;
	font-size: 13px;
	font-weight: 700;
}

.p4Level {
	border: 1px solid #8bc454;
	padding: 2px 5px;
	background-color: #e4f3d5;
	border-radius: 5px;
	color: #8b0000;
	font-size: 13px;
	font-weight: 700;
}

.p5Level {
	border: 1px solid #c3c3c3;
	padding: 2px 5px;
	background-color: #c2c2c2;
	border-radius: 5px;
	color: #8b0000;
	font-size: 13px;
	font-weight: 700;
}

.moneyText {
	/* color: #000; */
	font-weight: 700;
}

.moneyText,
.ptext {
	color: #8b0000;
}

.pgrmSummTableP {

	width: 100%;
	border: 2px solid #e5e5e5;
	border-radius: 5px;
}

.pgrmSummTableP th {
	text-align: left;
}

.pgrmSummTableP tr {
	text-align: left;
}

.pgrmSummTableP tbody {
	color: #8b0000;
}

.p5submission {
	display: flex;
	align-items: center;
}

.p5submission i {
	color: #0178b8;
	margin-right: 10px;
}

.p5submission p {
	color: #8b0000;
	font-size: 14px;
	margin-top: 0px;
	margin-bottom: 0px;
}

.bugbusLink a {
	color: #4a52cf;
	font-size: 14px;
}

.prgmDescAttach {
	border-radius: 10px;
	margin-top: 15px;
	padding: 20px;
	border: 1px solid #d3d3d3;
}

.pgrmDescAttachFileName {
	margin-left: 10px;
	font-size: 12px;
	background-color: #e6e6e6;
	padding: 3px 8px;
	border-radius: 4px;
}

.fileDetails {
	display: flex;
	width: fit-content;
	margin-bottom: 20px;
}

.fileIcon {
	display: flex;
	align-items: center;
	font-size: 22px;
	margin-right: 15px;
}

.fileName {
	justify-content: center;
	display: flex;
	align-items: center;
}

.fileName p {
	color: #4977c2;
	margin-bottom: 0px;
	font-size: 15px;
	margin-right: 8px;
	font-weight: 600;
}

.fileName span {
	border: 4px solid #ff9900;
	border-radius: 6px;
	padding: 0px 7px;
}

.fileName i {
	font-size: 11px;
}

.rewardTableResearcher thead {
	background-color: #f7f7f7;
	color: #000;
}

.P1cls {
	font-size: 13px;
	color: #000;
	font-weight: 600;
	background-color: #f8dfde;
	padding: 2px 5px;
	border: 1px solid #d95c5c;
	border-radius: 2px;
}

.resolved {
	font-size: 13px;
	color: #000;
	font-weight: 600;
	background-color: #d6f5f1;
	padding: 2px 5px;
	border: 1px solid #01bfa5;
	border-radius: 2px;
}

/* program tracking */

.profilePic {
	height: 50px;
	width: 50px;
	overflow: hidden;
	border-radius: 50%;
	object-fit: cover;
}

.statustimeline {
	margin-bottom: 20px;
}

.statustimeline1 {
	display: flex;
	align-items: center;
}

.whatisthestatus {
	margin-left: 25px;
}

.status1 {
	font-size: 13px;
}

.displayName {
	font-weight: 600;
	color: #000;
	margin-right: 5px;
	font-size: 14px;
}

.displayParaStatus {
	margin-right: 5px;
}

.trackingStatus {
	background-color: #e0e3f5;
	padding-left: 8px;
	padding-right: 8px;
	font-size: 11px;
	color: #000;
	border: 1px solid #4859b2;
	border-radius: 4px;
	text-transform: capitalize;
}

/* rewards leaderboard */

.thisQuarter {
	border-radius: 5px;
	font-size: 14px;
	background-color: #def2d8;
	border: 1px solid #C9E9BD;
	padding: 5px 10px;
	color: #020202;
}

.quSelect option p {
	font-weight: 500;
}


.researcherLastSide {
	border: 2px solid #ccc;
	/* max-height: 350px; */
	margin-top: 20px;

	padding: 0;
}

.LastSideHeading {
	background-color: #f7f7f7;
	padding: 10px;


}

.LastSideMiddle p {
	text-align: center;
	margin-top: 5px;
	margin-bottom: 5px;
}

.LastSideLast {
	margin-top: 10px;
}