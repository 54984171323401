table td p{
    color: black;
    font-weight: 500;
}

.cmsPagesTable tr th{
    font-size: 0.8rem ;
    color: #000333 ;
    font-family: 'Poppins', sans-serif;
}

