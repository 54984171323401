.rescuerLogo {
	border-radius: 5px;
	height: 40px;
	width: 40px;
	overflow: hidden;
	object-fit: cover;
}

.rescuerLogo img {
	height: 100%;
	width: 100%;
	background-size: cover;
}

.statusNew span {
	padding: 5px 10px;
	color: white;
	font-weight: 600;
	font-size: 11px;
	background-color: #3ce0e2;
	border-radius: 5px;
}


.statusTriaged span {
	padding: 5px 10px;
	color: white;
	font-weight: 600;
	font-size: 11px;
	background-color: #ffae00;
	border-radius: 5px;
}
.statusAccepted span {
	padding: 5px 10px;
	color: white;
	font-weight: 600;
	font-size: 11px;
	background-color: #82d616;
	border-radius: 5px;
}
.statusRejected span {
	padding: 5px 10px;
	color: white;
	font-weight: 600;
	font-size: 11px;
	background-color: #ea0606;
	border-radius: 5px;
}

.file-upload {
	text-align: center;

	padding: 1.5rem;
	position: relative;
	cursor: pointer;
}
.file-upload p {
	font-size: 0.87rem;
	margin-top: 10px;
	color: #bbcada;
}
.file-upload input {
	display: block;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0;
	cursor: pointer;
}

.languageFrameworkDiv {
	display: flex;
	flex-wrap: wrap;
}

.languageFrameworkDiv input {
	border: none;
	background-color: transparent;
	outline: none;
	width: 100%;
}
.languageFrameworkDiv input::placeholder{
	color: #adb5bd;
  opacity: 1;
}

.LfElement {
	background-color: #3a416f;
	color: #fff;
	padding: 0 10px;
	border-radius: 6px;
	margin: 0 10px 10px 0;
}
