.weekly24main {
    height: 50px;
    width: 100%;
    border-radius: 31px;
    position: relative;
    overflow: hidden;
    border: 2px solid #E3E3E5;
    padding: 0 16px;
}

.weekly24 {
    border: none;
    height: 100%;
    width: 100%;
    padding: 0 18px;
    font-size: 18px;
    color: #2D2D2D;
    font-weight: 500;
    text-transform: capitalize;
}

.weekly24:focus {
    border: none;
    outline: none;
}

.weekly24>option {
    text-transform: capitalize;
}

.weeklyDownIcn {
    position: absolute;
    top: 13px;
    right: 12px;
}