.programResearcher {
    padding: 30px;
    width: 100%;
    background-color: white;
    border-radius: 30px;
    box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05);
    ;
}

.statusTriaged span {
    padding: 5px 10px;
    color: white;
    font-weight: 600;
    font-size: 11px;
    background-color: #ffae00;
    border-radius: 5px;
}

.statusAccepted span {
    padding: 5px 10px;
    color: white;
    font-weight: 600;
    font-size: 11px;
    background-color: #82d616;
    border-radius: 5px;
}

.statusRejected span {
    padding: 5px 10px;
    color: white;
    font-weight: 600;
    font-size: 11px;
    background-color: #ea0606;
    border-radius: 5px;
}


.researcherLeftSide .partInviteBox {
    margin: 0 18px;
    border: 1px solid #dee2e6;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.partInviteBox div {

    display: flex;
    justify-content: center;
    align-items: center;
}

.partInviteBox h5 {
    font-size: 17px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.partInviteBox hr {
    margin: 0;
    height: 1px;
    background-color: #dee2e6;
    border: 1px solid black;
}


.researcherRightSide h6 {
    font-size: 25px;
    font-weight: 500;
}

.researcherTableTh {
    font-size: 17px !important;
    color: #6f42c1 !important;
}

.researcherProfilePhoto {
    position: relative;
    overflow: hidden;
    background-size: cover;
    height: 50px;
    width: 50px;
    background-color: blue;
    border-radius: 50%;
}

.researcherProfilePhoto img {
    height: 50px;
    width: 50px;
}

/* program summary */
.programSummary .uploadedLogo {
    height: 184px;
    width: 184px;
    overflow: hidden;
    background-size: cover;
    background-color: #fff;
    border-radius: 20px;
    margin-bottom: 20px;
}

.uploadedLogo img {
    height: 100%;
    width: 100%;
}



.basicDetailsStatus {
    color: #fff;
}


.demoTitleItemCount {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #adb5bd;
    border-radius: 50%;
    width: 25px;
    height: 25px;
}

.demoTitleItemCount span {
    color: #212529;
}

.demoTitleBox hr {
    height: 1px;
    border: 1px solid #6c757d;
}

.demoTitleBox {
    height: 100%;
}

.statsBox hr {
    height: 1px;
    border: 1px solid #fff;
    margin: 0;
}

.statsBox h5 {
    color: #fff;
}

.statsBox {
    color: #fff;
}

.statsBox p {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 500;

}

.yourAssignedLogo {
    height: 50px;
    width: 54px;
    border-radius: 50%;
    overflow: hidden;
    background-size: cover;
}

.yourAssignedLogo img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.recentActivity hr {
    margin: 0;
    height: 1px;
    border: 1px solid #6c757d;
}

.dottedHr div {
    height: 77%;
    margin-top: 40px;
    width: 1px;
    border-left: 2px dotted #6c757d;

}

.recentActivityPhoto div {
    overflow: hidden;
    background-color: black;
    height: 50px;
    width: 50px;
    border-radius: 50%;
}

.recentActivityPhoto div img {
    height: 100%;
    width: 100%;
    background-size: cover;
}

.activityBox {
    display: flex;

    margin-left: -85px;
}

/* program submission */

.statusBox {
    height: 50px;
    display: flex;
    justify-content: left;
    background-color: #D7D7D7;
    border: 1px solid #C3C3C3;
}

.scrollableLeftSide {
    /* height: 100%; */
    height: calc(100vh - 100px);
    overflow-y: auto;
    padding-right: 15px;
}

.scrollableLeftSide::-webkit-scrollbar {
    width: 15px;
    background-color: transparent;

}

.scrollableLeftSide:hover::-webkit-scrollbar {
    background-color: #f1f1f1;
}

.scrollableLeftSide::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 20px;
    height: 5%;

}

.scrollableLeftSide::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.scrollableLeftSide:hover::-webkit-scrollbar-thumb {
    background: #888;
}

.statusBoxItems {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}

.statusBoxItems>p {
    margin-bottom: 0;
    margin-right: 10px;
}

.statusBoxItems div {

    background-color: #C8BFE7;
    color: #000;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.statusBoxItems div p {
    margin-bottom: 0;
}

.submissionTagDiv {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.tagBox {
    margin-left: 15px;
    background-color: #D9EAFF;
    padding: 3px;
    font-size: 15px;
    border-radius: 5px;
}

.tagBox span {
    margin-bottom: 0;
    font-weight: 500;
    color: #000;
}

.submissionTagTitle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.submissionTagTitle p {
    margin-bottom: 0;
    color: #000;
    font-weight: 600;
    margin-left: 20px;
}

.crossMark {
    padding: 5px 10px;
    background-color: #ECEDEF;
    border-radius: 5px;
}

.resultsMatchingText {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-weight: 600;
    margin-bottom: 0;
}

.resultsMatchingText button {
    background-color: transparent;
    border: none;
    color: #0000FF;
    font-weight: 600;
}

.demoLeftItemBox {
    border-radius: 10px;
    margin-bottom: 30px;
    margin-right: 20px;
    padding: 20px;
    border: 1px solid #CDCDCD;
    cursor: pointer;
    box-shadow: 0px 0px 1px 4px #d5d5d519;

}

.demoLeftItemBox>p {
    font-size: 15px;
    color: #205EBD;
    font-weight: 600;
}

.taxonomyDiv {
    padding: 5px 10px;
    border: 1px solid #D5D5D5;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.taxonomyDiv>p {
    margin-bottom: 0;
    color: #000;
    font-weight: 600;
}

.demoItemboxDetailsText {
    font-weight: 600;
    font-size: 13px;
}

.top100 {
    font-size: 10px;
    background-color: #DCEBF5;
    padding: 3px 5px;
    border-radius: 5px;
}


.rightSideDemoTitle p {
    font-size: 20px;
    font-weight: 700;
    color: #000;
}

.submissionStatus {
    margin-right: 20px;
    border: 1px solid #D5D5D5;
    padding: 10px;

}

/* .submissionStatus span {
    color: #000;
    font-weight: 600;
} */

.detailsDemoDetails button {
    background-color: transparent;
    border: none;
    color: #0000FF;
    font-weight: 600;
}

.fourOptionBox {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 10px;
}

.fourOptionBox span {

    text-wrap: nowrap;
}

.fourOptionBox span>b {
    margin-right: 10px;
    color: #000;
}

.addRewardBtn {
    margin-right: 20px;
    background-color: #FBFBFB;
    color: #8E8E8E;
    padding: 5px 10px;
    border: 1px solid #F1F1F1;
    border-radius: 10px;
    font-weight: 600;
}

.markasDuplicateBtn {
    background-color: #ECEDEF;
    color: #000;
}

.assignBtn {
    background-color: #ECEDEF;
    color: #000;
}

.threeDotBtn {
    background-color: #ECEDEF;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    font-weight: 700;
    padding: 2px 10px;

}

.threeDotBtn span {
    vertical-align: middle;
    margin-bottom: 5px;
}

.assignee i {
    margin-left: 10px;
}

.IntegrationHeading {
    color: #4F5256;
    font-weight: 600;
    font-size: 20px;
}

.integrationImg {
    margin-right: 10px;
    height: 30px;
    width: 25px;
    border-radius: 5px;
    background-color: blue;
}

.submissionDescription {
    color: #000;
    font-weight: 600;
    margin-bottom: 5px;
}

.loremTextSubmissionDescription {
    color: #000;
    font-size: 13px;
    font-weight: 500;
    text-align: justify;
}

.loremTextSubmissionDescription ul {
    list-style: none;
    padding-left: 0;
}

.commentLogo {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #0000FF;
}

/* program rewards */

.timeFrameBox {
    display: flex;

    border: 1px solid #DDDEDF;
    padding: 0px 0px;
    border-radius: 5px;
}

.timeFrameElement {
    cursor: pointer;
    color: #000;
    font-weight: 600;
    background-color: #ECEDEF;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    border-right: 1px solid #DDDEDF;
}

.allTimeElement {
    border: none;
    background-color: #B8BCC4;
}

.pdiv {
    margin-right: 20px;
    background-color: #E4F3D5;
    padding: 5px 10px;
    border: 1px solid #6BB41F;
    border-radius: 5px;
}

.cancelledStatus {
    color: #000;
    background-color: #FDE2E0;
    border: 1px solid #F45A4E;
    border-radius: 5px;
}

.processingStatus {
    color: #000;
    background-color: #EFEFEF;
    border-radius: 5px;
    padding: .31rem .5rem;
}

.completeStatus {
    color: #fff;
    background-color: #51af37;
    border-radius: 5px;
    padding: .31rem .5rem;
}

.Up-part {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 55px;
}

.btn-part {
    margin-left: 25px;

}

.btn-part button {
    background-color: #2A6ECF;
    color: white;
}

.At-sts {
    color: #6665C1;
}

.programSubmissionDescLeft {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 1em;
}

.text-overflow-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 40ch;
}

.submissionStatus {
    color: #000;
    font-weight: 600;
    padding: 5px 15px 5px;
    text-wrap: nowrap;
    border-radius: 10px;
    outline: none;
    box-shadow: 0px 0px 2px 1px #00000032;
}

.submissionStatusrejected {
    color: #fff;
    background-color: #c42a0b;
    box-shadow: 0px 0px 2px 1px #c42a0b;
}

.submissionStatustriaged {
    color: #fff;
    background-color: #fa5f69;
    box-shadow: 0px 0px 2px 1px #fa5f69;
}

.submissionStatusreview,
.submissionStatusunresolved {
    color: #fff;
    background-color: #54a0ff;
    box-shadow: 0px 0px 2px 1px #54a0ff;
}

.submissionStatusresolved {
    color: #fff;
    background-color: #5af064;
    box-shadow: 0px 0px 2px 1px #5af064;
}

.submissionStatusnew {
    color: #fff;
    background-color: #fbaf19;
    box-shadow: 0px 0px 2px 1px #fbaf19;
}