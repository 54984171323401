.dragDropModal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.dragDropModalBody {
	background: white;
	padding: 20px;
	border-radius: 8px;
	width: 50%;
	height: 60%;

}

.dragDropArea {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}

.dragDropModalBodyTop {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 40px;
	border-bottom: solid 1px #ccc;
}

.dragDropArea {
	border: 2px dashed #ccc;
	padding: 20px;
	cursor: pointer;
}

.dragDropArea p {
	margin-left: 60px;
}

.dragDropModalBodyLastPart {
	display: flex;
	justify-content: flex-end;
	gap: 5px;
}

.dragDropBtnClose,
.dragDropBtnSubmit {
	padding: 10px 20px;
	cursor: pointer;
}

.FooterDragFile {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-top: solid 1px #CCC;
	margin-top: 60px;
}



.ModalSubHeading {
	display: flex;
	justify-content: space-between;

	margin: 0 auto;
}

.vtxModalcontent {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.vtxModalcontent>div {
	flex: 1;

}

.vtxModalcontentLeft {
	display: flex;
	justify-content: center;
	align-items: center;
}