.dotted {
    border: 4px dashed #dddddd !important;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
/* This selector targets the editable element (excluding comments). */
.ck-editor__editable_inline:not(.ck-comment__input *) {
    height: 160px;
    overflow-y: auto;
}
.btn:disabled{
    background-color: #ddd !important;
}

.program-select{
    border-radius: 0;
}

.total-programs{
    display: flex;
    justify-content: center;
    align-items: center;
}
.total-programs h5{
    color: black;
    margin: 0;
}
.total-programs span{
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-weight: 500;
    height: 35px;
    font-size: 20px;
    font-weight: 500;
    margin-left: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.customer-name-status{
    margin-top: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
}







.customer-name-status hr{
    width: 80%;
    
   display:block;
    border:none;
    color:white;
    height:1px;
    background:black;
    background: -webkit-gradient(radial, 50% 50%, 0, 50% 50%, 350, from(#000), to(#fff));
}

.notice{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: #e9ecef;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 43px;
}




.form-select::after{
    content: "Aa";
}
.form-select::before{
    content: "Aa";
}


