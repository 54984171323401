.avater {
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	align-items: center;
}
.avater img {
	border-radius: 50%;
	border: 3px solid #ddd;
}
.avater span {
	font-weight: 600;
	font-size: 16px;
	color: #344767;
}

.lockScreenEyePass {
	position: absolute;
	top: 7px;
	right: 12px;
    cursor: pointer;
}
