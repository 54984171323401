.crossIndustryGrid {
  grid-column-gap: 2rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  justify-items: center;
  display: grid;
}
@media only screen and (max-width: 412px) {
  .uui-page-padding-3 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .uui-page-padding-4{
	padding-left: 10px !important;
	padding-right: 10px !important;
  }
  /* .custom-next{
	pos
  } */
}
