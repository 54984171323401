.outsideBorder {
	object-fit: cover;
	overflow: hidden;
	height: 37px;
	width: 37px;
	border: 1px solid #b4ebf8;
	border-radius: 50%;

	display: flex;
	justify-content: center;
	align-items: center;
}

.customerProfilePic {
	object-fit: cover;
	overflow: hidden;
	height: 36px;
	width: 36px;
	border-radius: 50%;
}

.customerProfilePic img {
	background-size: cover;
	overflow: hidden;
	height: 100%;
	width: 100%;
	border-radius: 50%;
}

.programStsTxt {
	text-align: center;
	margin-bottom: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
	border-top-left-radius: 1rem;
	border-top-right-radius: 1rem;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}

.fourStatusDiv {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.researcherProfile table tr th {
	font-size: 12px;
	padding: 0;
}

.AggregateInnerDiv {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}