/* Existing styles */
.cardHeader {
	font-weight: 700;
}

.rescuerLogo {
	border-radius: 5px;
	height: 40px;
	width: 40px;
	overflow: hidden;
	object-fit: cover;
}

.rescuerLogo img {
	height: 100%;
	width: 100%;
	background-size: cover;
}

.statusNew span {
	padding: 5px 10px;
	color: white;
	font-weight: 600;
	font-size: 11px;
	background-color: #3ce0e2;
	border-radius: 5px;
}

.metricStatElement p,
.metricStatElement span {
	color: #272727;
	font-weight: 600;
	font-size: 12px;
}

.statusTriaged span {
	padding: 5px 10px;
	color: white;
	font-weight: 600;
	font-size: 11px;
	background-color: #ffae00;
	border-radius: 5px;
}

.statusAccepted span {
	padding: 5px 10px;
	color: white;
	font-weight: 600;
	font-size: 11px;
	background-color: #82d616;
	border-radius: 5px;
}

.statusRejected span {
	padding: 5px 10px;
	color: white;
	font-weight: 600;
	font-size: 11px;
	background-color: #ea0606;
	border-radius: 5px;
}

.file-upload {
	text-align: center;
	padding: 1.5rem;
	position: relative;
	cursor: pointer;
}

.file-upload p {
	font-size: 0.87rem;
	margin-top: 10px;
	color: #bbcada;
}

.file-upload input {
	display: block;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0;
	cursor: pointer;
}

.languageFrameworkDiv {
	display: flex;
	flex-wrap: wrap;
}

.languageFrameworkDiv input {
	border: none;
	background-color: transparent;
	outline: none;
	width: 100%;
}

.languageFrameworkDiv input::placeholder {
	color: #adb5bd;
	opacity: 1;
}

.LfElement {
	background-color: #3a416f;
	color: #fff;
	padding: 0 10px;
	border-radius: 6px;
	margin: 0 10px 10px 0;
}

.PaymentModal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.PaymentModal-content {
	background-color: white;
	border-radius: 10px;
	width: 80%;
	max-width: 400px;
	padding-top: 20px;
}

.PaymentModal-Border {
	margin: 15px;
	border: 2px solid black;
	position: relative;
}

.PaymentModal-header {
	display: grid;
	justify-content: center;
	align-items: center;
	margin-bottom: 0;
	padding-bottom: 0;
	text-align: center;
	margin-top: 35px;
}

.PaymentModal-logo {
	width: 50px;
	height: 50px;
	position: absolute;
	right: 0;
	left: 0;
	top: -28px;
	margin: auto;
	display: flex;
	justify-content: center;
	margin-bottom: 10px;
}

.PaymentModal-group {
	margin-top: 12px;
}

.PaymentModal-group label {
	display: block;
	margin-bottom: 5px;
}

.PaymentModal-group input,
.PaymentModal-group textarea {
	width: 100%;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
}

.PaymentModal-btn {
	display: flex;
	justify-content: flex-end;
}

.PaymentModal-Cbutton {
	background-color: #ccc;
	color: black;
	border: none;
	padding: 5px 25px;
	border-radius: 5px;
	margin-right: 10px;
}

.PaymentModal-Pbutton {
	background-color: green;
	color: white;
	border: none;
	padding: 5px 35px;
	border-radius: 5px;
}

.forFooterBorder {
	border-bottom: 1px solid #ccc;
}

.CSdiv {
	background-color: #ccc;
	width: 100%;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	color: black;
}

.CSdiv span {
	background-color: lightgreen;
	border: 1px solid green;
}

/* Responsive styles */
@media (max-width: 768px) {
	.row {
		flex-direction: column;
	}

	.button-row {
		flex-direction: column;
		align-items: flex-start;
	}

	.card {
		margin-bottom: 20px;
	}

	.card-body .row {
		flex-direction: row;
		text-align: center;
		align-items: center;
	}

	.card-body .col-7,
	.card-body .col-5 {
		width: 50%;
		text-align: center;
		margin-bottom: 10px;
	}

	.table-responsive {
		overflow-x: auto;
	}

	.table {
		width: 100%;
		overflow-x: auto;
		display: block;
	}

	.card {
		height: auto;
		padding: 1px;
	}

	.card .card-body {
		padding: 1px;
	}

	.card .card-body .row .col-7,
	.card .card-body .row .col-5 {
		margin-bottom: 5px;
	}

	.card .card-body .row h5,
	.card .card-body .row p {
		font-size: 14px;
	}

	.card .card-body .row .icon {
		font-size: 24px;
	}

	.card .card-body {
		height: auto;
	}

	.card .card-body .row h5 {
		font-size: 16px;
	}

	.card .card-body .row p {
		font-size: 12px;
	}

	.card-body .row {
		align-items: center;
		justify-content: space-between;
	}

	.card-body .row .col-7,
	.card-body .row .col-5 {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.card-body .row .col-7 .icon,
	.card-body .row .col-5 .icon {
		margin-bottom: 0;
		margin-right: 5px;
	}
}