.priceButtons button {
	padding: 5px 26px;
	border-radius: 10px;
	background-color: transparent;
	border: 1px solid #e0e3e5;
}

.priceButtons button:focus {
	transition: 0.2s all;
	border: 1px solid #7f56d9;
	outline: none;
}

.inpEnterPriceDiv {
	position: relative;
}

.inpEnterPriceDiv input {
	padding-left: 30px;
	width: 350px;
	height: 35px;
	border: 1px solid #e0e3e5;

	border-radius: 5px;
}

.inpEnterPriceDiv input:focus {
	transition: 0.2s all;
	border: none;
	outline: 1px solid #7f56d9;
}

.inpEnterPriceDiv span {
	position: absolute;
	top: 5px;
	left: 8px;
}

.walletDashboardTable tr th {
	font-size: 0.8rem;
	color: #000333;
	font-family: "Poppins", sans-serif;
}

.body-part {
	display: grid;
	grid-template-columns: 50% 50%;

	margin: 10px;
	column-gap: 20px;
}

.right-part {
	background-color: white;
	display: grid;
	grid-template-columns: auto auto auto;
	border-radius: 15px;
	padding-top: 3%;
	box-shadow: 0 2px 3px #ccc;
}

.kyc-btn {
	width: 8rem;
	height: 2rem;
	background-color: #7f56da;
	color: white;
	font-weight: bold;
	text-align: center;
	padding-top: 3px;
}

.wallet-img {
	align-items: center;
	width: 80px;
	padding-left: 15px;
}

.wallet-details {
	display: inline-grid;
	padding-left: 25px;
	margin: 5%;
}

.company-details {
	display: grid;
	grid-template-rows: auto auto auto;
	margin: 2rem;
}

.wallet-Bal-details {
	margin: 2rem;
}

.left-part {
	background-color: white;
	display: grid;
	grid-template-columns: auto auto;
	border-radius: 15px;
	padding-top: 3%;
	box-shadow: 0 2px 3px #ccc;
	padding-left: 20px;
}

.leftFirst {
	margin: 5%;
}

.leftSecond {
	margin: 5%;
}

.docIcon {
	color: #7f56da;
}

/* new add */

.CAM-fieldBox {
	display: block;
	width: 100%;
}

.CAM-field {
	display: flex;
	padding-bottom: 10px;
	justify-content: flex-end;
}

.CAM-field label {
	width: 100%;
	font-size: 18px;
	color: #000;
	direction: rtl;
	padding-right: 10px;
}