.resourceMainSearch{
	width: 40%;
}
.resourceTypePart{
	width: 70%;
}


.searchInp {
    padding-right: 50px;
	padding-left: 20px;
	border-radius: 6px;
	border: 1px solid #d6d6d6;
	height: 50px;
	width: 100%;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.searchInp:focus {
	outline: none;
	transition: 0.3s all;
	border: 2px solid #a375c7;
}
.searchIcn {
	position: absolute;
	top: 10px;
	right: 18px;
	font-size: 20px;
}
.allDoc{
    
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.docImg{
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.docImg img{
    height: 60px;
}
.docDetails{
    padding: 10px 20px;
    text-align: center;
    font-size: 13px;
}

@media only screen and (max-width:412px){
	.resourceMainSearch{
		width: 95%;
	}
	.resourceTypePart{
		width: 100%;
		flex-direction: column;
		padding-right: 0px !important;
	}
	.resourceTypePart > .col-4{
		width: 100%;
	}
	div:has(.resourceTypePart){
		padding-right: 0px !important;
	}
}