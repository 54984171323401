.CommonTablePagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.toolbarContainer {
    margin-top: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.prePage {
    padding: 0px 8px;
    margin-right: 4px;
    transition: .3s all;
}

.prePage:hover {
    color: #fff;
    background-color: #7e49fb;
}

.pageActive {
    color: #fff;
    background-color: #6941C6;
}

.prePage i {
    margin-right: 8px;
}

.DotPage {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8392AB;
    padding: 0;
    /* margin: 0 3px; */
    border-radius: 50% !important;
    width: 20px;
    /* height: 36px; */
    font-size: 0.875rem;
}

@media (max-width: 768px) {
    .toolbarContainer {
        flex-direction: column;
        align-items: center;
    }

    .toolbarContainer>div {
        width: 100%;
        margin-bottom: 10px;
    }

    .input-group {
        width: 100% !important;
    }

    .prePage {
        padding: 0px 6px;
        font-size: 0.875rem;
        margin-right: 2px;
    }

    .DotPage {
        font-size: 0.75rem;
        width: 16px;
    }
}

@media (max-width: 480px) {
    .prePage {
        padding: 0px 4px;
        font-size: 0.75rem;
        margin-right: 1px;
    }

    .DotPage {
        font-size: 0.625rem;
        width: 14px;
    }
}