.ultimateChatbox {
	position: fixed;
	right: 25px;
	/* font-size: 30px; */
	bottom: 30px;
	transition: 0.5s all;
	z-index: 999999999;

	/* top: 10vh; */
	border-radius: 22px;
}



.mainChatbox {

	position: fixed;
	right: 25px;
	/* font-size: 30px; */
	bottom: 30px;
	transition: 0.5s all;
	z-index: 999999999;
	height: 600px;
	width: 313px;
	/* top: 10vh; */
	border-radius: 22px;
}

.chatMsgIcn {
	position: absolute;
	bottom: 25px;
	right: -21px;
	color: #fff;
	font-size: 20px;
	cursor: pointer;
	transition: 0.5s all;
	z-index: 9999999999;
	padding: 15px 19px;
	background-color: #7f56da;
	border-radius: 50%;
	width: 60px;
	height: 60px;
	/* box-shadow: rgba(17, 17, 26, 0.4) 0px 1px 0px,
		rgba(17, 17, 26, 0.4) 0px 8px 24px, rgba(17, 17, 26, 0.4) 0px 16px 48px; */
}

.chatMsgIcn:hover {
	color: #7f56da;
	background-color: white;
	box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
		rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.chatMsgIcn:disabled {
	background-color: #c6ccdc;
	cursor: not-allowed;
	fill: white;
	padding: 15px 19px;
	width: 60px;
	height: 60px;
}

.chatBoxFirst {
	top: 10vh;
	right: 25px;
	/* border: 80vh; */
	height: 600px;
	width: 313px;
	/* border: 2px solid #b1a4d0; */
	/* position: fixed; */
	z-index: 999;
	border-radius: 22px;
	overflow: hidden;
	background-color: #fff;
	transition: 0.5s all;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.chatBoxFirstTopPart {
	padding-left: 14px;
	padding-right: 14px;
	background-color: #7f56da;
	width: 100%;
	height: 13%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.chatBoxSecPart {
	background-color: #9675ea;
	height: 13%;
	padding: 15px;
	position: relative;
}

.chatBoxSecPart p {
	color: #fff;
	font-weight: 600;
	font-size: 11px;
	margin-bottom: 5px;
	margin-left: 10px;
}

/* .chatBoxSecPart::after{
	height: 100px;
	width: 100px;
	background-color: black;
} */
.conScBox {
	display: flex;
	justify-content: center;
	margin-top: 20px;
	padding-bottom: 7px;
	border-bottom: 1px solid #969696;
	width: 74%;
	color: #8f4aeb;
}

.conScBox span {
	margin-left: 10px;
	font-size: 14px;
	font-weight: 500;
}

.chatBoxThirdPart {
	position: relative;
	height: 87%;
	background-color: #fff;
	display: flex;
	padding-top: 10px;
	padding-left: 10px;
	padding-right: 10px;
	/* align-items: center; */
	flex-direction: column;
	overflow-y: scroll;
}

.chatBoxThirdPart::-webkit-scrollbar {
	display: none;
}

.thirdboxFirst {
	max-width: 80%;
	overflow: hidden;
	border: 1px solid #7f56d9;
	border-radius: 8px;
}

.botChat {
	display: flex;
	justify-content: flex-start;
	margin-top: 8px;
	margin-bottom: 8px;
}

.thirdBoxFirstInrP {
	padding: 10px;
	background-color: #fff;
	white-space: pre-wrap;
	font-size: 12px;
	margin-bottom: 0px;
	color: #286182;
}

.thirdBoxFirstInrP p {
	font-size: 12px;
	margin-bottom: 0px;
	color: #286182;
}

.thirdBoxSecInrP {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 5px;
	padding-bottom: 5px;
	border-top: 1px solid #e0e3e5;
}

/* .thirdBoxSecInrP:last-child {
	border-top: none;
} */

.thirdBoxSecInrP p {
	color: #3698fd;
	font-size: 13px;
	margin-bottom: 0px;
	margin-left: 5px;
}

.customerChat {
	display: flex;
	justify-content: flex-end;
	margin-top: 5px;
	margin-bottom: 5px;
}

.customerInnerMessage {
	max-width: 80%;
	overflow: hidden;
	border: 1px solid #7f56d9;
	border-radius: 8px;
	padding: 5px 10px;
	font-size: 12px;
	margin-bottom: 0px;
	background-color: #7f56d9;
	color: #fff;
}

.customerChatFst {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	border-radius: 20px;
	background: rgb(158, 119, 237);
	background: linear-gradient(125deg,
			rgba(158, 119, 237, 1) 0%,
			rgba(34, 213, 242, 0.9529061624649859) 100%);
}

.customerChatFst p {
	margin: 0px;
	color: #fff;
	margin-left: 3px;
}

.customerChatFst i {
	color: #000;
}

.msgTop {
	height: 80%;
	overflow-y: scroll;
}

.msgTop::-webkit-scrollbar {
	display: none;
}

.bottomInpDiv {
	position: absolute;
	/* height: 15%; */
	width: 96%;
	text-align: center;
	bottom: 0px;
	left: 12px;
	border-top: 1px solid #e9ebf0;
	background-color: #fff;
}

.buttonPreDefinedType {
	cursor: pointer;
}

.sndMsgInp {
	border: none;
	font-size: 14px;
	margin-top: 8px;
	padding: 3px;
	width: 100%;
	padding-right: 87px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.sndMsgInp:focus {
	outline: none;
	caret-color: #7f56da;
}

.poweredBy {
	font-size: 8px;
	font-weight: 600;
}

.poweredBy span {
	font-size: 11px;
	color: #7e52d1;
}

.inpNEW {
	width: 100%;
	height: 28px;
	margin-top: 10px;
	border: none;
	border-radius: 6px;
	padding: 10px;
}

.inpNEW:focus {
	border: none;
	outline: none;
	caret-color: #7f56da;
}

.InpNEWSubmitBtn {
	padding: 5px 12px;
	border: 1px solid #dae1e9;
	border-radius: 17px;
	font-size: 13px;
}

.innerMsgIcn {
	background-color: #7f56d9;
	position: absolute;
	font-size: 25px;
	right: 21px;
	bottom: -31px;
	padding: 3px 11px;
	border-radius: 50%;
	color: #fff;
	cursor: pointer;
}

.bt1int {
	opacity: 0;
	visibility: hidden;
	transition: visibility 1s, opacity 0.5s;
}

.visible {
	opacity: 1;
	visibility: visible;
}

/* chat text box */
.textMsg {
	position: relative;
	height: 87%;
	background-color: #eef5f2;
	overflow-y: scroll;
	padding-left: 10px;
	padding-right: 10px;
}

.textMsg::-webkit-scrollbar {
	display: none;
}

.textMsg img {
	position: absolute;
	left: 11%;
	top: 19%;
	opacity: 0.1;
	height: 200px;
}

.sendTextInpBox {
	height: 13%;
	position: relative;
	display: flex;
	justify-content: left;
}

.sendTextInpBox input {
	border-radius: 10px;
	margin-top: 10px;
	border: 1px solid #7f56da;
	width: 75%;
	height: 28px;
	margin-left: 16px;
	padding-left: 10px;
	font-size: 13px;
}

.sendTextInpBox input:focus {
	outline: none;
}

.sendTextInpBox i {
	color: #7f56da;
	cursor: pointer;
	position: absolute;
	right: 17px;
	font-size: 20px;
	top: 15px;
}

.customerTxt {
	display: flex;
	justify-content: flex-end;
	font-size: 12px;
	margin-top: 5px;
	margin-bottom: 5px;
	position: relative;
}

.customerTxt div {
	background-color: #7f56da;
	color: white;
	max-width: 65%;
	padding: 1px 5px;
	border-radius: 6px;
	margin-right: 8px;
}

.customerTxt i {
	position: absolute;
	font-size: 32px;
	transform: rotate(90deg);
	color: #7f56da;
	right: 0px;
	top: -5px;
	z-index: 1;
}

/* .customerTxt::after{
	content: "";
	height: 20px;
	width: 20px;
	background-color: black;
} */

.chatBotTxt {
	display: flex;
	justify-content: flex-start;
	font-size: 12px;
	margin-top: 5px;
	margin-bottom: 5px;
	position: relative;
}

.chatBotTxt div {
	background-color: #7f56da;
	color: white;
	max-width: 65%;
	padding: 1px 5px;
	border-radius: 6px;
	position: relative;
	margin-left: 8px;
}

.chatBotTxt i {
	position: absolute;
	font-size: 32px;
	transform: rotate(270deg);
	color: #7f56da;
	left: -1px;
	top: -5px;
	z-index: 1;
}

.turnOfNotification {
	width: 145px;
	position: absolute;
	font-size: 10px;
	background-color: #fff;
	color: #8390a8;
	padding: 10px;
	cursor: pointer;
	right: -19px;
	display: flex;
	border-radius: 8px;
	z-index: 9;
	justify-content: center;
	align-items: center;
	top: 31px;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
		rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.turnOfNotification span {
	color: #566073;
}

.minimizeChatBtn {
	color: rgb(255, 255, 255);
	margin-left: 20px;
	cursor: pointer;
	padding: 6px 11px;
	border-radius: 50%;
	background-color: transparent;
	transition: 0.5s all;
}

.minimizeChatBtn:hover,
.setNotificationStateBtn:hover {
	background-color: #00000050;
	transition: 0.5s all;
}

.setNotificationStateBtn {
	color: rgb(255, 255, 255);
	font-size: 22px;
	right: -22px;
	margin-left: 20px;
	cursor: pointer;
	padding: 0px 16px;
	border-radius: 50%;
	background-color: transparent;
	transition: 0.5s all;
	position: relative;
}

.PrevMsgBtn {
	display: flex;
	justify-content: center;
	font-size: 12px;
	margin-top: 5px;
	/* margin-bottom: 5px; */
	border: 1px solid #9da4b0;
	color: #9da4b0;
	padding: 1px 8px;
	border-radius: 11px;
	background-color: #fff;
}


.chatbotLoading {
	display: inline-flex;
	justify-content: flex-start;
	/* padding: 15px 17px 15px 5px;
	border: 1px solid #7f56d9; */
	border-radius: 8px;
	margin-top: 15px;
}

.dot {
	width: 8px;
	height: 8px;
	background-color: #7f56d9;
	border-radius: 50%;
	margin: 0 2px;
	animation: wave 1.5s infinite;
}

.dot:nth-child(2) {
	animation-delay: 0.1s;
}

.dot:nth-child(3) {
	animation-delay: 0.2s;
}

@keyframes wave {

	0%,
	60%,
	100% {
		transform: translateY(0);
	}

	30% {
		transform: translateY(-10px);
	}
}