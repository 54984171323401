.top-heading h3 {
  margin: 40px;
  color: #6941c6;
  letter-spacing: 0;
}

.app-container {
  display: flex;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.form-container,
.poster-container {
  width: 50%;
  /* Each container takes up 50% of the width */
  padding: 5px;
  box-sizing: border-box;
}

.form-container {
  background-color: #f9f9f9;
  border-right: 1px solid #9e9e9e;
  /* Add a border between form and poster */
  padding: 40px;
  margin-left: 30px;
}

.form-container h2 {
  margin-bottom: 10px;
  margin-top: 50px;
}

.form-container p {
  margin-bottom: 20px;
}

.form-container input,
.form-container select,
.form-container button {
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #f2c9c9;
  border-radius: 5px;
}

.bugbountySubmitBtn {
  background-color: #6941c6;
  color: white;
}

.form-container input[type="submit"] {
  background-color: #007bff;
  color: #ccc;
  cursor: pointer;
}

.poster-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.poster-container img {
  max-width: 100%;
  max-height: 100%;
}

.BB-footer {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.BB-footer-logo ul {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  align-items: center;
  list-style: none;
}

.BB-footer-logo ul li img {
  width: 120px;
  height: auto;
}

/* new add */
.BB-PhNo {
  display: grid;
  grid-template-columns: 80px auto;
}

.sidebar-rec-doc {
  overflow-y: scroll;
}

.researcherDocContainer {
  max-width: 1763px;
}

@media screen and (max-width: 768px) {
  .app-container {
    flex-direction: column;
    /* Stack containers vertically on smaller screens */
  }

  .form-container,
  .poster-container {
    width: 100%;
    /* Each container takes full width on smaller screens */
  }

  .form-container h2 {
    margin-top: 20px;
    /* Adjust top margin for spacing */
  }

  .form-container p {
    margin-bottom: 10px;
    /* Adjust bottom margin for spacing */
  }

  .form-container input,
  .form-container select,
  .form-container button {
    margin-bottom: 10px;
    /* Adjust bottom margin for spacing */
  }
}

@media only screen and (max-width: 412px) {
  .form-container {
    background-color: #f9f9f9;
    border-right: none;
    /* Add a border between form and poster */
    padding: 0px;
    margin-left: 0px;
  }

  .fawtab,
  .ourTeams {
    flex-direction: column;
  }

  .ourTeams>div {
    width: 100% !important;
  }

  .sidebar-rec-doc {
    height: fit-content;
    max-height: 600px;
  }

  .pageContentDiv {
    border-top: 1px solid #9200db;
    padding-top: 10px;
  }
}