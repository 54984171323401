#inputfilds input {
    height: 50px;
    font-size: 30px;
    text-align: center;
    width: 56px;
    margin-right: 1.75rem !important;
}
#verifybtn{
    height: 45px;
    margin-top: 2px;
}
.verify-text h5{
    font-size: 18px;
}
.font-weight-bolder {
    font-weight: 600 !important;
}
.btn-simple span {
    color: #67748e;
    text-transform: lowercase;
    font-weight: 600;
}