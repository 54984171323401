.roleandpermissiontable td{
    text-align: left;
    
}
.roleandpermissiontable tr th{
    font-size: 0.8rem ;
    color: #000333 ;
    font-family: 'Poppins', sans-serif;
}

/* .roleandpermissiontable .rolename{
    text-align: center;
} */

.roleNameHeader{
    text-align: start;
}

.roleIdHeader {
    text-align: start;
}

.roleDiv{
    margin: 30px 128px;
}

.roleFormLable{
    font-size: 14px;
    margin-bottom: 0;
    margin-right: 12px;
    text-wrap: nowrap;
}
.permissionTable {
    
    border-collapse: separate;
    border-spacing: 20px 1em;
}
.permissionHead{
    margin-top: 10px;
}
.permissionHead hr{
    height: 1px;
    
    border: 1px solid #adb5bd;
    margin: 10px 0;
}

.permissionTable thead th{
    width: 85px;
}
.permissionTable tbody tr td:nth-child(1){
    text-wrap: nowrap;
}


