/* General styles */
.programContainer {
  margin: 0 auto;
  padding: 0 15px;
}

.programContainer .total-programs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.programContainer .total-programs h5 {
  margin: 0;
}

.programContainer .total-programs span {
  font-size: 18px;
}

.backProgram .frontPrgmLogo,
.backProgram  .frontPrgmLogo img {
  border-radius: 0px !important;
}

.backPrgmResearcherProfile div{
  height: 30px;
  width: 30px;
}



/* Mobile styles */
@media (max-width: 768px) {
  .programContainer .total-programs {
    flex-direction: column;
  }

  .programContainer .col-lg-3,
  .programContainer .col-md-6,
  .programContainer .col-sm-12 {
    width: 100%;
    margin-bottom: 1em;
  }

  .programContainer .customer-name-status {
    margin-top: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px;
  }

  .programContainer .customer-name-status .col-5,
  .programContainer .customer-name-status .col-6,
  .programContainer .customer-name-status .col-7 {
    margin-left: 0;
    margin-bottom: 10px;
    width: 100%;
  }

  .programContainer .customer-name-status .row.mt-3.mb-3.w-100.d-flex.justify-content-between.align-items-center {
    flex-direction: column;
    align-items: flex-start;
  }

  .programContainer .customer-name-status .row.mb-3.w-100.d-flex.justify-content-center {
    flex-direction: column;
    align-items: flex-start;
  }

    .programContainer .search-container .row {
      flex-direction: column;
    }
  
    .programContainer .search-container .row>div {
      width: 100%;
      margin-bottom: 1em;
    }
  
    .programContainer .search-container input[type="text"] {
      width: 100%;
    }
  
    .programContainer .total-programs {
      width: 100%;
    }
  
    .programContainer .d-flex.justify-content-left,
    .programContainer .d-flex.justify-content-end {
      flex-direction: column;
      align-items: stretch;
    }

  .programContainer .customerStatus {
    padding: 2px 8px;
    font-size: 12px;
  }

  .programContainer .view-summary-btn {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 576px) {
  .programContainer .customer-name-status {
    flex-direction: column;
    align-items: flex-start;
  }

  .programContainer .aim-section {
    height: auto;
    width: 100%;
  }

  .programContainer .customer-name-status hr {
    width: 100%;
  }

  .programContainer .customer-name-status img {
    width: 100%;
    height: auto;
  }

  .programContainer .row.mb-3.w-100.d-flex.justify-content-center {
    flex-direction: column;
    align-items: flex-start;
  }

  .programContainer .row.d-flex.justify-content-center.align-items-center {
    flex-direction: column;
    align-items: flex-start;
  }

  .programContainer .mb-3.row.d-flex.justify-content-center.align-items-center {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .programContainer .customer-name-status .col-1,
  .programContainer .customer-name-status .col-3,
  .programContainer .customer-name-status .col-7 {
    width: auto;
    margin-bottom: 0;
    margin-right: 5px;
  }

  .programContainer .mb-3.row.d-flex.justify-content-center.align-items-center .col-1,
  .programContainer .mb-3.row.d-flex.justify-content-center.align-items-center .col-3,
  .programContainer .mb-3.row.d-flex.justify-content-center.align-items-center .col-7 {
    display: inline-flex;
    align-items: center;
  }

  .programContainer .mb-3.row.d-flex.justify-content-center.align-items-center span {
    display: inline-block;
    margin-left: 5px;
  }

  /* Added styles */
  .programContainer .customer-name-status .status-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  .programContainer .customer-name-status .status-container .customerName {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .programContainer .customer-name-status .status-container .status-badge {
    display: inline-block;
    background-color: #FBD38D;
    border: 1px solid #ddd;
    border-radius: 20px;
    padding: 2px 8px;
    font-size: 12px;
    text-transform: capitalize;
    margin-left: 10px;
    flex-shrink: 0;
  }

  .programContainer .customer-name-status .program-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }

  .programContainer .customer-name-status .program-details>div {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 5px;
  }

  .programContainer .customer-name-status .program-details .icon {
    margin-right: 5px;
  }

  .programContainer .customer-name-status .program-details .text {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
@media (max-width: 412px) {
  .programContainer > .col-3{
    width: 100%;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .customerPrgmFluid{
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .programContainer .customer-name-status .col-5, .programContainer .customer-name-status .col-6, .programContainer .customer-name-status .col-7 {
      margin-left: 0;
      margin-bottom: 10px;
      width: auto;
  }
  .prgmDashboardContainerFluid{
    padding: 0px;
  }
  .programContainer{
    padding: 0px;
  }
  .customer-name-status .topdiv{
    flex-direction: row !important;
  }
  .backPrgmBottomMain{
    display: flex;
    justify-content: center;
  }
  .backPrgmBottomDiv{
    flex-wrap: nowrap;
    justify-content: flex-start !important;
  }
  .backPrgmBottomDiv .col-1{
    width: 25% !important;
  }
  .backPrgmBottomDiv .col-3{
    width: 31% !important;
  }
  .view-summary-btn{
    font-size: 12px;
  }
  .responseCount{
    margin-bottom: 0px !important;
    margin-left: 25px !important;
  }
  .seePrgmMenu{
    margin-right: 0px !important;
  }
  .prgmCardItem{
    flex-wrap: nowrap;
  }
}