.custom-modal{
  width: 80% !important;
  /* Adjust the width as needed */
  /* min-width: 800px; */
  /* Example maximum width */
  height: 80% !important;
  /* Adjust the height as needed */
  /* min-height: 600px; */
  /* Example maximum height */
  /* margin: 0 auto !important; */
}