.stats-container {
	margin-top: 30px;
}

.stats-heading {
	margin-left: 40px;
	padding-top: 10px;
}

.report-page {
	display: flex;
	justify-content: space-around;
	margin: 10px 0 10px 10px;
	background-color: #f9f9f9;
}

.OptionNav {
	/* flex: 0 0 300px; */
	display: grid;
	/* justify-content: center; */
	width: 250px;
	/* max-height: 340px; */
	background-color: white;
	border-radius: 10px;
	padding: 0;
	margin: 20px;
	/* text-align: center; */
}

.OptionNav ul {
	list-style: none;
	padding: 0;
	/* text-align: center; */
}

.OptionNav li {
	cursor: pointer;
	padding: 20px;
	padding-left: 20px;
	border-radius: 5px;

	transition: background-color 0.3s ease;
	position: relative;
}

.OptionNav ul li {
	border: 1px solid #ccc;
}

.OptionNav li.active {
	border-right: 5px solid #FF6A00;
	font-weight: bold;
}

.OptionNav li:hover {
	color: black;
	background-color: #e0e0e0;
}

.details-section {
	flex: 1;
	padding-left: 20px;
	margin-top: 20px;
}

/* .details-section h2 {
    font-size: 24px;
    margin-bottom: 10px;
  } */

.details-section p {
	font-size: 16px;
	line-height: 1.5;
}

.userCard-details {
	margin-bottom: 20px;
	/* background-color: #f9f9f9; */
	display: flex;
	justify-content: space-around;
}

.profileDetails {
	margin-right: 20px;
	display: flex;
	justify-content: space-evenly;
	margin-bottom: 5px;
	align-items: center;
	width: 72%;
	border: 1px solid #e4e4e4;

	/* background-color: white; */
}

.namedetails {
	margin-top: 15px;
}

.namedetails div {
	display: inline-flex;
}

.namedetails h4 {
	font-weight: bolder;
}

.namedetails p {
	margin-left: 8px;
}

.pStatus {
	font-weight: bolder;
	color: rgb(11, 173, 237);
}

.imgDetails img {
	width: 80px;
	height: 80px;
	border-radius: 15px;
}

.socialDetails {
	display: flex;
	justify-content: center;
	align-items: center;
}

.socialDetails div {
	padding-left: 15px;
}

.markDetails {
	border: 1px solid #e4e4e4;
	border-radius: 10px;
	width: 25%;
}

.markDetails div:first-child {
	border-bottom: 1px solid #e4e4e4;
}

.markDetails div {
	width: 320px;
	padding-top: 10px;
	padding-left: 30px;
	padding-bottom: 0;

	text-align: left;
}

.reportCard-container {
	padding: 30px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	background-color: #ffffff;
	/* margin-right: 40px; */
}


.achiveHeading {
	display: flex;
	justify-content: space-between;
}

.achiveHeading h4 {
	color: #443437;
	padding-left: 20px;
	padding-top: 10px;
	font-weight: bolder;
}


.subHeading {
	color: #000022;
	margin-top: 5px;
	margin-left: 30px;
	font-size: larger;
}

.rankCard-container {
	display: grid;
	grid-template-columns: 25% 25% auto auto;
}

.StsReportRank-card {
	/* background-color: white; */
	width: 250px;
	border-radius: 10px;
	padding: 10px 0;
	margin-bottom: 15px;
	margin-left: 10px;
	border: 1px solid #EBEBEB;
	text-align: center;
}

.rankImg {
	display: flex;
	justify-content: center;
	align-items: center;
}

.rankImg img {
	width: 50px;
	height: 50px;
}

.StsReportRank-card h6 {
	padding-top: 10px;
	text-align: center;
}

.StsReportRank-card p {
	font-size: small;
}

.secondrankCard {
	display: grid;
	grid-template-columns: 25% 25% 25% 25%;
}

.LeaderboardCard {
	display: grid;
	grid-template-columns: 45% 45% auto;
}

.LeaderboardRank-card {
	background-color: white;
	border-radius: 5px;
	padding: 10px 0;
	margin-left: 10px;
	border: 1px solid #ccc;
	/* text-align: center; */
	display: inline-flex;
	padding-left: 15px;
}

.leaderboard-desc {
	display: grid;
	padding-left: 15px;
}


.pBar {
	width: 25%;
}


/* New Add 01/06/2024 */
.ReportFirstCardMark {
	display: grid;
	grid-template-columns: auto auto auto auto auto;
	margin: 0 10px;
	padding: 5px 15px;
	border: 1px solid #e4e4e4;
	margin-bottom: 5px;
}



/* For mobile devices */
@media screen and (max-width: 768px) {
	.stats-heading {
		margin-left: 0;
		/* Reset left margin */
		text-align: center;
		/* Center align */
	}

	.report-page {
		flex-direction: column;
		/* Stack items vertically */
		margin: 10px;
		/* Adjust margins */
	}

	.navbar {
		width: 100%;
		/* Full width */
		max-width: none;
		/* Remove max-width */
		margin: 0;
		/* Reset margin */
	}

	.details-section {
		padding-left: 0;
		margin-top: 10px;
		/* Adjust margin */
	}

	.profileDetails {
		flex-direction: column;
		/* Stack items vertically */
		align-items: center;
		/* Center align */
	}

	.markDetails div {
		width: 120px;
	}

	.imgDetails img {
		margin-bottom: 10px;
		/* Adjust margin */
	}

	.firstMark,
	.secondMark {
		flex-direction: column;
		/* Stack items vertically */
	}

	.firstMark div,
	.secondMark div {
		border-left: none;
		/* Remove left border */
		padding: 5px 0;
		/* Adjust padding */
		text-align: center;
		/* Center align */
	}

	.rankCard-container {
		flex-direction: column;
	}

	.rank-card {
		margin-left: 0;
	}
}

.pathStas {
	margin-top: 5px;
	margin-left: 50px;
	display: inline-flex;
}