.dotted {
  border: 4px dashed #dddddd !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* This selector targets the editable element (excluding comments). */
.ck-editor__editable_inline:not(.ck-comment__input *) {
  height: 160px;
  overflow-y: auto;
}

.btn:disabled {
  background-color: #ddd !important;
}

.program-select {
  border-radius: 0;
}

.program-select option {
  padding-top: 30px;
  padding-bottom: 8px;
}

.filterPrgm1 {
  width: 100%;
  position: absolute;
  background-color: #fff;
  top: 44px;
  border: 1px solid #000;
}

.filterPrgm1Opt {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.filterPrgm1Opt:hover {
  background-color: blue;
  color: #fff;
}

.total-programs {
  display: flex;
  justify-content: center;
  align-items: center;
}

.total-programs h5 {
  color: black;
  margin: 0;
}

.total-programs span {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-weight: 500;
  height: 35px;
  font-size: 20px;
  font-weight: 500;
  margin-left: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.customer-name-status {
  margin-top: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
}

.customer-name-status .col-5,
.customer-name-status .col-6 .col-7 {
  margin-left: 10px;
  /* margin-bottom: 10px; */
  margin-top: 10px;
  /* padding: 5px 10px; */
  /* border:1px solid #ddd !important; */
}

.customerName {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  text-wrap: nowrap;
}

.customerStatus {
  color: #000;
  font-weight: 600;
  padding: 5px 15px 5px;
  text-wrap: nowrap;
}

.customerStsApproved {
  color: #fff;
  background-color: #6ab04c;
}

.customerStsPending {
  color: #fff;
  background-color: #fa5f69;
}

.customerStsHold {
  color: #fff;
  background-color: #54a0ff;
}

.customerStsClosed {
  color: #fff;
  background-color: #5a78f0;
}

.customerInProgress {
  color: #fff;
  background-color: #fbaf19;
}

.aim-section {
  border: 4px solid #7f56d9;
  /* margin: 10px 20px; */
  border-radius: 15px;
  height: 130px;
}

.aim-section img {
  object-fit: contain;
  height: 94%;
}

.bug-bounty-demo {
  width: 100%;
  margin-bottom: 15px !important;
  /* border-radius: 40%; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.customer-name-status hr {
  width: 80%;

  display: block;
  border: none;
  color: white;
  height: 1px;
  background: black;
  background: -webkit-gradient(
    radial,
    50% 50%,
    0,
    50% 50%,
    350,
    from(#000),
    to(#fff)
  );
}

.customer-name-status hr:first-child {
  margin-bottom: 6px;
}

.dropMenu {
  border: 1px solid rgb(240, 221, 221);
  padding-top: 0px;
  overflow: hidden;
  padding-bottom: 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.notice {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #e9ecef;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 43px;
}

.view-summary-btn {
  border: 2px solid #9b3dd9;
  background-color: #7f56da;
  color: #fff;
  white-space: nowrap;
  padding: 5px 20px;
  border-radius: 10px;
  transition: 0.5s all;
  display: inline-block;
  width: auto;
}

.view-summary-btn:hover {
  transition: 0.5s all;
  background-color: #7f56da80;
  color: #000;
}

.form-select::after {
  content: "Aa";
}

.form-select::before {
  content: "Aa";
}

.bug-bounty-demo span {
  color: #000;
}

.inpPriority {
  text-align: center;
  color: #000;
  font-weight: 600;
}

.inpPriority:focus {
  border-color: #d2d6da;
  outline: 0;
  box-shadow: none;
}

.counterProgram {
  border-radius: 50%;
  background-color: #7f56da;
  color: #fff;
  margin-left: 1em;
  padding: 4px;
  width: 25px;
  height: 25px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}



