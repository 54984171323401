@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&family=Poppins:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700&family=Urbanist:wght@100;200;300;400;500;600;700;800&display=swap");

.main-content {
	background-color: rgb(245, 245, 251);
}

.bg-gradient-dark {
	/* background-image: linear-gradient(310deg, #ccbeec 0%, #6941c6 100%) !important; */
	background-image: linear-gradient(310deg,
			#7f56d9 0%,
			#9e77ed 100%) !important;
}

.dropdown-toggle::after {
	content: none;
}

.subadminDashboardTable tr th {
	font-size: 0.8rem;
	color: #000333;
	font-family: "Poppins", sans-serif;
}

#adduser {
	font-size: 0.9rem;
}

.changeStatus {
	width: 100vw;
	height: 100vh;
	background-color: #00033350;
	position: fixed;
	top: 0px;
	left: 0;
	z-index: 9999999999;
	display: flex;
	justify-content: center;
	align-items: center;
}

.changeStatusBody {
	height: auto;
	width: 25%;
	background-color: #fff;
	border-radius: 8px;
}

.changeStatusBodyTop {
	margin-top: 20px;
	text-align: center;
	height: 60px;
	/* font-size: 50px; */
	color: #7f54df;
}

.changeStatusBodyTop i {
	font-size: 40px;
}

.changeStatusBodyTop p {
	color: #4b5467;
	font-size: 13px;
	font-weight: 500;
}

.changeStatusBodySecPart {
	display: flex;
	justify-content: center;
	margin-top: 8px;
}

.changeStatusBodySecPart select {
	width: 35%;
	/* margin-top: 10px; */
	font-weight: 600;
	padding: 5px;
	text-align: center;
	border-radius: 8px;
	background-color: #f5f1fb;
	border: 1px solid #9b77db;
}

.changeStatusBodySecPart select:focus {
	outline: none;
}

.changeStatusBodyThirdPart {
	margin-top: 10px;
	padding: 0px 10px;
}

.changeStatusBodyThirdPart p {
	margin: 20px;
	font-size: 20px;
	font-weight: 600;
	/* line-height: 1.2; */
	text-align: center;
	color: #414141;
}

.changeStatusBodyLastPart {
	display: flex;
	justify-content: center;
	margin-top: 30px;
	padding-left: 40px;
	padding-right: 40px;
	margin-bottom: 20px;
}

.changeStatusBtnClose,
.changeStatusBtnSubmit {

	width: 28%;
	padding: 8px;
	margin-right: 15px;
	font-size: 14px;
	font-weight: 600;
	border-radius: 8px;
}

.changeStatusBtnSubmit {
	background-color: #4caf50;
	color: #fff;
}

.changeStatusBtnClose {
	background-color: #ff5722;
	color: #000;
}

.PaidBtn span {
	padding: 5px 10px;
	color: white;
	font-weight: 600;
	font-size: 11px;
	background-color: greenyellow;
	border-radius: 5px;
}

.UnPaidBtn span {
	padding: 5px 10px;
	color: white;
	font-weight: 600;
	font-size: 11px;
	background-color: #3ce0e2;
	border-radius: 5px;
}