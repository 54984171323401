.adminReportDashboardTable tr th {
    font-size: 0.8rem;
    color: #000333;
    font-family: 'Poppins', sans-serif;
}

.report {
    padding: 2rem 1rem;
}

.report .sub-title {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 600;
}

.report>div {
    font-size: 1.125rem;
    margin-bottom: 2rem;
}

.report div {
    font-size: 1.125rem;
    margin-bottom: .1rem;
}

.report th {
    background-color: #7f56d9;
    color: #fff;
    padding: 1rem;
    border: 3px solid #fff;
}

.report td {
    text-align: start;
    padding: .2rem;
    padding-top: .625rem;
    background-color: #FFF5E6;
    border: 3px solid #fff;
    vertical-align: text-top;
}

.report table {
    margin: 1rem 0;
}