.outsideBorder {
	object-fit: cover;
	overflow: hidden;
	height: 37px;
	width: 37px;
	border: 1px solid #b4ebf8;
	border-radius: 50%;

	display: flex;
	justify-content: center;
	align-items: center;
}

.customerProfilePic {
	object-fit: cover;
	overflow: hidden;
	height: 36px;
	width: 36px;
	border-radius: 50%;
}

.customerProfilePic img {
	background-size: cover;
	overflow: hidden;
	height: 100%;
	width: 100%;
	border-radius: 50%;
}

.fourStatusDiv {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.researcherProfile table tr th {
	font-size: 12px;
	padding: 0;
}

.tableReasercherHeaderStyle tr th {
	color: #000333;
	font-size: 12px;
	font-weight: 500;
}

.tableHeaderStyle tr td p {
	font-size: 14px;
}

.AggregateInnerDiv {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.circleOutline {
	border-radius: 50%;
	border: 2px solid #6ff84d;
	height: 40px;
	width: 40px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	color: #6ff84d;
}

.circleOutlineDown {
	border-radius: 50%;
	border: 2px solid #ef3d3d;
	height: 40px;
	width: 40px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	color: #ef3d3d;

}

.innerWhiteCircle {
	/* position: absolute; */
	background-color: white;
	/* top: 0px;
	right: 0px;
	width: 95%;
	height: 95%;
	margin: 1px; */
	border-radius: 50%;
}

.circleArrow {
	font-size: 12px;
	position: absolute;
	z-index: 99;
	color: #6ff84d;
	top: -3px;
	left: 41%;
}

.circleArrowDown {
	font-size: 12px;
	position: absolute;
	z-index: 99;
	color: #ef3d3d;
	top: -3px;
	left: 41%;
}

.rewardManaPartP {
	color: #2f4568;
	margin-bottom: 0px;
	font-size: 14px;
	font-weight: 600;
}

.rewardManaPartSpan {
	color: #a0a9b9;
	font-size: 11px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	line-height: 10px;
}

.stsResolved {
	font-size: 13px;
	color: #6ff84d;
	font-weight: 600;
}

.stsResolvedPending {
	font-size: 13px;
	color: #ba4366;
	font-weight: 600;
}

.rewardManaPart {
	padding-top: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid #eceef1;
	margin-bottom: 10px;
}

.rewardManaPart:last-child {
	border: none;
}

.colorHint {
	height: 27px;
	width: 30px;
	border-radius: 10px
}

.alphaWeb {
	background-color: rgb(0, 143, 251);
}

.betaData {
	background-color: rgb(0, 227, 150);
}

.gammaCloud {
	background-color: rgb(254, 176, 25);
}

.deltaIoT {
	background-color: rgb(255, 69, 96);
}

.epsilonNet {
	background-color: rgb(119, 93, 208);
}

.donutLegendName {
	display: flex;
	align-items: baseline;
	gap: 10px;
	font-size: 12px;
	/* justify-content: space-between; */
}

.donutLegendName p {
	margin-bottom: 0px;
	font-size: 14px;
	font-weight: 500;
	color: #283e61;
}

.donutLegendName span {
	font-size: 12px;
	color: #bfbfbf;
	line-height: 1;
}

.donutLegendPart {
	border-bottom: 1px solid #eceff1;
	padding-top: 10px;
	padding-bottom: 10px;
}

.donutLegendPart:last-child {
	border: none;
}