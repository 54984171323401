@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.top-content {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 30px;
	margin-bottom: 20px;
}
.company-name {
	font-family: "Roboto", sans-serif;
	display: flex;
	align-items: center;
	font-size: 27px;
	font-weight: 700;
	vertical-align: middle;
	color: #344767;
}

form label {
	font-size: 15px;
	font-weight: 600 !important;
}
.suggestion-pass strong {
    font-weight: 600;
}
.main-welcome {
	margin-top: 30px;
	margin-bottom: 30px;
	font-weight: 600;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.eye-password {
	position: relative;
}

.eye-password img {
	position: absolute;
	top: -32px;
	left: 325px;
}
.checkbox {
	display: flex;
	justify-content: space-between;
}

.loginbtn {
	background-color: #7f56d9 !important;
	color: white !important;
}
/* .loginbtn:hover {
	background-color: #7f56d9;
	color: white;
} */
/* #logincard{
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
} */
.forget-password a{
	font-size: 14px;
	font-weight: 600;
	color: #000e5e;
	letter-spacing: normal;
}
.btn{
	letter-spacing: normal !important;
}
.main-welcome span {
    font-weight: normal;
    font-size: 14px;
}
#eyeicon{
	top:37px;
	right: 30px;
}
.was-validated #eyeicon{
	right: 50px;
}
@media only screen and (max-width: 1024px) {
.top-content img {
    width: 40%;
}
.eye-password img {
    position: absolute;
    top: -32px;
    left: 300px;
}
}


@media only screen and (max-width: 767px) {
	.top-content img {
		width: 65%;
	}
	.eye-password img {
		position: relative;
		top: -32px;
		left: 280px;
	}
}


