.font-poppins {
    font-family: 'Poppins', sans-serif;
}

.bg-gradient-purple {
    background: linear-gradient(to right, #f3e8ff, #ddd6fe);
}

.bg-gradient-indigo {
    background: linear-gradient(to right, #e0e7ff, #c7d2fe);
}

.bg-purple-100 {
    background-color: #f3e8ff;
}

.bg-indigo-100 {
    background-color: #e0e7ff;
}

.border-purple-200 {
    border-color: #ddd6fe;
}

.hover-border-primary:hover {
    border-color: #7f56d9 !important;
}

.w-6 {
    width: 1.5rem;
}

.h-6 {
    height: 1.5rem;
}

/* Custom shadow similar to original */
.shadow {
    box-shadow: 0 0 15px rgba(127, 86, 217, 0.3);
}

/* Fill for star icon */
.fill-warning {
    fill: #ffc107;
}

.badgeRounded {
    background-color: white;
    border-radius: 50%;
    padding: 2px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dcc;
}

.bg-light-1 {
    background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1)) !important;
}

.hover-border-primary:hover {
    border-color: #7f56d9 !important;
}

.viptitle {
    font-size: 14px;
    font-weight: 600;
}

.vipSub {
    font-size: 14px;
    color: #6c757d;
    font-weight: 900;
}

.btn-primary-1 {
    background-color: #7f56d9 !important;
}

.border-primary {
    border-color: #7f56d9 !important;
}

.menu-active-btn {
    box-shadow: none;
    border: none;
    background-color: rgb(243 232 255 / var(--tw-bg-opacity, 1)) !important;
    color: #7f56d9 !important;
    border-radius: 4px;

}

.menu-active-btn:hover {
    box-shadow: none !important;
    border: none;
}

.menu-btn {
    background-color: transparent;
    box-shadow: none;
    border: none;
    color: #7f56d9 !important;
    border-radius: 4px;

}

.menu-btn:hover {
    box-shadow: none !important;
    border: none;
}

.custom-btn-outline {
    border: 1px solid #ced4da;
    background-color: white;
    border-radius: 4px;
    padding: 2px 10px;
}

.custom-btn-outline:hover {
    background-color: #f8f9fa;
}

.rating-bar {
    display: flex;
    height: 100%;
    align-items: flex-end;
    justify-content: space-between;
    gap: 2px;
}

.bar-item {
    width: 8px;
    background-color: #7F56D9;
    border-radius: 4px 4px 0 0;
}

.mode-radio {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mode-radio-inner {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #7F56D9;
}

.btn-outline-primary {
    border-color: #7f56d9 !important;
    color: #7f56d9 !important;
}

.btn-primary {
    background-color: #7f56d9 !important;
    color: #fff !important;
}

.text-success {
    color: rgb(22 163 74 / var(--tw-text-opacity, 1)) !important;
}

.bg-success {
    background-color: rgb(22 163 74 / var(--tw-bg-opacity, 1)) !important;
}

.form-control:focus {
    border-color: #7f56d9 !important;
    box-shadow: 0 0 0 2px #7f56d9;
}