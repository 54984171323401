.productOption i {
  transition: 0.5s ease-out;
}

.productOption {
  position: relative;
}

.productOption:hover i {
  transform: rotate(180deg);
  transition: 0.3s ease-in-out;
}

.productsElements {
  visibility: hidden;
  /* visibility: visible; */
  padding: 10px;
  position: absolute;
  background-color: #fff;
  width: 320px;
  opacity: 0;
  top: 40px;
  left: -76px;
  z-index: 9;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 10px;
  transition: 0.3s all;
}
.productElementImg {
  height: 50px;
  width: 50px;
  object-fit: cover;
}
.productElementP {
  color: #101828;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 5px;
}
.productElementSpan {
  line-height: 1.4;
  color: #475467;
  /* font-weight: 600; */
  font-size: 14px;
  /* margin-bottom: 5px; */
}
.productElementItem {
  padding: 9px 6px;
  border-radius: 6px;
}
.productElementItem:hover {
  background-color: #f5f5f5;
}

.productOption:hover .productsElements {
  opacity: 1;
  visibility: visible;
  top: 52px;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
}
.productOption:focus .productsElements {
  opacity: 1;
  visibility: visible;
  top: 52px;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
}
.navMenu {
  display: none;
}
.responsiveNav {
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  float: right;
  top: 71px;
  width: 100vw;
  left: 0px;
  height: 100vh;
  /* top: -100%; */

}
.responsiveNavOpen{
  top: 71px;
}
@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 412px) {
  .navMenu {
    display: block;
    font-size: 20px;
  }
  .productOption {
    width: 100%;
  }
  .productsElements {
    left: 0px;
  }
  .uui-navbar07_menu-left {
    height: auto !important;
  }
  .uui-navbar07_menu-right {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 10px;
  }

  .w-nav-menu {
    display: none;
  }
  .responsiveNav {
    display: block;
    flex-direction: column;
    justify-content: flex-start;
    position: absolute;
    float: right;
    top: 71px;
    width: 100vw;
    left: 0px;
    height: 100vh;
    /* top: -100%; */
  
  }
}
