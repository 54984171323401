.customerDashboardTable tr th {
	font-size: 0.8rem;
	color: #000333;
	font-family: "Poppins", sans-serif;
}

.pageCountInfo {
	display: flex;
	justify-content: space-between;
	margin: 0 40px;
}
.page-item {
	padding-left: 5px;
}

.search-container {
	margin-top: 10px;
	padding-right: 10px;
	display: flex;
	justify-content: flex-end;
}
