
.tagInputBox{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.tagInputBox input{
    border: none;
    background-color: transparent;
    outline: none;
   width: 100%;
}

.tagElements{
    background-color: #596CFF;
    margin-bottom: 10px;
    margin-right: 10px;
    color: #fff;
    padding: 0 10px;
    height: 30px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}


