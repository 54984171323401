.privacyPolicyText{
    font-size: 30px;
    font-weight: 600;
}

@media only screen and (max-width:412px){
    .privacy{
        padding-right: 20px;
        padding-left: 20px;
    }
}