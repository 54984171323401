.mainsignupDiv {
	padding-left: 200px;
	padding-right: 200px;
}

.alreadyAccount {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
}

.alreadyAccount a {
	color: #a795ce;
}

h3 {
	color: #000;
}

.signupInp {
	height: 50px;
	border: 2px solid #d6d6d6;
	border-radius: 5px;
	padding-left: 20px;
}

.signupInp:focus {
	outline: none;
	transition: 0.3s all;
	border: 2px solid #a375c7;
}

.signupInp::placeholder {
	color: #cec7c3;
}

.agreeCheckbox {
	margin-right: 20px;
	height: 20px;
	width: 20px;
	border: 2px solid #5f6265;
}

.Iaccept {
	display: flex;
	margin-bottom: 0px;
	align-items: center;
	margin-top: -3px;
	color: #232d69;
}

.signupbtn {
	width: 100%;
	padding: 14px;
	border-radius: 6px;
	font-size: 18px;
	font-weight: 600;
	background-color: #7f56d9;
	color: #fff;
}

.genderDropdown {
	position: absolute;
	background-color: #fff;
	width: 100%;
	border: 2px solid #d6d6d6;
	top: 86px;
	border-radius: 6px;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.gender {
	cursor: pointer;
	padding: 5px 20px;
}

.gender:hover {
	background-color: #6941C6;
	color: #fff;
}