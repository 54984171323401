.breadcrumb{
    background-color: transparent;
}
.breadcrumb li a{
    font-size: 16px;
    text-transform: capitalize;
}
.breadcrumb-item+.breadcrumb-item::before {
    content: ">";
    margin-top: 3px;
}