.add-subcategory-btn {
    background-color: #6f42c1;
    height: 25px;
    display: flex;
    align-items: center;
}

.addCategoryArea{
    width: 70%;
}

table th{
    text-align: center;
}
table td{
    text-align: center;
}