.researcherProfileTable tr th {
	color: #7f56d9;
	font-size: 14px;
}

.researcherProfileTable tr td p {
	font-size: 14px;
}

.donutLegendPartResearcher {
	border: none;
	margin-bottom: 20px;
}

.colorHintResearcher {
	height: 22px;
	width: 22px;
	border-radius: 50%;
}

.researcherProfile td {
	font-size: 14px ;

}

.researcherProfile th,
.researcherProfile td,
.researcherProfile td>* {
	font-size: 12px ;
}

.researcherProfile .table td .progress {
	width: 90%;
}

.researcherProfile .td-first>div {
	width: 150px;
	text-wrap: balance;
}

.researcherProfile .th-first {
	width: 100px;
}