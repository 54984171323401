.text-overflow {
	text-wrap: nowrap;
	text-overflow: ellipsis;
	width: 10ch;
	overflow: hidden;
	display: inline-block;
}

.textBalance {
	text-wrap: balance;
	text-align: center;
}

.mr-2 {
	margin-right: .5rem !important;
}

.txtWidth {
	width: 100%;
}

.cursorPointer {
	cursor: pointer;
}

.invalid-warning {
	width: 100%;
	margin-top: 0.25rem;
	font-size: 0.875em;
	color: #fd5c70;
}

.ck-powered-by {
	display: none !important;
}

.bg-primary {
	background-color: #7f56d9 !important;
}

