.loaderSpin {
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 51, 0.2);
	/* Slightly transparent background */
	position: fixed;
	top: 0;
	z-index: 999999999999999;
	display: flex;
	justify-content: center;
	align-items: center;
}

.circular-loader {
	border: 15px solid transparent;
	/* Transparent border */
	border-top: 15px solid #ffffff;
	/* Blue border */
	border-right: 15px solid #5b3ce7;
	/* Red border */
	border-bottom: 15px solid #ffffff;
	/* Yellow border */
	border-left: 15px solid #5b3ce7;
	/* Green border */
	border-radius: 50%;
	/* Make it circular */
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
	/* Enhanced shadow */
	animation: spin 1s linear infinite, pulse 2s infinite ease-in-out;
	/* Spinning and pulsating animation */
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
		/* Starting position */
	}

	100% {
		transform: rotate(360deg);
		/* Ending position */
	}
}

@keyframes pulse {

	0%,
	100% {
		transform: scale(1);
		/* Normal size */
	}

	50% {
		transform: scale(1.1);
		/* Slightly larger size */
	}
}