:root {
  --untitled-ui-gray700: #344054;
  --untitled-ui-primary600: #7f56d9;
  --untitled-ui-white: white;
  --untitled-ui-primary700: #6941c6;
  --untitled-ui-primary100: #f4ebff;
  --untitled-ui-gray600: #475467;
  --untitled-ui-gray50: #f9fafb;
  --untitled-ui-gray300: #d0d5dd;
  --untitled-ui-gray800: #1d2939;
  --untitled-ui-gray100: #f2f4f7;
  --untitled-ui-gray900: #101828;
  --untitled-ui-gray200: #eaecf0;
  --untitled-ui-gray500: #667085;
  --untitled-ui-primary800: #53389e;
  --untitled-ui-warning300: #fec84b;
  --untitled-ui-primary50: #f9f5ff;
  --untitled-ui-gray400: #98a2b3;
  --untitled-ui-primary500: #9e77ed;
  --untitled-ui-primary300: #d6bbfb;
  --untitled-ui-pink50: #fdf2fa;
  --untitled-ui-pink700: #c11574;
  --untitled-ui-indigo50: #eef4ff;
  --untitled-ui-indigo700: #3538cd;
  --untitled-ui-primary200: #e9d7fe;
}

.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.uui-navbar07_menu-button {
  padding: 0;
}

.uui-button {
  grid-column-gap: 0.5rem;
  border: 1px solid var(--untitled-ui-primary600);
  background-color: var(--untitled-ui-primary600);
  color: var(--untitled-ui-white);
  text-align: center;
  white-space: nowrap;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.125rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-button:hover {
  border-color: var(--untitled-ui-primary700);
  background-color: var(--untitled-ui-primary700);
}

.uui-button:focus {
  background-color: var(--untitled-ui-primary600);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05),
    0 0 0 4px var(--untitled-ui-primary100);
}

.uui-button.is-button-large {
  grid-column-gap: 0.75rem;
  padding: 1rem 1.75rem;
  font-size: 1.125rem;
}

.uui-button-tertiary-gray {
  grid-column-gap: 0.5rem;
  color: var(--untitled-ui-gray600);
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.125rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
}

.uui-button-tertiary-gray:hover {
  background-color: var(--untitled-ui-gray50);
  color: var(--untitled-ui-gray700);
}

.uui-button-secondary-gray {
  grid-column-gap: 0.5rem;
  border: 1px solid var(--untitled-ui-gray300);
  background-color: var(--untitled-ui-white);
  color: var(--untitled-ui-gray700);
  text-align: center;
  white-space: nowrap;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.125rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-button-secondary-gray:hover {
  background-color: var(--untitled-ui-gray50);
  color: var(--untitled-ui-gray800);
}

.uui-button-secondary-gray:focus {
  background-color: var(--untitled-ui-white);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05),
    0 0 0 4px var(--untitled-ui-gray100);
}

.uui-button-secondary-gray.show-tablet {
  display: none;
}

.uui-button-secondary-gray.is-button-large {
  grid-column-gap: 0.75rem;
  padding: 1rem 1.75rem;
  font-size: 1.125rem;
}

.show-tablet {
  display: none;
}

.uui-navbar07_button-wrapper {
  grid-column-gap: 0.75rem;
  grid-row-gap: 0.75rem;
  align-items: center;
  display: flex;
}

.uui-navbar07_menu-right {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: flex;
}

.uui-navbar07_link {
  color: var(--untitled-ui-gray600);
  align-items: center;
  padding: 0.75rem 1rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  transition: all 0.3s;
}

.uui-navbar07_link:hover {
  color: var(--untitled-ui-gray700);
}

.uui-navbar07_link.w--current {
  color: #000;
}

.uui-text-size-small {
  color: var(--untitled-ui-gray600);
  letter-spacing: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 0.875rem;
  line-height: 1.5;
}

.uui-navbar07_item-heading {
  color: var(--untitled-ui-gray900);
  margin-top: 0;
  margin-bottom: 0.25rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-weight: 600;
}

.uui-navbar07_item-right {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-weight: 400;
  display: flex;
}

.uui-icon-1x1-xsmall {
  width: 1.5rem;
  height: 1.5rem;
}

.uui-navbar07_icon-wrapper {
  color: var(--untitled-ui-primary600);
  flex: none;
}

.uui-navbar07_dropdown-link {
  grid-column-gap: 1rem;
  grid-row-gap: 0px;
  border-radius: 0.5rem;
  grid-template-rows: auto;
  grid-template-columns: max-content 1fr;
  grid-auto-columns: 1fr;
  align-items: flex-start;
  padding: 0.75rem;
  text-decoration: none;
  transition: all 0.3s;
  display: grid;
}

.uui-navbar07_dropdown-link:hover {
  background-color: var(--untitled-ui-gray50);
}

.uui-navbar07_dropdown-link-list {
  grid-column-gap: 0px;
  grid-row-gap: 0.5rem;
  grid-template-rows: max-content;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  grid-auto-columns: 1fr;
  display: grid;
}

.uui-navbar07_dropdown-list {
  box-shadow: 0 12px 16px -4px rgba(0, 0, 0, 0.08),
    0 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.uui-navbar07_dropdown-list.w--open {
  width: 20rem;
  border: 1px solid var(--untitled-ui-gray200);
  background-color: var(--untitled-ui-white);
  border-radius: 0.75rem;
  padding: 0.75rem;
  right: -100%;
}

.uui-dropdown-icon {
  width: 1.25rem;
  height: 1.25rem;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  display: flex;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: 0%;
}

.uui-navbar07_dropdown-toggle {
  color: var(--untitled-ui-gray600);
  align-items: center;
  padding: 0.75rem 2.5rem 0.75rem 1rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  transition: all 0.3s;
  display: flex;
}

.uui-navbar07_dropdown-toggle:hover {
  color: var(--untitled-ui-gray700);
}

.uui-navbar07_menu {
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-left: 1.5rem;
  display: flex;
  position: static;
}

.uui-logo_image {
  width: auto;
  height: 100%;
  flex: none;
  display: none;
}

.uui-logo_logotype {
  width: auto;
  height: 100%;
  flex: none;
}

.uui-logo_logomark-dot {
  z-index: 1;
  width: 50%;
  height: 50%;
  background-image: linear-gradient(26.5deg, #6941c6, #53389e);
  border-radius: 50%;
  position: relative;
}

.uui-logo_logomark-blur {
  z-index: 2;
  width: 100%;
  height: 50%;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgba(255, 255, 255, 0.2);
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  position: absolute;
  top: 50%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  overflow: hidden;
}

.uui-styleguide_logomark-bg {
  width: 2rem;
  height: 2rem;
  border-radius: 8px;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.uui-logo_logomark {
  width: 2rem;
  height: 2rem;
  border: 0.1px solid #dae0e8;
  border-radius: 0.5rem;
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 1px rgba(16, 24, 40, 0.06), 0 1px 2px rgba(16, 24, 40, 0.1);
}

.uui-logo_component {
  width: auto;
  height: 2rem;
  flex-wrap: nowrap;
  display: flex;
}

.uui-navbar07_logo-link {
  padding-left: 0;
}

.uui-navbar07_container {
  width: 100%;
  height: 100%;
  max-width: 80rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.uui-navbar07_component {
  z-index: 10;
  min-height: 4rem;
  border-bottom: 1px solid var(--untitled-ui-gray100);
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  margin-bottom: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  line-height: 0.5;
}

.text-block {
  padding-left: 4px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
}

.text-block.hyperesh {
  -webkit-text-stroke-color: #292929;
  text-transform: capitalize;
  text-decoration: none;
}

.uui-heroheader13_image {
  width: 100%;
  max-width: 48rem;
}

.uui-heroheader13_image-wrapper {
  margin-top: 4rem;
}

.uui-button-icon {
  width: 1.25rem;
  height: 1.25rem;
  min-height: 1.25rem;
  min-width: 1.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uui-button-icon.is-icon-large {
  width: 1.5rem;
  height: 1.5rem;
  min-height: 1.5rem;
  min-width: 1.5rem;
}

.uui-button-row {
  grid-column-gap: 0.75rem;
  grid-row-gap: 0.75rem;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.uui-button-row.button-row-center {
  justify-content: center;
}

.uui-space-large {
  width: 100%;
  min-height: 3rem;
}

.uui-text-size-xlarge {
  color: var(--untitled-ui-gray600);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.25rem;
  line-height: 1.5;
}

.uui-max-width-large {
  width: 100%;
  max-width: 48rem;
}

.uui-max-width-large.align-center,
.align-center {
  margin-left: auto;
  margin-right: auto;
}

.uui-space-small {
  width: 100%;
  min-height: 1.5rem;
}

.uui-heading-xlarge {
  color: var(--untitled-ui-gray900);
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 3.75rem;
  font-weight: 600;
  line-height: 1.2;
}

.uui-max-width-xlarge {
  width: 100%;
  max-width: 64rem;
}

.uui-text-align-center {
  text-align: center;
}

.uui-heroheader13_component {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.uui-padding-vertical-xhuge {
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 7rem;
  padding-bottom: 3rem;
  display: flex;
}

.uui-container-large {
  width: 100%;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
}

.uui-page-padding {
  background-color: #fff;
  padding-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.uui-section_heroheader13 {
  background-color: var(--untitled-ui-gray50);
  overflow: hidden;
}

.uui-button-link {
  grid-column-gap: 0.5rem;
  color: var(--untitled-ui-primary700);
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0);
  justify-content: center;
  align-items: center;
  padding: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
}

.uui-button-link:hover {
  color: var(--untitled-ui-primary800);
}

.uui-text-size-medium {
  color: var(--untitled-ui-gray600);
  letter-spacing: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

.uui-space-xxsmall {
  width: 100%;
  min-height: 0.5rem;
}

.uui-heading-xxsmall {
  color: var(--untitled-ui-gray900);
  letter-spacing: normal;
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5;
}

.uui-layout89_image {
  width: 100%;
}

.uui-layout89_image-wrapper {
  width: 100%;
  background-color: var(--untitled-ui-gray50);
  margin-bottom: 1.5rem;
  position: relative;
}

.uui-layout89_item {
  flex-direction: column;
  align-items: center;
}

.uui-layout89_component {
  grid-column-gap: 2rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  justify-items: start;
  display: grid;
}

.uui-layout70_image {
  width: 100%;
}

.uui-layout70_image-wrapper {
  width: 100%;
  background-color: var(--untitled-ui-gray50);
  margin-bottom: 2rem;
  position: relative;
}

.uui-layout70_item {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.uui-layout70_component {
  grid-column-gap: 2rem;
  grid-row-gap: 3rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  justify-items: center;
  display: grid;
}

.uui-layout09_lightbox-image {
  border: 0.25rem solid var(--untitled-ui-gray900);
  object-fit: cover;
  border-radius: 0.5rem;
}

.uui-lightbox_play-icon {
  z-index: 2;
  width: 8rem;
  height: 8rem;
  -webkit-backdrop-filter: saturate(150%) blur(12px);
  backdrop-filter: saturate(150%) blur(12px);
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 100%;
  position: absolute;
}

.lightbox_video-overlay-layer {
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(29, 41, 57, 0.1);
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.uui-layout09_lightbox {
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}

.uui-button-2 {
  grid-column-gap: 0.5rem;
  border: 1px solid var(--untitled-ui-primary600);
  background-color: var(--untitled-ui-primary600);
  color: var(--untitled-ui-white);
  text-align: center;
  white-space: nowrap;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.125rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-button-2:hover {
  border-color: var(--untitled-ui-primary700);
  background-color: var(--untitled-ui-primary700);
}

.uui-button-2:focus {
  background-color: var(--untitled-ui-primary600);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05),
    0 0 0 4px var(--untitled-ui-primary100);
}

.uui-button-secondary-gray-2 {
  grid-column-gap: 0.5rem;
  border: 1px solid var(--untitled-ui-gray300);
  background-color: var(--untitled-ui-white);
  color: var(--untitled-ui-gray700);
  text-align: center;
  white-space: nowrap;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.125rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-button-secondary-gray-2:hover {
  background-color: var(--untitled-ui-gray50);
  color: var(--untitled-ui-gray800);
}

.uui-button-secondary-gray-2:focus {
  background-color: var(--untitled-ui-white);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05),
    0 0 0 4px var(--untitled-ui-gray100);
}

.uui-button-row-2 {
  grid-column-gap: 0.75rem;
  grid-row-gap: 0.75rem;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.uui-button-row-2.is-reverse-mobile-landscape {
  grid-column-gap: 0rem;
  grid-row-gap: 0rem;
  flex-wrap: wrap;
}

.uui-text-size-large {
  color: var(--untitled-ui-gray600);
  letter-spacing: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.125rem;
  line-height: 1.5;
}

.uui-icon-1x1-xsmall-2 {
  width: 1.5rem;
  height: 1.5rem;
}

.uui-layout09_item-icon-wrapper {
  align-self: flex-start;
  margin-right: 0.75rem;
}

.uui-layout09_item {
  display: flex;
}

.uui-layout09_item-list {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  margin-top: 2rem;
  margin-bottom: 2.5rem;
  display: grid;
}

.uui-space-xsmall {
  width: 100%;
  min-height: 1rem;
}

.uui-heading-medium {
  color: var(--untitled-ui-gray900);
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.3;
}

.uui-heading-subheading {
  color: var(--untitled-ui-primary600);
  margin-bottom: 0.75rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
}

.uui-layout09_component {
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-content: stretch;
  align-items: center;
  margin-top: 100px;
  margin-left: 100px;
  margin-right: 100px;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  display: grid;
}

.uui-padding-vertical-xhuge-2 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.uui-container-large-2 {
  width: 100%;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
}

.uui-page-padding-2 {
  background-color: #fff;
  padding-left: 2rem;
  padding-right: 2rem;
}

.uui-text-size-small-2 {
  color: var(--untitled-ui-gray600);
  letter-spacing: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 0.875rem;
  line-height: 1.5;
}

.uui-testimonial13_client-heading {
  color: var(--untitled-ui-gray900);
  letter-spacing: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
}

.uui-testimonial13_customer-image {
  width: 3rem;
  height: 3rem;
  min-height: 3rem;
  min-width: 3rem;
  object-fit: cover;
  border-radius: 100%;
}

.uui-testimonial13_client-image-wrapper {
  margin-right: 0.75rem;
}

.uui-testimonial13_client {
  text-align: left;
  align-items: center;
  margin-top: auto;
  display: flex;
}

.uui-heading-xxsmall-2 {
  color: var(--untitled-ui-gray900);
  letter-spacing: normal;
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5;
}

.uui-heading-xxsmall-2.text-weight-medium {
  font-weight: 500;
}

.uui-testimonial13_rating-icon {
  width: 1.25rem;
  height: 1.25rem;
  color: var(--untitled-ui-warning300);
  justify-content: center;
  align-items: center;
  margin-right: 0.25rem;
  display: flex;
}

.uui-testimonial13_rating-wrapper {
  display: flex;
}

.uui-testimonial13_content {
  width: 100%;
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  border: 1px solid var(--untitled-ui-gray50);
  background-color: var(--untitled-ui-gray50);
  border-radius: 1rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2rem;
  display: flex;
}

.uui-testimonial13_component {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: stretch;
  margin-top: 4rem;
  display: grid;
}

.uui-max-width-large-2 {
  width: 100%;
  max-width: 48rem;
}

.uui-max-width-large-2.align-center,
.align-center-2 {
  margin-left: auto;
  margin-right: auto;
}

.uui-text-align-center-2 {
  text-align: center;
}

.uui-text-style-link-02 {
  color: var(--untitled-ui-primary700);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-weight: 600;
  text-decoration: none;
}

.uui-space-small-2 {
  width: 100%;
  min-height: 1.5rem;
}

.uui-text-size-medium-2 {
  color: var(--untitled-ui-gray600);
  letter-spacing: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

.uui-space-xxsmall-2 {
  width: 100%;
  min-height: 0.5rem;
}

.uui-icon-featured-outline-large {
  width: 3.5rem;
  height: 3.5rem;
  border: 0.5rem solid var(--untitled-ui-primary50);
  background-color: var(--untitled-ui-primary100);
  color: var(--untitled-ui-primary600);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uui-contact10_item {
  width: 100%;
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.uui-contact10_component {
  grid-column-gap: 2rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  justify-items: center;
  margin-top: 4rem;
  display: grid;
}

.utility-page-wrap {
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.utility-page-content {
  width: 260px;
  text-align: center;
  flex-direction: column;
  display: flex;
}

.utility-page-form {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.uui-button-3 {
  grid-column-gap: 0.5rem;
  border: 1px solid var(--untitled-ui-primary600);
  background-color: var(--untitled-ui-primary600);
  color: var(--untitled-ui-white);
  text-align: center;
  white-space: nowrap;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.125rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-button-3:hover {
  border-color: var(--untitled-ui-primary700);
  background-color: var(--untitled-ui-primary700);
}

.uui-button-3:focus {
  background-color: var(--untitled-ui-primary600);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05),
    0 0 0 4px var(--untitled-ui-primary100);
}

.uui-button-3.is-button-large {
  grid-column-gap: 0.75rem;
  padding: 1rem 1.75rem;
  font-size: 1.125rem;
}

.uui-button-row-3 {
  grid-column-gap: 0.75rem;
  grid-row-gap: 0.75rem;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.uui-button-row-3.button-row-center {
  justify-content: center;
}

.uui-space-small-3 {
  width: 100%;
  min-height: 1.5rem;
}

.uui-text-size-large-2 {
  color: var(--untitled-ui-gray600);
  letter-spacing: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.125rem;
  line-height: 1.5;
}

.uui-space-xxsmall-3 {
  width: 100%;
  min-height: 0.5rem;
}

.uui-heading-xxsmall-3 {
  color: var(--untitled-ui-gray900);
  letter-spacing: normal;
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5;
}

.uui-avatar-group_item,
.uui-avatar-stack_item {
  width: 2.75rem;
  height: 2.75rem;
  border: 2px solid var(--untitled-ui-white);
  background-color: var(--untitled-ui-white);
  border-radius: 50%;
  margin-right: -1rem;
}

.uui-avatar-stack_item.featured {
  width: 3.5rem;
  height: 3.5rem;
  position: relative;
}

.avatar-stack_component {
  width: auto;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 2rem;
  margin-right: 1rem;
  display: flex;
}

.uui-text-align-center-3 {
  text-align: center;
}

.uui-faq01_cta {
  background-color: var(--untitled-ui-gray50);
  border-radius: 1rem;
  justify-content: center;
  margin-top: 4rem;
  padding: 2rem;
  display: flex;
}

.uui-space-medium {
  width: 100%;
  min-height: 2rem;
  overflow: visible;
}

.uui-text-size-medium-3 {
  color: var(--untitled-ui-gray600);
  letter-spacing: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

.uui-faq01_answer {
  overflow: hidden;
}

.accordion-icon_vertical-line {
  width: 2px;
  height: 0.75rem;
  background-color: var(--untitled-ui-gray400);
  border-radius: 1px;
  position: absolute;
}

.accordion-icon_horizontal-line {
  width: 0.75rem;
  height: 2px;
  background-color: var(--untitled-ui-gray400);
  border-radius: 1px;
  position: absolute;
}

.accordion-icon_component {
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid var(--untitled-ui-gray400);
  color: var(--untitled-ui-gray400);
  border-radius: 50%;
  justify-content: center;
  align-self: flex-start;
  align-items: center;
  display: flex;
  position: relative;
}

.uui-faq01_icon-wrapper {
  color: var(--untitled-ui-primary600);
}

.uui-faq01_heading {
  color: var(--untitled-ui-gray900);
  letter-spacing: normal;
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
}

.uui-faq01_question {
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  display: flex;
}

.uui-faq01_accordion {
  border-bottom: 1px solid var(--untitled-ui-gray200);
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.uui-faq01_accordion.last-item {
  border-bottom-color: rgba(0, 0, 0, 0);
}

.uui-faq01_list {
  max-width: 48rem;
  margin-left: auto;
  margin-right: auto;
}

.uui-faq01_component {
  margin-top: 4rem;
}

.uui-space-xsmall-2 {
  width: 100%;
  min-height: 1rem;
}

.uui-heading-medium-2 {
  color: var(--untitled-ui-gray900);
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.3;
}

.uui-heading-subheading-2 {
  color: var(--untitled-ui-primary600);
  margin-bottom: 0.75rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
}

.uui-max-width-large-3 {
  width: 100%;
  max-width: 48rem;
}

.uui-max-width-large-3.align-center,
.align-center-3 {
  margin-left: auto;
  margin-right: auto;
}

.uui-padding-vertical-xhuge-3 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.uui-container-large-3 {
  width: 100%;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
}

.uui-page-padding-3 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.uui-layout13_image-01 {
  width: 24rem;
  min-width: 24rem;
  position: absolute;
  top: 2rem;
  left: auto;
  right: -1.5rem;
}

.uui-layout13_image-02 {
  width: 18rem;
  min-width: 18rem;
  position: absolute;
  top: 7rem;
  left: 2rem;
  right: 0%;
}

.uui-layout13_image-wrapper {
  width: 100%;
  height: 100%;
  min-height: 35rem;
  background-color: var(--untitled-ui-gray100);
  display: flex;
  position: relative;
  overflow: hidden;
}

.uui-layout13_lightbox-image {
  width: 100%;
  border: 0.25rem solid var(--untitled-ui-gray900);
  border-radius: 0.5rem;
}

.uui-lightbox_play-icon-2 {
  z-index: 2;
  width: 8rem;
  height: 8rem;
  -webkit-backdrop-filter: saturate(150%) blur(12px);
  backdrop-filter: saturate(150%) blur(12px);
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 100%;
  position: absolute;
}

.lightbox_video-overlay-layer-2 {
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(29, 41, 57, 0.1);
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.uui-layout13_lightbox {
  width: 100%;
  height: 100%;
  min-height: 35rem;
  background-color: var(--untitled-ui-gray100);
  align-items: center;
  padding: 3rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.uui-heading-xsmall {
  color: var(--untitled-ui-gray900);
  letter-spacing: 0;
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.4;
}

.uui-layout13_tabs-link {
  border-left: 4px solid var(--untitled-ui-gray100);
  background-color: rgba(0, 0, 0, 0);
  padding: 1.5rem 0 1.5rem 2rem;
  display: block;
}

.uui-layout13_tabs-link.w--current {
  border-left-color: var(--untitled-ui-primary600);
  opacity: 1;
  background-color: rgba(0, 0, 0, 0);
}

.uui-layout13_tabs-menu {
  grid-column-gap: 1rem;
  grid-row-gap: 0rem;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.uui-layout13_component {
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.uui-heroheader14_image-background {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(221deg,
      var(--untitled-ui-primary700),
      var(--untitled-ui-primary800));
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: auto;
}

.uui-heroheader14_card-image-05 {
  z-index: 5;
  width: 35rem;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-image: linear-gradient(112deg,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0));
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 1.25rem;
  position: absolute;
  transform: translate(65%, 88%) rotate(150deg);
  box-shadow: 1px 1px 25px 12px rgba(0, 0, 0, 0.05);
}

.uui-heroheader14_card-image-04 {
  z-index: 4;
  width: 35rem;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-image: linear-gradient(112deg,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0));
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 1.25rem;
  position: absolute;
  transform: translate(38%, 42%) rotate(120deg);
  box-shadow: 1px 1px 25px 12px rgba(0, 0, 0, 0.05);
}

.uui-heroheader14_card-image-03 {
  z-index: 3;
  width: 35rem;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-image: linear-gradient(112deg,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0));
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 1.25rem;
  position: absolute;
  transform: translate(0, 25%) rotate(90deg);
  box-shadow: 1px 1px 25px 12px rgba(0, 0, 0, 0.05);
}

.uui-heroheader14_card-image-02 {
  z-index: 2;
  width: 35rem;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-image: linear-gradient(112deg,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0));
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 1.25rem;
  position: absolute;
  transform: translate(-38%, 42%) rotate(60deg);
  box-shadow: 1px 1px 25px 12px rgba(0, 0, 0, 0.05);
}

.uui-heroheader14_card-image-01 {
  z-index: 1;
  width: 35rem;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-image: linear-gradient(112deg,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0));
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 1.25rem;
  position: absolute;
  transform: translate(-65%, 88%) rotate(30deg);
  box-shadow: 1px 1px 25px 12px rgba(0, 0, 0, 0.05);
}

.uui-heroheader14_image-wrapper {
  width: 100%;
  height: 32rem;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  display: flex;
  position: relative;
}

.uui-button-icon-2 {
  width: 1.25rem;
  height: 1.25rem;
  min-height: 1.25rem;
  min-width: 1.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uui-button-icon-2.is-icon-large {
  width: 1.5rem;
  height: 1.5rem;
  min-height: 1.5rem;
  min-width: 1.5rem;
}

.uui-button-secondary-gray-3 {
  grid-column-gap: 0.5rem;
  border: 1px solid var(--untitled-ui-gray300);
  background-color: var(--untitled-ui-white);
  color: var(--untitled-ui-gray700);
  text-align: center;
  white-space: nowrap;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.125rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-button-secondary-gray-3:hover {
  background-color: var(--untitled-ui-gray50);
  color: var(--untitled-ui-gray800);
}

.uui-button-secondary-gray-3:focus {
  background-color: var(--untitled-ui-white);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05),
    0 0 0 4px var(--untitled-ui-gray100);
}

.uui-button-secondary-gray-3.is-button-large {
  grid-column-gap: 0.75rem;
  padding: 1rem 1.75rem;
  font-size: 1.125rem;
}

.uui-space-large-2 {
  width: 100%;
  min-height: 3rem;
}

.uui-text-size-xlarge-2 {
  color: var(--untitled-ui-gray600);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.25rem;
  line-height: 1.5;
}

.uui-heading-xlarge-2 {
  color: var(--untitled-ui-gray900);
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 3.75rem;
  font-weight: 600;
  line-height: 1.2;
}

.uui-badge-group_icon {
  width: 1rem;
  height: 1rem;
  justify-content: center;
  align-items: center;
  margin-left: 0.25rem;
  display: flex;
}

.uui-badge-group_icon.text-color-primary500 {
  color: var(--untitled-ui-primary500);
  margin-right: -0.25rem;
}

.badge-group_text-wrapper {
  align-items: center;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  display: flex;
}

.uui-badge-group_badge {
  background-color: var(--untitled-ui-white);
  color: var(--untitled-ui-gray700);
  white-space: nowrap;
  border-radius: 1rem;
  align-items: center;
  padding: 0.125rem 0.625rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  display: flex;
}

.uui-badge-group_badge.is-primary {
  background-color: var(--untitled-ui-white);
  color: var(--untitled-ui-primary700);
}

.uui-badge-group {
  background-color: var(--untitled-ui-gray100);
  color: var(--untitled-ui-gray700);
  white-space: nowrap;
  mix-blend-mode: multiply;
  border-radius: 10rem;
  align-items: center;
  padding: 0.25rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  display: flex;
}

.uui-badge-group.is-primary {
  background-color: var(--untitled-ui-primary50);
  color: var(--untitled-ui-primary700);
  white-space: nowrap;
  text-decoration: none;
}

.uui-max-width-xlarge-2 {
  width: 100%;
  max-width: 64rem;
}

.uui-heroheader14_component {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.uui-space-xhuge {
  width: 100%;
  min-height: 7rem;
}

.uui-section_heroheader14 {
  overflow: hidden;
}

.uui-heroheader04_image {
  width: 76rem;
  min-width: 76rem;
  display: block;
  position: absolute;
}

.uui-heroheader04_image-wrapper {
  height: 100%;
  min-height: 44.375rem;
  position: relative;
}

.uui-max-width-medium {
  width: 100%;
  max-width: 35rem;
}

.uui-heroheader04_component {
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.uui-section_heroheader04 {
  overflow: hidden;
}

.uui-button-link-2 {
  grid-column-gap: 0.5rem;
  color: var(--untitled-ui-primary700);
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0);
  justify-content: center;
  align-items: center;
  padding: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
}

.uui-button-link-2:hover {
  color: var(--untitled-ui-primary800);
}

.uui-layout68_item-text-wrapper {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.uui-icon-1x1-xsmall-3 {
  width: 1.5rem;
  height: 1.5rem;
}

.uui-icon-featured-outline-large-2 {
  width: 3.5rem;
  height: 3.5rem;
  border: 0.5rem solid var(--untitled-ui-primary50);
  background-color: var(--untitled-ui-primary100);
  color: var(--untitled-ui-primary600);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uui-layout68_item-icon-wrapper {
  flex: none;
  align-self: flex-start;
  margin-right: 1rem;
}

.uui-layout68_item {
  display: flex;
}

.uui-layout68_item-list {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
}

.uui-layout68_image {
  width: 100%;
  object-fit: fill;
  display: inline-block;
}

.uui-layout68_image-wrapper {
  width: 100%;
  height: 100%;
  background-color: rgba(249, 250, 251, 0);
}

.uui-layout68_component {
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.error-text {
  color: #e23939;
}

.error-message {
  margin-top: 1.5rem;
  padding: 0.875rem 1rem;
}

.success-text {
  font-weight: 600;
}

.success-message {
  background-color: #f4f4f4;
  padding: 1.5rem;
}

.uui-form-button-wrapper {
  flex-direction: column;
  margin-top: 0.5rem;
  display: flex;
}

.uui-text-style-link {
  color: var(--untitled-ui-gray600);
  text-decoration: underline;
  transition: color 0.3s;
}

.uui-text-style-link:hover {
  color: var(--untitled-ui-gray700);
}

.uui-form-checkbox-label {
  color: var(--untitled-ui-gray700);
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
}

.uui-form-checkbox-icon {
  width: 1.25rem;
  height: 1.25rem;
  min-height: 1.25rem;
  min-width: 1.25rem;
  border: 1px solid var(--untitled-ui-gray300);
  background-color: var(--untitled-ui-white);
  cursor: pointer;
  border-radius: 0.375rem;
  margin-top: 0;
  margin-left: -1.25rem;
  margin-right: 0.75rem;
  transition: all 0.3s;
}

.uui-form-checkbox-icon:hover {
  border-color: var(--untitled-ui-primary600);
  background-color: var(--untitled-ui-primary50);
}

.uui-form-checkbox-icon.w--redirected-checked {
  border-width: 1px;
  border-color: var(--untitled-ui-primary600);
  background-color: var(--untitled-ui-primary50);
  box-shadow: none;
  background-image: url("../images/check.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 0.875rem 0.875rem;
  background-attachment: scroll;
  border-radius: 0.25rem;
}

.uui-form-checkbox-icon.w--redirected-focus {
  border-color: var(--untitled-ui-primary600);
  background-color: var(--untitled-ui-primary50);
  box-shadow: 0 0 0 4px var(--untitled-ui-primary100);
  border-radius: 0.25rem;
}

.uui-form-checkbox {
  align-items: center;
  margin-bottom: 0;
  padding-left: 1.25rem;
  display: flex;
}

.uui-form_input {
  height: auto;
  min-height: 2.75rem;
  border: 1px solid var(--untitled-ui-gray300);
  background-color: var(--untitled-ui-white);
  color: var(--untitled-ui-gray900);
  border-radius: 0.5rem;
  margin-bottom: 0;
  padding: 0.5rem 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  transition: all 0.3s;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-form_input:focus {
  border-color: var(--untitled-ui-primary300);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05),
    0 0 0 4px var(--untitled-ui-primary100);
  color: var(--untitled-ui-gray900);
}

.uui-form_input::-ms-input-placeholder {
  color: var(--untitled-ui-gray500);
}

.uui-form_input::placeholder {
  color: var(--untitled-ui-gray500);
}

.uui-form_input.text-area {
  height: auto;
  min-height: 11.25rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  overflow: auto;
}

.uui-form_input.select {
  color: var(--untitled-ui-gray900);
  background-color: #fff;
}

.uui-field-label {
  color: var(--untitled-ui-gray700);
  margin-bottom: 0.5rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
}

.uui-form-field-wrapper {
  position: relative;
}

.uui-form-radio-label {
  color: var(--untitled-ui-gray700);
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-weight: 500;
}

.uui-form-radio-icon {
  width: 1.25rem;
  height: 1.25rem;
  min-height: 1.25rem;
  min-width: 1.25rem;
  border: 1px solid var(--untitled-ui-gray300);
  background-color: var(--untitled-ui-white);
  cursor: pointer;
  border-radius: 100px;
  margin-top: 0;
  margin-left: -1.125rem;
  margin-right: 0.75rem;
  transition: all 0.3s;
}

.uui-form-radio-icon:hover {
  border-color: var(--untitled-ui-primary600);
  background-color: var(--untitled-ui-primary50);
}

.uui-form-radio-icon.w--redirected-checked {
  border-width: 1px;
  border-color: var(--untitled-ui-primary600);
  background-color: var(--untitled-ui-primary50);
  background-image: url("../images/check-circle.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto;
}

.uui-form-radio-icon.w--redirected-focus {
  border-color: var(--untitled-ui-primary600);
  background-color: var(--untitled-ui-primary50);
  box-shadow: 0 0 0 4px var(--untitled-ui-primary100);
  margin-top: 0;
}

.uui-form-radio-icon.tick-icon.w--redirected-checked {
  background-color: var(--untitled-ui-primary600);
  background-image: url("../images/check-tick.svg");
}

.uui-form-radio {
  align-items: center;
  margin-bottom: 0;
  padding-left: 1.125rem;
  display: flex;
}

.form-radio-2col {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  margin-top: 1rem;
  display: grid;
}

.form-field-2col {
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.uui-contact02_form {
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.uui-contact02_component {
  max-width: 35rem;
  flex-direction: column;
  align-items: stretch;
  margin: 4rem auto 0;
}

.uui-container-small {
  width: 100%;
  max-width: 48rem;
  margin-left: auto;
  margin-right: auto;
}

.uui-heroheader01_pattern-image {
  width: 18.625rem;
  position: absolute;
  top: -6%;
  right: -21%;
}

.uui-heroheader01_fileupload-image2 {
  width: 23rem;
  min-width: 23rem;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  border-radius: 0.5rem;
}

.uui-heroheader01_fileupload-image1 {
  width: 23rem;
  min-width: 23rem;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  border-radius: 0.5rem;
  margin-bottom: 0.75rem;
}

.uui-heroheader01_fileupload-image-wrapper {
  width: 23rem;
  position: absolute;
  top: auto;
  bottom: 5%;
  left: -16%;
  right: auto;
}

.uui-heroheader01_image {
  width: 100%;
  height: 40rem;
  object-fit: cover;
  border-top-left-radius: 10rem;
}

.uui-heroheader01_image-wrapper {
  position: relative;
}

.uui-heroheader01_component {
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.uui-section_heroheader01 {
  overflow: hidden;
}

.uui-button-icon-3 {
  width: 1.25rem;
  height: 1.25rem;
  min-height: 1.25rem;
  min-width: 1.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uui-button-icon-3.is-icon-large {
  width: 1.5rem;
  height: 1.5rem;
  min-height: 1.5rem;
  min-width: 1.5rem;
}

.uui-button-link-3 {
  grid-column-gap: 0.5rem;
  color: var(--untitled-ui-primary700);
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0);
  justify-content: center;
  align-items: center;
  padding: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
}

.uui-button-link-3:hover {
  color: var(--untitled-ui-primary800);
}

.uui-button-row-4 {
  grid-column-gap: 0.75rem;
  grid-row-gap: 0.75rem;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.uui-space-small-4 {
  width: 100%;
  min-height: 1.5rem;
}

.uui-text-size-medium-4 {
  color: var(--untitled-ui-gray600);
  letter-spacing: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

.uui-space-xxsmall-4 {
  width: 100%;
  min-height: 0.5rem;
}

.uui-heading-xsmall-2 {
  color: var(--untitled-ui-gray900);
  letter-spacing: 0;
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.4;
}

.uui-icon-1x1-xsmall-4 {
  width: 1.5rem;
  height: 1.5rem;
}

.uui-icon-featured-outline-large-3 {
  width: 3.5rem;
  height: 3.5rem;
  border: 0.5rem solid var(--untitled-ui-primary50);
  background-color: var(--untitled-ui-primary100);
  color: var(--untitled-ui-primary600);
  border-radius: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uui-icon-featured-outline-large-3.align-center {
  margin-left: auto;
  margin-right: auto;
}

.uui-layout47_content {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.uui-layout47_component {
  grid-column-gap: 2rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  justify-items: center;
  display: grid;
}

.uui-padding-vertical-xhuge-4 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.uui-container-large-4 {
  width: 100%;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
}

.uui-page-padding-4 {
  background-color: #fff;
  padding-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.uui-footer07_legal-link {
  color: var(--untitled-ui-gray500);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 0.875rem;
  text-decoration: none;
  transition: all 0.3s;
}

.uui-footer07_legal-link:hover {
  color: var(--untitled-ui-gray600);
}

.uui-footer07_legal-list {
  grid-column-gap: 1rem;
  grid-row-gap: 0rem;
  white-space: normal;
  grid-template-rows: auto;
  grid-template-columns: max-content;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  justify-content: center;
  display: grid;
}

.uui-text-size-small-3 {
  color: var(--untitled-ui-gray600);
  letter-spacing: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 0.875rem;
  line-height: 1.5;
}

.uui-text-size-small-3.text-color-gray500 {
  color: var(--untitled-ui-gray500);
}

.uui-footer07_bottom-wrapper {
  border-top: 1px solid var(--untitled-ui-gray200);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  padding-top: 2rem;
  display: flex;
}

.uui-footer07_link {
  color: var(--untitled-ui-gray600);
  text-align: center;
  padding: 0.5rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s;
}

.uui-footer07_link:hover {
  color: var(--untitled-ui-gray700);
}

.uui-footer07_link-list {
  grid-column-gap: 1rem;
  grid-row-gap: 0rem;
  white-space: normal;
  grid-template-rows: auto;
  grid-template-columns: max-content;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  justify-content: center;
  justify-items: start;
  display: grid;
}

.uui-logo_image-2 {
  width: auto;
  height: 100%;
  flex: none;
  display: none;
}

.uui-logo_logotype-2 {
  width: auto;
  height: 100%;
  flex: none;
}

.uui-logo_logomark-dot-2 {
  z-index: 1;
  width: 50%;
  height: 50%;
  background-image: linear-gradient(26.5deg, #6941c6, #53389e);
  border-radius: 50%;
  position: relative;
}

.uui-logo_logomark-blur-2 {
  z-index: 2;
  width: 100%;
  height: 50%;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgba(255, 255, 255, 0.2);
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  position: absolute;
  top: 50%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  overflow: hidden;
}

.uui-styleguide_logomark-bg-2 {
  width: 2rem;
  height: 2rem;
  border-radius: 8px;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.uui-logo_logomark-2 {
  width: 2rem;
  height: 2rem;
  border: 0.1px solid #dae0e8;
  border-radius: 0.5rem;
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 1px rgba(16, 24, 40, 0.06), 0 1px 2px rgba(16, 24, 40, 0.1);
}

.uui-logo_component-2 {
  width: auto;
  height: 2rem;
  flex-wrap: nowrap;
  display: flex;
}

.uui-footer07_logo-link {
  margin-bottom: 2rem;
  padding-left: 0;
}

.uui-footer07_top-wrapper {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.uui-padding-vertical-xlarge {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.text-block-2 {
  padding-top: 8px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 18px;
  font-weight: 700;
}

.checkbox-label {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
}

.uui-text-style-link-02-2 {
  color: var(--untitled-ui-primary700);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-weight: 600;
  text-decoration: none;
}

.uui-heading-xxsmall-4 {
  color: var(--untitled-ui-gray900);
  letter-spacing: normal;
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5;
}

.align-center-4 {
  margin-left: auto;
  margin-right: auto;
}

.uui-contact11_item {
  width: 100%;
  text-align: center;
  flex-direction: column;
  align-items: center;
}

.uui-contact11_component {
  grid-column-gap: 2rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  justify-items: center;
  margin-top: 4rem;
  display: grid;
}

.uui-text-size-large-3 {
  color: var(--untitled-ui-gray600);
  letter-spacing: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.125rem;
  line-height: 1.5;
}

.uui-space-xsmall-3 {
  width: 100%;
  min-height: 1rem;
}

.uui-heading-medium-3 {
  color: var(--untitled-ui-gray900);
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.3;
}

.uui-heading-subheading-3 {
  color: var(--untitled-ui-primary600);
  margin-bottom: 0.75rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
}

.uui-max-width-large-4 {
  width: 100%;
  max-width: 48rem;
}

.uui-max-width-large-4.align-center {
  margin-left: auto;
  margin-right: auto;
}

.uui-text-align-center-4 {
  text-align: center;
}

.uui-heroheader03_arrow-image {
  position: absolute;
  bottom: 5rem;
  left: -12rem;
}

.uui-heroheader03_image {
  width: 100%;
  height: 40rem;
  object-fit: cover;
  border-top-right-radius: 4rem;
  border-bottom-left-radius: 4rem;
}

.uui-heroheader03_image-wrapper {
  position: relative;
}

.uui-button-4 {
  grid-column-gap: 0.5rem;
  border: 1px solid var(--untitled-ui-primary600);
  background-color: var(--untitled-ui-primary600);
  color: var(--untitled-ui-white);
  text-align: center;
  white-space: nowrap;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.125rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-button-4:hover {
  border-color: var(--untitled-ui-primary700);
  background-color: var(--untitled-ui-primary700);
}

.uui-button-4:focus {
  background-color: var(--untitled-ui-primary600);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05),
    0 0 0 4px var(--untitled-ui-primary100);
}

.uui-button-4.is-button-large {
  grid-column-gap: 0.75rem;
  padding: 1rem 1.75rem;
  font-size: 1.125rem;
}

.uui-button-secondary-gray-4 {
  grid-column-gap: 0.5rem;
  border: 1px solid var(--untitled-ui-gray300);
  background-color: var(--untitled-ui-white);
  color: var(--untitled-ui-gray700);
  text-align: center;
  white-space: nowrap;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.125rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-button-secondary-gray-4:hover {
  background-color: var(--untitled-ui-gray50);
  color: var(--untitled-ui-gray800);
}

.uui-button-secondary-gray-4:focus {
  background-color: var(--untitled-ui-white);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05),
    0 0 0 4px var(--untitled-ui-gray100);
}

.uui-button-secondary-gray-4.is-button-large {
  grid-column-gap: 0.75rem;
  padding: 1rem 1.75rem;
  font-size: 1.125rem;
}

.uui-space-large-3 {
  width: 100%;
  min-height: 3rem;
}

.uui-text-size-xlarge-3 {
  color: var(--untitled-ui-gray600);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.25rem;
  line-height: 1.5;
}

.uui-max-width-medium-2 {
  width: 100%;
  max-width: 35rem;
}

.uui-heading-xlarge-3 {
  color: var(--untitled-ui-gray900);
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 3.75rem;
  font-weight: 600;
  line-height: 1.2;
}

.uui-heroheader03_content {
  z-index: 1;
  margin-right: -6rem;
  position: relative;
}

.uui-heroheader03_component {
  grid-column-gap: 0rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1.25fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.uui-section_heroheader03 {
  overflow: hidden;
}

.uui-layout01_image {
  width: 40rem;
  min-width: 40rem;
  border: 0.25rem solid var(--untitled-ui-gray900);
  border-radius: 0.5rem;
  position: absolute;
  top: auto;
  bottom: auto;
  left: 3rem;
  right: auto;
}

.uui-layout01_image-wrapper {
  width: 100%;
  height: 100%;
  min-height: 33rem;
  background-color: var(--untitled-ui-gray100);
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.uui-button-5 {
  grid-column-gap: 0.5rem;
  border: 1px solid var(--untitled-ui-primary600);
  background-color: var(--untitled-ui-primary600);
  color: var(--untitled-ui-white);
  text-align: center;
  white-space: nowrap;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.125rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-button-5:hover {
  border-color: var(--untitled-ui-primary700);
  background-color: var(--untitled-ui-primary700);
}

.uui-button-5:focus {
  background-color: var(--untitled-ui-primary600);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05),
    0 0 0 4px var(--untitled-ui-primary100);
}

.uui-button-secondary-gray-5 {
  grid-column-gap: 0.5rem;
  border: 1px solid var(--untitled-ui-gray300);
  background-color: var(--untitled-ui-white);
  color: var(--untitled-ui-gray700);
  text-align: center;
  white-space: nowrap;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.125rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-button-secondary-gray-5:hover {
  background-color: var(--untitled-ui-gray50);
  color: var(--untitled-ui-gray800);
}

.uui-button-secondary-gray-5:focus {
  background-color: var(--untitled-ui-white);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05),
    0 0 0 4px var(--untitled-ui-gray100);
}

.uui-button-row-5 {
  grid-column-gap: 0.75rem;
  grid-row-gap: 0.75rem;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.uui-space-medium-2 {
  width: 100%;
  min-height: 2rem;
}

.uui-text-size-large-4 {
  color: var(--untitled-ui-gray600);
  letter-spacing: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.125rem;
  line-height: 1.5;
}

.uui-space-xsmall-4 {
  width: 100%;
  min-height: 1rem;
}

.uui-heading-medium-4 {
  color: var(--untitled-ui-gray900);
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.3;
}

.uui-heading-subheading-4 {
  color: var(--untitled-ui-primary600);
  margin-bottom: 0.75rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
}

.uui-layout01_component {
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.uui-padding-vertical-xhuge-5 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.uui-container-large-5 {
  width: 100%;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
}

.uui-page-padding-5 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.uui-contact07_map {
  height: 100%;
  overflow: hidden;
}

.uui-contact07_map-placeholder {
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.uui-contact07_map-wrapper {
  width: 100%;
  height: 35rem;
  margin-top: 4rem;
  position: relative;
}

.uui-text-style-link-02-3 {
  color: var(--untitled-ui-primary700);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-weight: 600;
  text-decoration: none;
}

.uui-space-xxsmall-5 {
  width: 100%;
  min-height: 0.5rem;
}

.uui-heading-xxsmall-5 {
  color: var(--untitled-ui-gray900);
  letter-spacing: normal;
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5;
}

.uui-icon-1x1-xsmall-5 {
  width: 1.5rem;
  height: 1.5rem;
}

.icon-featured-large {
  width: 3rem;
  height: 3rem;
  background-color: var(--untitled-ui-primary100);
  color: var(--untitled-ui-primary600);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uui-contact07_item-icon-wrapper {
  flex: none;
  align-self: flex-start;
  margin-right: 1rem;
}

.uui-contact07_item {
  display: flex;
}

.uui-contact07_contact-list {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
}

.uui-text-size-large-5 {
  color: var(--untitled-ui-gray600);
  letter-spacing: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.125rem;
  line-height: 1.5;
}

.uui-space-xsmall-5 {
  width: 100%;
  min-height: 1rem;
}

.uui-heading-medium-5 {
  color: var(--untitled-ui-gray900);
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.3;
}

.uui-heading-subheading-5 {
  color: var(--untitled-ui-primary600);
  margin-bottom: 0.75rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
}

.uui-max-width-large-5 {
  width: 100%;
  max-width: 48rem;
}

.uui-contact07_component {
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 0.75fr;
  grid-auto-columns: 1fr;
  align-items: start;
  display: grid;
}

.uui-padding-vertical-xhuge-6 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.uui-container-large-6 {
  width: 100%;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
}

.uui-page-padding-6 {
  background-color: #fff;
  padding-left: 2rem;
  padding-right: 2rem;
}

.uui-section_contact07 {
  background-color: var(--untitled-ui-gray50);
}

.link-block {
  -webkit-text-stroke-color: #161616;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  text-decoration: none;
}

.link-block.w--current {
  -webkit-text-stroke-color: #333;
  text-decoration: none;
}

.text-span {
  -webkit-text-stroke-color: #000;
}

.uui-heroheader13_image-2 {
  width: 100%;
  max-width: 48rem;
}

.uui-heroheader13_image-wrapper-2 {
  margin-top: 4rem;
}

.uui-button-6 {
  grid-column-gap: 0.5rem;
  border: 1px solid var(--untitled-ui-primary600);
  background-color: var(--untitled-ui-primary600);
  color: var(--untitled-ui-white);
  text-align: center;
  white-space: nowrap;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.125rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-button-6:hover {
  border-color: var(--untitled-ui-primary700);
  background-color: var(--untitled-ui-primary700);
}

.uui-button-6:focus {
  background-color: var(--untitled-ui-primary600);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05),
    0 0 0 4px var(--untitled-ui-primary100);
}

.uui-button-6.is-button-large {
  grid-column-gap: 0.75rem;
  padding: 1rem 1.75rem;
  font-size: 1.125rem;
}

.uui-button-icon-4 {
  width: 1.25rem;
  height: 1.25rem;
  min-height: 1.25rem;
  min-width: 1.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uui-button-icon-4.is-icon-large {
  width: 1.5rem;
  height: 1.5rem;
  min-height: 1.5rem;
  min-width: 1.5rem;
}

.uui-button-secondary-gray-6 {
  grid-column-gap: 0.5rem;
  border: 1px solid var(--untitled-ui-gray300);
  background-color: var(--untitled-ui-white);
  color: var(--untitled-ui-gray700);
  text-align: center;
  white-space: nowrap;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.125rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-button-secondary-gray-6:hover {
  background-color: var(--untitled-ui-gray50);
  color: var(--untitled-ui-gray800);
}

.uui-button-secondary-gray-6:focus {
  background-color: var(--untitled-ui-white);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05),
    0 0 0 4px var(--untitled-ui-gray100);
}

.uui-button-secondary-gray-6.is-button-large {
  grid-column-gap: 0.75rem;
  padding: 1rem 1.75rem;
  font-size: 1.125rem;
}

.uui-button-row-6 {
  grid-column-gap: 0.75rem;
  grid-row-gap: 0.75rem;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.uui-button-row-6.button-row-center {
  justify-content: center;
}

.uui-space-large-4 {
  width: 100%;
  min-height: 3rem;
}

.uui-text-size-xlarge-4 {
  color: var(--untitled-ui-gray600);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.25rem;
  line-height: 1.5;
}

.uui-max-width-large-6 {
  width: 100%;
  max-width: 48rem;
}

.uui-max-width-large-6.align-center,
.align-center-5 {
  margin-left: auto;
  margin-right: auto;
}

.uui-space-small-5 {
  width: 100%;
  min-height: 1.5rem;
}

.uui-heading-xlarge-4 {
  color: var(--untitled-ui-gray900);
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 3.75rem;
  font-weight: 600;
  line-height: 1.2;
}

.uui-max-width-xlarge-3 {
  width: 100%;
  max-width: 64rem;
}

.uui-text-align-center-5 {
  text-align: center;
}

.uui-heroheader13_component-2 {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.uui-padding-vertical-xhuge-7 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.uui-container-large-7 {
  width: 100%;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
}

.uui-page-padding-7 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.uui-section_heroheader13-2 {
  background-color: var(--untitled-ui-gray50);
  overflow: hidden;
}

.uui-heroheader13_image-3 {
  width: 100%;
  max-width: 48rem;
}

.uui-heroheader13_image-wrapper-3 {
  margin-top: 4rem;
}

.uui-button-7 {
  grid-column-gap: 0.5rem;
  border: 1px solid var(--untitled-ui-primary600);
  background-color: var(--untitled-ui-primary600);
  color: var(--untitled-ui-white);
  text-align: center;
  white-space: nowrap;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.125rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-button-7:hover {
  border-color: var(--untitled-ui-primary700);
  background-color: var(--untitled-ui-primary700);
}

.uui-button-7:focus {
  background-color: var(--untitled-ui-primary600);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05),
    0 0 0 4px var(--untitled-ui-primary100);
}

.uui-button-7.is-button-large {
  grid-column-gap: 0.75rem;
  padding: 1rem 1.75rem;
  font-size: 1.125rem;
}

.uui-button-icon-5 {
  width: 1.25rem;
  height: 1.25rem;
  min-height: 1.25rem;
  min-width: 1.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uui-button-icon-5.is-icon-large {
  width: 1.5rem;
  height: 1.5rem;
  min-height: 1.5rem;
  min-width: 1.5rem;
}

.uui-button-secondary-gray-7 {
  grid-column-gap: 0.5rem;
  border: 1px solid var(--untitled-ui-gray300);
  background-color: var(--untitled-ui-white);
  color: var(--untitled-ui-gray700);
  text-align: center;
  white-space: nowrap;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.125rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-button-secondary-gray-7:hover {
  background-color: var(--untitled-ui-gray50);
  color: var(--untitled-ui-gray800);
}

.uui-button-secondary-gray-7:focus {
  background-color: var(--untitled-ui-white);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05),
    0 0 0 4px var(--untitled-ui-gray100);
}

.uui-button-secondary-gray-7.is-button-large {
  grid-column-gap: 0.75rem;
  padding: 1rem 1.75rem;
  font-size: 1.125rem;
}

.uui-button-row-7 {
  grid-column-gap: 0.75rem;
  grid-row-gap: 0.75rem;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.uui-button-row-7.button-row-center {
  justify-content: center;
}

.uui-space-large-5 {
  width: 100%;
  min-height: 3rem;
}

.uui-text-size-xlarge-5 {
  color: var(--untitled-ui-gray600);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.25rem;
  line-height: 1.5;
}

.uui-max-width-large-7 {
  width: 100%;
  max-width: 48rem;
}

.uui-max-width-large-7.align-center,
.align-center-6 {
  margin-left: auto;
  margin-right: auto;
}

.uui-space-small-6 {
  width: 100%;
  min-height: 1.5rem;
}

.uui-heading-xlarge-5 {
  color: var(--untitled-ui-gray900);
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 3.75rem;
  font-weight: 600;
  line-height: 1.2;
}

.uui-max-width-xlarge-4 {
  width: 100%;
  max-width: 64rem;
}

.uui-text-align-center-6 {
  text-align: center;
}

.uui-heroheader13_component-3 {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.uui-padding-vertical-xhuge-8 {
  background-color: #fff;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.uui-container-large-8 {
  width: 100%;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
}

.uui-page-padding-8 {
  background-color: #fff;
  padding-left: 2rem;
  padding-right: 2rem;
}

.uui-section_heroheader13-3 {
  background-color: var(--untitled-ui-gray50);
  overflow: hidden;
}

.uui-page-padding-9,
.uui-page-padding-10 {
  background-color: #fff;
  padding-left: 2rem;
  padding-right: 2rem;
}

.text-span-2 {
  text-decoration: none;
}

.uui-heroheader08_image {
  width: 100%;
  height: 40rem;
  object-fit: cover;
}

.uui-text-size-medium-5 {
  color: var(--untitled-ui-gray600);
  letter-spacing: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

.uui-ratings_number {
  color: var(--untitled-ui-gray700);
  letter-spacing: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
}

.uui-ratings_stars {
  width: 100%;
  height: 1.25rem;
  margin-right: 0.5rem;
}

.ratings_stars-wrapper {
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.125rem;
  display: flex;
}

.ratings_wrapper {
  margin-left: 1rem;
}

.uui-avatar-group_item-2 {
  width: 2.75rem;
  height: 2.75rem;
  border: 2px solid var(--untitled-ui-white);
  background-color: var(--untitled-ui-white);
  border-radius: 50%;
  margin-right: -1rem;
}

.uui-avatar-group_component {
  grid-column-gap: 0rem;
  flex-wrap: nowrap;
  align-items: center;
  padding-right: 1rem;
  display: flex;
}

.uui-ratings_component {
  align-items: center;
  display: flex;
}

.uui-text-style-link-2 {
  color: var(--untitled-ui-gray600);
  text-decoration: underline;
  transition: color 0.3s;
}

.uui-text-style-link-2:hover {
  color: var(--untitled-ui-gray700);
}

.uui-text-size-small-4 {
  color: var(--untitled-ui-gray600);
  letter-spacing: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 0.875rem;
  line-height: 1.5;
}

.uui-button-8 {
  grid-column-gap: 0.5rem;
  border: 1px solid var(--untitled-ui-primary600);
  background-color: var(--untitled-ui-primary600);
  color: var(--untitled-ui-white);
  text-align: center;
  white-space: nowrap;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.125rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-button-8:hover {
  border-color: var(--untitled-ui-primary700);
  background-color: var(--untitled-ui-primary700);
}

.uui-button-8:focus {
  background-color: var(--untitled-ui-primary600);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05),
    0 0 0 4px var(--untitled-ui-primary100);
}

.uui-button-8.is-button-large {
  grid-column-gap: 0.75rem;
  padding: 1rem 1.75rem;
  font-size: 1.125rem;
}

.uui-form_input-2 {
  height: auto;
  min-height: 2.75rem;
  border: 1px solid var(--untitled-ui-gray300);
  background-color: var(--untitled-ui-white);
  color: var(--untitled-ui-gray900);
  border-radius: 0.5rem;
  margin-bottom: 0;
  padding: 0.5rem 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  transition: all 0.3s;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-form_input-2:focus {
  border-color: var(--untitled-ui-primary300);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05),
    0 0 0 4px var(--untitled-ui-primary100);
  color: var(--untitled-ui-gray900);
}

.uui-form_input-2::-ms-input-placeholder {
  color: var(--untitled-ui-gray500);
}

.uui-form_input-2::placeholder {
  color: var(--untitled-ui-gray500);
}

.uui-signup-form_wrapper {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr max-content;
  grid-auto-columns: 1fr;
  margin-bottom: 0.375rem;
  display: grid;
}

.uui-heroheader08_form {
  max-width: 30rem;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.uui-text-size-xlarge-6 {
  color: var(--untitled-ui-gray600);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.25rem;
  line-height: 1.5;
}

.uui-max-width-small {
  width: 100%;
  max-width: 30rem;
}

.uui-space-small-7 {
  width: 100%;
  min-height: 1.5rem;
}

.uui-heading-xlarge-6 {
  color: var(--untitled-ui-gray900);
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 3.75rem;
  font-weight: 600;
  line-height: 1.2;
}

.uui-heroheader08_component {
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.uui-padding-vertical-xhuge-9 {
  padding-top: 1rem;
  padding-bottom: 4rem;
}

.uui-container-large-9 {
  width: 100%;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
}

.uui-page-padding-11 {
  background-color: #fff;
  padding-left: 2rem;
  padding-right: 2rem;
}

.uui-section_heroheader08 {
  background-color: var(--untitled-ui-gray50);
}

.section {
  text-align: center;
  margin: 2rem;
  padding: 0;
  overflow: scroll;
}

.uui-heroheader13_image-4 {
  width: 100%;
  max-width: 48rem;
}

.uui-heroheader13_image-wrapper-4 {
  margin-top: 4rem;
}

.uui-button-icon-6 {
  width: 1.25rem;
  height: 1.25rem;
  min-height: 1.25rem;
  min-width: 1.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uui-button-icon-6.is-icon-large {
  width: 1.5rem;
  height: 1.5rem;
  min-height: 1.5rem;
  min-width: 1.5rem;
}

.uui-button-secondary-gray-8 {
  grid-column-gap: 0.5rem;
  border: 1px solid var(--untitled-ui-gray300);
  background-color: var(--untitled-ui-white);
  color: var(--untitled-ui-gray700);
  text-align: center;
  white-space: nowrap;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.125rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-button-secondary-gray-8:hover {
  background-color: var(--untitled-ui-gray50);
  color: var(--untitled-ui-gray800);
}

.uui-button-secondary-gray-8:focus {
  background-color: var(--untitled-ui-white);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05),
    0 0 0 4px var(--untitled-ui-gray100);
}

.uui-button-secondary-gray-8.is-button-large {
  grid-column-gap: 0.75rem;
  padding: 1rem 1.75rem;
  font-size: 1.125rem;
}

.uui-button-row-8 {
  grid-column-gap: 0.75rem;
  grid-row-gap: 0.75rem;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.uui-button-row-8.button-row-center {
  justify-content: center;
}

.uui-space-large-6 {
  width: 100%;
  min-height: 3rem;
}

.uui-max-width-large-8 {
  width: 100%;
  max-width: 48rem;
}

.uui-max-width-large-8.align-center,
.align-center-7 {
  margin-left: auto;
  margin-right: auto;
}

.uui-max-width-xlarge-5 {
  width: 100%;
  max-width: 64rem;
}

.uui-text-align-center-7 {
  text-align: center;
}

.uui-heroheader13_component-4 {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.uui-section_heroheader13-4 {
  background-color: var(--untitled-ui-gray50);
  overflow: hidden;
}

.link {
  -webkit-text-stroke-color: #222121;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  text-decoration: none;
}

.container2 {
  grid-column-gap: 10px;
  grid-row-gap: 0px;
  flex-flow: row;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: flex-start;
  align-self: flex-end;
  margin-left: 12px;
  margin-right: 12px;
  padding-left: 0;
  display: flex;
}

.uui-space-xxsmall-6 {
  width: 100%;
  min-height: 0.5rem;
}

.uui-heading-xxsmall-6 {
  color: var(--untitled-ui-gray900);
  letter-spacing: normal;
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5;
}

.uui-icon-1x1-xsmall-6 {
  width: 1.5rem;
  height: 1.5rem;
}

.uui-icon-featured-outline-large-4 {
  width: 3.5rem;
  height: 3.5rem;
  border: 0.5rem solid var(--untitled-ui-primary50);
  background-color: var(--untitled-ui-primary100);
  color: var(--untitled-ui-primary600);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uui-layout74_item {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.uui-layout74_list {
  width: 100%;
  grid-column-gap: 2rem;
  grid-row-gap: 4rem;
  grid-template:
    "."
    / 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  justify-items: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
  display: grid;
}

.uui-text-size-large-6 {
  color: var(--untitled-ui-gray600);
  letter-spacing: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.125rem;
  line-height: 1.5;
}

.uui-space-xsmall-6 {
  width: 100%;
  min-height: 1rem;
}

.uui-heading-medium-6 {
  color: var(--untitled-ui-gray900);
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.3;
}

.uui-heading-subheading-6 {
  color: var(--untitled-ui-primary600);
  margin-bottom: 0.75rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
}

.uui-layout74_component {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.uui-padding-vertical-xhuge-10 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 4rem;
  display: flex;
}

.uui-page-padding-12 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.uui-layout38_logo {
  height: 2.5rem;
}

.uui-layout38_logo-row {
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 4rem;
  display: flex;
}

.uui-space-medium-3 {
  width: 100%;
  min-height: 2rem;
}

.navbar-no-shadow-container {
  z-index: 5;
  width: 100%;
  max-width: 1140px;
  background-color: rgba(0, 0, 0, 0);
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
}

.container-regular {
  width: 100%;
  max-width: 1260px;
  min-height: 30px;
  margin-left: auto;
  margin-right: auto;
}

.navbar-wrapper {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.nav-menu {
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 0;
  padding-bottom: 0;
  padding-left: 0;
  display: flex;
}

/* .nav-link {
  direction: rtl;
  color: #1a1b1f;
  text-align: right;
  letter-spacing: .25px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
} */

.nav-link:hover {
  color: rgba(26, 27, 31, 0.75);
}

.nav-link:focus-visible {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 4px;
  outline: 2px solid #0050bd;
}

.nav-link[data-wf-focus-visible] {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 4px;
  outline: 2px solid #0050bd;
}

.nav-dropdown {
  margin-left: 5px;
  margin-right: 5px;
}

.nav-dropdown-toggle {
  letter-spacing: 0.25px;
  padding: 5px 30px 5px 10px;
  font-size: 14px;
  line-height: 20px;
}

.nav-dropdown-toggle:hover {
  color: rgba(26, 27, 31, 0.75);
}

.nav-dropdown-toggle:focus-visible {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 5px;
  outline: 2px solid #0050bd;
}

.nav-dropdown-toggle[data-wf-focus-visible] {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 5px;
  outline: 2px solid #0050bd;
}

.nav-dropdown-icon {
  margin-right: 10px;
}

.nav-dropdown-list {
  background-color: #fff;
  border-radius: 12px;
}

.nav-dropdown-list.w--open {
  padding-top: 10px;
  padding-bottom: 10px;
}

.nav-dropdown-link {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}

.nav-dropdown-link:focus-visible {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 5px;
  outline: 2px solid #0050bd;
}

.nav-dropdown-link[data-wf-focus-visible] {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 5px;
  outline: 2px solid #0050bd;
}

.nav-button-wrapper {
  margin-left: 120px;
}

.button-primary {
  color: #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
  background-color: #1a1b1f;
  padding: 12px 25px;
  font-size: 12px;
  line-height: 20px;
  transition: all 0.2s;
}

.button-primary:hover {
  color: #fff;
  background-color: #32343a;
}

.button-primary:active {
  background-color: #43464d;
}

.uui-layout62_image-01 {
  width: 24rem;
  min-width: 24rem;
  position: absolute;
  top: 2rem;
  left: auto;
  right: -1.5rem;
}

.uui-layout62_image-02 {
  width: 18rem;
  min-width: 18rem;
  position: absolute;
  top: 7rem;
  left: 2rem;
  right: 0%;
}

.uui-layout62_image-wrapper {
  width: 100%;
  height: 100%;
  min-height: 35rem;
  background-color: var(--untitled-ui-gray100);
  display: flex;
  position: relative;
  overflow: hidden;
}

.uui-layout62_lightbox-image {
  width: 40rem;
  min-width: 40rem;
  border: 0.25rem solid var(--untitled-ui-gray900);
  border-radius: 0.5rem;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: 3rem;
}

.uui-lightbox_play-icon-3 {
  z-index: 2;
  width: 8rem;
  height: 8rem;
  -webkit-backdrop-filter: saturate(150%) blur(12px);
  backdrop-filter: saturate(150%) blur(12px);
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 100%;
  position: absolute;
}

.lightbox_video-overlay-layer-3 {
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(29, 41, 57, 0.1);
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.uui-layout62_lightbox {
  width: 100%;
  height: 100%;
  min-height: 33rem;
  background-color: var(--untitled-ui-gray100);
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.uui-text-size-medium-6 {
  color: var(--untitled-ui-gray600);
  letter-spacing: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

.uui-space-xxsmall-7 {
  width: 100%;
  min-height: 0.5rem;
}

.uui-heading-xsmall-3 {
  color: var(--untitled-ui-gray900);
  letter-spacing: 0;
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.4;
}

.uui-layout62_tabs-link {
  border-left: 4px solid var(--untitled-ui-gray100);
  background-color: rgba(0, 0, 0, 0);
  padding: 1.5rem 0 1.5rem 2rem;
  display: block;
}

.uui-layout62_tabs-link.w--current {
  border-left-width: 4px;
  border-left-color: var(--untitled-ui-primary600);
  opacity: 1;
  background-color: rgba(0, 0, 0, 0);
}

.uui-layout62_component {
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.uui-padding-vertical-xhuge-11 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
}

.uui-container-large-10 {
  width: 100%;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
}

.uui-page-padding-13 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.uui-button-icon-7 {
  width: 1.25rem;
  height: 1.25rem;
  min-height: 1.25rem;
  min-width: 1.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uui-button-link-4 {
  grid-column-gap: 0.5rem;
  color: var(--untitled-ui-primary700);
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0);
  justify-content: center;
  align-items: center;
  padding: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
}

.uui-button-link-4:hover {
  color: var(--untitled-ui-primary800);
}

.uui-button-row-9 {
  grid-column-gap: 0.75rem;
  grid-row-gap: 0.75rem;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.uui-space-small-8 {
  width: 100%;
  min-height: 1.5rem;
}

.uui-icon-1x1-xsmall-7 {
  width: 1.5rem;
  height: 1.5rem;
}

.uui-icon-featured-outline-large-5 {
  width: 3.5rem;
  height: 3.5rem;
  border: 0.5rem solid var(--untitled-ui-primary50);
  background-color: var(--untitled-ui-primary100);
  color: var(--untitled-ui-primary600);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uui-layout47_content-2 {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.uui-layout47_component-2 {
  grid-column-gap: 2rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  justify-items: center;
  display: grid;
}

.uui-heading-xxsmall-7 {
  color: var(--untitled-ui-gray900);
  letter-spacing: normal;
  margin-top: 0;
  margin-bottom: 1.1rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5;
}

.uui-layout32_timeline-right {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.uui-layout32_timeline-icon-wrapper {
  z-index: 1;
  background-color: #fff;
  margin-top: -1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: relative;
}

.uui-layout32_timeline-left {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  display: flex;
  position: relative;
}

.uui-layout32_timeline-step {
  grid-column-gap: 2.5rem;
  grid-row-gap: 0px;
  grid-template-rows: auto;
  grid-template-columns: max-content 1fr;
}

.uui-layout32_progress-bar {
  width: 2px;
  height: 100%;
  background-color: var(--untitled-ui-primary600);
}

.uui-layout32_progress-bar-wrapper {
  width: 2px;
  height: 75%;
  background-color: var(--untitled-ui-gray200);
  position: absolute;
  top: 10%;
  left: 1.75rem;
  right: auto;
}

.uui-layout32_timeline-wrapper {
  position: relative;
}

.uui-layout32_content-right {
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.uui-button-9 {
  grid-column-gap: 0.5rem;
  border: 1px solid var(--untitled-ui-primary600);
  background-color: var(--untitled-ui-primary600);
  color: var(--untitled-ui-white);
  text-align: center;
  white-space: nowrap;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.125rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-button-9:hover {
  border-color: var(--untitled-ui-primary700);
  background-color: var(--untitled-ui-primary700);
}

.uui-button-9:focus {
  background-color: var(--untitled-ui-primary600);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05),
    0 0 0 4px var(--untitled-ui-primary100);
}

.uui-button-secondary-gray-9 {
  grid-column-gap: 0.5rem;
  border: 1px solid var(--untitled-ui-gray300);
  background-color: var(--untitled-ui-white);
  color: var(--untitled-ui-gray700);
  text-align: center;
  white-space: nowrap;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.125rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-button-secondary-gray-9:hover {
  background-color: var(--untitled-ui-gray50);
  color: var(--untitled-ui-gray800);
}

.uui-button-secondary-gray-9:focus {
  background-color: var(--untitled-ui-white);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05),
    0 0 0 4px var(--untitled-ui-gray100);
}

.uui-space-medium-4 {
  width: 100%;
  min-height: 2rem;
}

.uui-heading-medium-7 {
  color: var(--untitled-ui-gray900);
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.3;
}

.uui-heading-subheading-7 {
  color: var(--untitled-ui-primary600);
  margin-bottom: 0.75rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
}

.uui-layout32_content-left {
  align-self: flex-start;
}

.uui-layout32_component {
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  display: grid;
}

.image {
  object-fit: fill;
}

.uui-heroheader08_image-2 {
  width: 100%;
  height: 40rem;
  object-fit: cover;
}

.uui-text-size-medium-7 {
  color: var(--untitled-ui-gray600);
  letter-spacing: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

.uui-ratings_number-2 {
  color: var(--untitled-ui-gray700);
  letter-spacing: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
}

.uui-ratings_stars-2 {
  width: 100%;
  height: 1.25rem;
  margin-right: 0.5rem;
}

.ratings_stars-wrapper-2 {
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.125rem;
  display: flex;
}

.ratings_wrapper-2 {
  margin-left: 1rem;
}

.uui-avatar-group_item-3 {
  width: 2.75rem;
  height: 2.75rem;
  border: 2px solid var(--untitled-ui-white);
  background-color: var(--untitled-ui-white);
  border-radius: 50%;
  margin-right: -1rem;
}

.uui-avatar-group_component-2 {
  grid-column-gap: 0rem;
  flex-wrap: nowrap;
  align-items: center;
  padding-right: 1rem;
  display: flex;
}

.uui-ratings_component-2 {
  align-items: center;
  display: flex;
}

.uui-text-style-link-3 {
  color: var(--untitled-ui-gray600);
  text-decoration: underline;
  transition: color 0.3s;
}

.uui-text-style-link-3:hover {
  color: var(--untitled-ui-gray700);
}

.uui-text-size-small-5 {
  color: var(--untitled-ui-gray600);
  letter-spacing: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 0.875rem;
  line-height: 1.5;
}

.uui-button-10 {
  grid-column-gap: 0.5rem;
  border: 1px solid var(--untitled-ui-primary600);
  background-color: var(--untitled-ui-primary600);
  color: var(--untitled-ui-white);
  text-align: center;
  white-space: nowrap;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.125rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-button-10:hover {
  border-color: var(--untitled-ui-primary700);
  background-color: var(--untitled-ui-primary700);
}

.uui-button-10:focus {
  background-color: var(--untitled-ui-primary600);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05),
    0 0 0 4px var(--untitled-ui-primary100);
}

.uui-form_input-3 {
  height: auto;
  min-height: 2.75rem;
  border: 1px solid var(--untitled-ui-gray300);
  background-color: var(--untitled-ui-white);
  color: var(--untitled-ui-gray900);
  border-radius: 0.5rem;
  margin-bottom: 0;
  padding: 0.5rem 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  transition: all 0.3s;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-form_input-3:focus {
  border-color: var(--untitled-ui-primary300);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05),
    0 0 0 4px var(--untitled-ui-primary100);
  color: var(--untitled-ui-gray900);
}

.uui-form_input-3::-ms-input-placeholder {
  color: var(--untitled-ui-gray500);
}

.uui-form_input-3::placeholder {
  color: var(--untitled-ui-gray500);
}

.uui-signup-form_wrapper-2 {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr max-content;
  grid-auto-columns: 1fr;
  margin-bottom: 0.375rem;
  display: grid;
}

.uui-heroheader08_form-2 {
  max-width: 30rem;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.uui-text-size-xlarge-7 {
  color: var(--untitled-ui-gray600);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.25rem;
  line-height: 1.5;
}

.uui-max-width-small-2 {
  width: 100%;
  max-width: 30rem;
}

.uui-space-small-9 {
  width: 100%;
  min-height: 1.5rem;
}

.uui-heading-xlarge-7 {
  color: var(--untitled-ui-gray900);
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 3.75rem;
  font-weight: 600;
  line-height: 1.2;
}

.uui-heroheader08_component-2 {
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.uui-padding-vertical-xhuge-12 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.uui-container-large-11 {
  width: 100%;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
}

.uui-page-padding-14 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.uui-section_heroheader08-2 {
  background-color: var(--untitled-ui-gray50);
}

.uui-layout03_image {
  width: 40rem;
  min-width: 40rem;
  border: 0.25rem solid var(--untitled-ui-gray900);
  border-radius: 0.5rem;
  position: absolute;
  top: auto;
  bottom: auto;
  left: 3rem;
  right: auto;
}

.uui-lightbox_play-icon-4 {
  z-index: 2;
  width: 8rem;
  height: 8rem;
  -webkit-backdrop-filter: saturate(150%) blur(12px);
  backdrop-filter: saturate(150%) blur(12px);
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 100%;
  position: absolute;
}

.lightbox_video-overlay-layer-4 {
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(29, 41, 57, 0.1);
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.uui-layout03_lightbox {
  width: 100%;
  height: 100%;
  min-height: 35rem;
  background-color: var(--untitled-ui-gray100);
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.uui-button-11 {
  grid-column-gap: 0.5rem;
  border: 1px solid var(--untitled-ui-primary600);
  background-color: var(--untitled-ui-primary600);
  color: var(--untitled-ui-white);
  text-align: center;
  white-space: nowrap;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.125rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-button-11:hover {
  border-color: var(--untitled-ui-primary700);
  background-color: var(--untitled-ui-primary700);
}

.uui-button-11:focus {
  background-color: var(--untitled-ui-primary600);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05),
    0 0 0 4px var(--untitled-ui-primary100);
}

.uui-button-secondary-gray-10 {
  grid-column-gap: 0.5rem;
  border: 1px solid var(--untitled-ui-gray300);
  background-color: var(--untitled-ui-white);
  color: var(--untitled-ui-gray700);
  text-align: center;
  white-space: nowrap;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.125rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-button-secondary-gray-10:hover {
  background-color: var(--untitled-ui-gray50);
  color: var(--untitled-ui-gray800);
}

.uui-button-secondary-gray-10:focus {
  background-color: var(--untitled-ui-white);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05),
    0 0 0 4px var(--untitled-ui-gray100);
}

.uui-button-row-10 {
  grid-column-gap: 0.75rem;
  grid-row-gap: 0.75rem;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.uui-space-medium-5 {
  width: 100%;
  min-height: 2rem;
}

.uui-text-size-large-7 {
  color: var(--untitled-ui-gray600);
  letter-spacing: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.125rem;
  line-height: 1.5;
}

.uui-space-xsmall-7 {
  width: 100%;
  min-height: 1rem;
}

.uui-heading-medium-8 {
  color: var(--untitled-ui-gray900);
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.3;
}

.uui-heading-subheading-8 {
  color: var(--untitled-ui-primary600);
  margin-bottom: 0.75rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
}

.uui-layout03_component {
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.uui-padding-vertical-xhuge-13 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.uui-container-large-12 {
  width: 100%;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
}

.uui-page-padding-15 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.table_cell {
  padding: 0.75rem;
}

.table_row {
  border-bottom: 1px solid #000;
}

.table_component {
  width: 100%;
  text-align: center;
  margin-left: 0;
  margin-right: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
}

.uui-heroheader01_pattern-image-2 {
  width: 18.625rem;
  position: absolute;
  top: -6%;
  right: -21%;
}

.uui-heroheader01_fileupload-image2-2 {
  width: 23rem;
  min-width: 23rem;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  border-radius: 0.5rem;
}

.uui-heroheader01_fileupload-image1-2 {
  width: 23rem;
  min-width: 23rem;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  border-radius: 0.5rem;
  margin-bottom: 0.75rem;
}

.uui-heroheader01_fileupload-image-wrapper-2 {
  width: 23rem;
  position: absolute;
  top: auto;
  bottom: 5%;
  left: -16%;
  right: auto;
}

.uui-heroheader01_image-2 {
  width: 100%;
  height: 40rem;
  object-fit: cover;
  border-top-left-radius: 10rem;
}

.uui-heroheader01_image-wrapper-2 {
  position: relative;
}

.uui-button-12 {
  grid-column-gap: 0.5rem;
  border: 1px solid var(--untitled-ui-primary600);
  background-color: var(--untitled-ui-primary600);
  color: var(--untitled-ui-white);
  text-align: center;
  white-space: nowrap;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.125rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-button-12:hover {
  border-color: var(--untitled-ui-primary700);
  background-color: var(--untitled-ui-primary700);
}

.uui-button-12:focus {
  background-color: var(--untitled-ui-primary600);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05),
    0 0 0 4px var(--untitled-ui-primary100);
}

.uui-button-12.is-button-large {
  grid-column-gap: 0.75rem;
  padding: 1rem 1.75rem;
  font-size: 1.125rem;
}

.uui-button-icon-8 {
  width: 1.25rem;
  height: 1.25rem;
  min-height: 1.25rem;
  min-width: 1.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uui-button-icon-8.is-icon-large {
  width: 1.5rem;
  height: 1.5rem;
  min-height: 1.5rem;
  min-width: 1.5rem;
}

.uui-button-secondary-gray-11 {
  grid-column-gap: 0.5rem;
  border: 1px solid var(--untitled-ui-gray300);
  background-color: var(--untitled-ui-white);
  color: var(--untitled-ui-gray700);
  text-align: center;
  white-space: nowrap;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.125rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-button-secondary-gray-11:hover {
  background-color: var(--untitled-ui-gray50);
  color: var(--untitled-ui-gray800);
}

.uui-button-secondary-gray-11:focus {
  background-color: var(--untitled-ui-white);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05),
    0 0 0 4px var(--untitled-ui-gray100);
}

.uui-button-secondary-gray-11.is-button-large {
  grid-column-gap: 0.75rem;
  padding: 1rem 1.75rem;
  font-size: 1.125rem;
}

.uui-button-row-11 {
  grid-column-gap: 0.75rem;
  grid-row-gap: 0.75rem;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.uui-space-large-7 {
  width: 100%;
  min-height: 3rem;
}

.uui-text-size-xlarge-8 {
  color: var(--untitled-ui-gray600);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.25rem;
  line-height: 1.5;
}

.uui-max-width-medium-3 {
  width: 100%;
  max-width: 35rem;
}

.uui-space-small-10 {
  width: 100%;
  min-height: 1.5rem;
}

.uui-heading-xlarge-8 {
  color: var(--untitled-ui-gray900);
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 3.75rem;
  font-weight: 600;
  line-height: 1.2;
}

.uui-heroheader01_component-2 {
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.uui-padding-vertical-xhuge-14 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.uui-container-large-13 {
  width: 100%;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
}

.uui-page-padding-16 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.uui-section_heroheader01-2 {
  overflow: hidden;
}

.uui-heroheader05_image-blob {
  z-index: -1;
  width: 34rem;
  height: auto;
  color: var(--untitled-ui-gray50);
  position: absolute;
  top: 12%;
}

.uui-heroheader05_image {
  height: 100%;
  max-height: 40rem;
  object-fit: cover;
  margin-top: -1rem;
  margin-bottom: -1rem;
  margin-left: 1rem;
}

.uui-heroheader05_image-wrapper {
  justify-content: center;
  display: flex;
  position: relative;
}

.uui-button-row-12 {
  grid-column-gap: 0.75rem;
  grid-row-gap: 0.75rem;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.uui-space-large-8 {
  width: 100%;
  min-height: 3rem;
}

.uui-text-size-xlarge-9 {
  color: var(--untitled-ui-gray600);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.25rem;
  line-height: 1.5;
}

.uui-space-small-11 {
  width: 100%;
  min-height: 1.5rem;
}

.uui-heading-xlarge-9 {
  color: var(--untitled-ui-gray900);
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 3.75rem;
  font-weight: 600;
  line-height: 1.2;
}

.uui-badge-group_icon-2 {
  width: 1rem;
  height: 1rem;
  justify-content: center;
  align-items: center;
  margin-left: 0.25rem;
  display: flex;
}

.uui-badge-group_icon-2.text-color-primary500 {
  color: var(--untitled-ui-primary500);
  margin-right: -0.25rem;
}

.badge-group_text-wrapper-2 {
  align-items: center;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  display: flex;
}

.uui-badge-group_badge-2 {
  background-color: var(--untitled-ui-white);
  color: var(--untitled-ui-gray700);
  white-space: nowrap;
  border-radius: 1rem;
  align-items: center;
  padding: 0.125rem 0.625rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  display: flex;
}

.uui-badge-group_badge-2.is-primary {
  background-color: var(--untitled-ui-white);
  color: var(--untitled-ui-primary700);
}

.uui-badge-group-2 {
  background-color: var(--untitled-ui-gray100);
  color: var(--untitled-ui-gray700);
  white-space: nowrap;
  mix-blend-mode: multiply;
  border-radius: 10rem;
  align-items: center;
  padding: 0.25rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  display: flex;
}

.uui-badge-group-2.is-primary {
  background-color: var(--untitled-ui-primary50);
  color: var(--untitled-ui-primary700);
  white-space: nowrap;
  text-decoration: none;
}

.uui-heroheader05_component {
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 0.75fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.uui-padding-vertical-xhuge-15 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.uui-container-large-14 {
  width: 100%;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
}

.uui-page-padding-17 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.uui-section_heroheader05 {
  overflow: hidden;
}

.uui-text-size-small-6 {
  color: var(--untitled-ui-gray600);
  letter-spacing: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 0.875rem;
  line-height: 1.5;
}

.uui-text-divider {
  color: var(--untitled-ui-gray600);
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

.uui-text-divider.text-size-small {
  color: var(--untitled-ui-gray600);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 0.875rem;
}

.uui-blog01_date-wrapper {
  align-items: center;
  display: flex;
}

.uui-blog01_author-heading {
  color: var(--untitled-ui-gray900);
  letter-spacing: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
}

.uui-blog01_author-image {
  width: 2.5rem;
  height: 2.5rem;
  min-height: 2.5rem;
  min-width: 2.5rem;
  object-fit: cover;
  border-radius: 100%;
}

.uui-blog01_author-image-wrapper {
  margin-right: 0.75rem;
}

.uui-blog01_author-wrapper {
  align-items: center;
  margin-top: 1.5rem;
  display: flex;
}

.uui-text-size-medium-8 {
  color: var(--untitled-ui-gray600);
  letter-spacing: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

.uui-blog01_icon {
  width: 1.5rem;
  height: 1.5rem;
  justify-content: center;
  align-items: center;
  margin-top: 0.25rem;
  margin-left: 0.5rem;
  display: flex;
}

.uui-heading-xsmall-4 {
  color: var(--untitled-ui-gray900);
  letter-spacing: 0;
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.4;
}

.uui-blog01_title-link {
  color: var(--untitled-ui-gray900);
  justify-content: space-between;
  margin-bottom: 0.5rem;
  text-decoration: none;
  display: flex;
}

.uui-blog01_category-link {
  color: var(--untitled-ui-primary700);
  margin-bottom: 0.5rem;
  margin-right: 1rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  text-decoration: none;
  transition: opacity 0.3s;
}

.uui-blog01_category-link:hover {
  color: var(--untitled-ui-primary800);
}

.uui-blog01_category-link.w--current {
  border-bottom: 2px solid #000;
}

.uui-blog01_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.uui-blog01_image-wrapper {
  width: 100%;
  padding-top: 66%;
  position: relative;
  overflow: hidden;
}

.uui-blog01_image-link {
  width: 100%;
  margin-bottom: 2rem;
  transition: all 0.3s;
}

.uui-blog01_image-link:hover {
  box-shadow: 0 24px 48px -12px rgba(16, 24, 40, 0.18);
}

.uui-blog01_list {
  grid-column-gap: 2rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
}

.uui-blog01_menu-link {
  color: var(--untitled-ui-gray500);
  white-space: nowrap;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
  flex: none;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding: 0.5rem 0.25rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s;
}

.uui-blog01_menu-link:hover,
.uui-blog01_menu-link.w--current {
  border-bottom-color: var(--untitled-ui-primary700);
  color: var(--untitled-ui-primary700);
}

.uui-blog01_category-menu {
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
  display: flex;
}

.uui-blog01_category-menu.no-scrollbar {
  box-shadow: inset 0 -1px 0 0 var(--untitled-ui-gray200);
}

.uui-blog01_component {
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 6rem;
  display: flex;
}

.uui-text-size-large-8 {
  color: var(--untitled-ui-gray600);
  letter-spacing: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.125rem;
  line-height: 1.5;
}

.uui-heading-large {
  color: var(--untitled-ui-gray900);
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.2;
}

.uui-heading-subheading-9 {
  color: var(--untitled-ui-primary600);
  margin-bottom: 0.75rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
}

.uui-max-width-large-9 {
  width: 100%;
  max-width: 48rem;
}

.uui-max-width-large-9.align-center,
.align-center-8 {
  margin-left: auto;
  margin-right: auto;
}

.uui-text-align-center-8 {
  text-align: center;
}

.uui-layout01_image-2 {
  width: 40rem;
  min-width: 40rem;
  border: 0.25rem solid var(--untitled-ui-gray900);
  border-radius: 0.5rem;
  position: absolute;
  top: auto;
  bottom: auto;
  left: 3rem;
  right: auto;
}

.uui-layout01_image-wrapper-2 {
  width: 100%;
  height: 100%;
  min-height: 33rem;
  background-color: var(--untitled-ui-gray100);
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.uui-button-13 {
  grid-column-gap: 0.5rem;
  border: 1px solid var(--untitled-ui-primary600);
  background-color: var(--untitled-ui-primary600);
  color: var(--untitled-ui-white);
  text-align: center;
  white-space: nowrap;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.125rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-button-13:hover {
  border-color: var(--untitled-ui-primary700);
  background-color: var(--untitled-ui-primary700);
}

.uui-button-13:focus {
  background-color: var(--untitled-ui-primary600);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05),
    0 0 0 4px var(--untitled-ui-primary100);
}

.uui-button-secondary-gray-12 {
  grid-column-gap: 0.5rem;
  border: 1px solid var(--untitled-ui-gray300);
  background-color: var(--untitled-ui-white);
  color: var(--untitled-ui-gray700);
  text-align: center;
  white-space: nowrap;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.125rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-button-secondary-gray-12:hover {
  background-color: var(--untitled-ui-gray50);
  color: var(--untitled-ui-gray800);
}

.uui-button-secondary-gray-12:focus {
  background-color: var(--untitled-ui-white);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05),
    0 0 0 4px var(--untitled-ui-gray100);
}

.uui-button-secondary-gray-12.is-button-small {
  grid-column-gap: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

.uui-button-secondary-gray-12.is-button-small.icon-only {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
}

.uui-button-secondary-gray-12.icon-only {
  width: 2.75rem;
  height: 2.75rem;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
}

.uui-space-medium-6 {
  width: 100%;
  min-height: 2rem;
}

.uui-space-xsmall-8 {
  width: 100%;
  min-height: 1rem;
}

.uui-heading-medium-9 {
  color: var(--untitled-ui-gray900);
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.3;
}

.uui-layout01_component-2 {
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.uui-layout08_image {
  width: 100%;
  border: 0.25rem solid var(--untitled-ui-gray900);
  border-radius: 0.5rem;
}

.uui-icon-1x1-xsmall-8 {
  width: 1.5rem;
  height: 1.5rem;
}

.uui-layout08_item-icon-wrapper {
  align-self: flex-start;
  margin-right: 0.75rem;
}

.uui-layout08_item {
  display: flex;
}

.uui-layout08_item-list {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  margin-top: 2rem;
  margin-bottom: 2.5rem;
  display: grid;
}

.uui-layout08_component {
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.uui-blogpost01_date-wrapper {
  align-items: center;
  display: flex;
}

.uui-blogpost01_author-heading {
  color: var(--untitled-ui-gray900);
  letter-spacing: normal;
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
}

.uui-blogpost01_author-image {
  width: 3.5rem;
  height: 3.5rem;
  min-height: 3.5rem;
  min-width: 3.5rem;
  object-fit: cover;
  border-radius: 100%;
}

.uui-blogpost01_author-image-wrapper {
  margin-right: 1rem;
}

.uui-blogpost01_author-wrapper {
  align-items: center;
  display: flex;
}

.uui-blogpost01_divider {
  width: 100%;
  height: 1px;
  max-width: 48rem;
  background-color: var(--untitled-ui-gray200);
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}

.uui-badge {
  grid-column-gap: 0.375rem;
  background-color: var(--untitled-ui-gray100);
  color: var(--untitled-ui-gray700);
  white-space: nowrap;
  border-radius: 10rem;
  align-items: center;
  padding: 0.125rem 0.625rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  text-decoration: none;
  display: flex;
}

.uui-badge.is-pink {
  background-color: var(--untitled-ui-pink50);
  color: var(--untitled-ui-pink700);
}

.uui-badge.is-indigo {
  background-color: var(--untitled-ui-indigo50);
  color: var(--untitled-ui-indigo700);
}

.uui-badge.is-primary {
  background-color: var(--untitled-ui-primary50);
  color: var(--untitled-ui-primary700);
}

.uui-blogpost01_tag-list {
  grid-column-gap: 0.5rem;
  flex-wrap: wrap;
  justify-content: flex-end;
  display: flex;
}

.uui-blogpost01_tag-list-wrapper {
  width: 50%;
}

.uui-button-icon-9 {
  width: 1.25rem;
  height: 1.25rem;
  min-height: 1.25rem;
  min-width: 1.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uui-button-icon-9.text-color-gray400 {
  color: var(--untitled-ui-gray400);
}

.uui-blogpost01_share {
  grid-column-gap: 0.75rem;
  grid-row-gap: 0.75rem;
  grid-template-rows: auto;
  grid-template-columns: max-content;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  justify-items: start;
  margin-top: 1rem;
  display: grid;
}

.uui-blogpost01_share-heading {
  color: var(--untitled-ui-gray900);
  letter-spacing: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
}

.uui-blogpost01_share-wrapper {
  width: 50%;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.uui-blogpost01_content-bottom {
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.uui-text-rich-text {
  color: var(--untitled-ui-gray600);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
}

.uui-text-rich-text h1 {
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

.uui-text-rich-text h2 {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.uui-text-rich-text h3 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.uui-text-rich-text h4 {
  margin-top: 1.5rem;
  margin-bottom: 1.25rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
}

.uui-text-rich-text h5,
.uui-text-rich-text h6 {
  margin-top: 1.25rem;
  margin-bottom: 1rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
}

.uui-text-rich-text p {
  margin-bottom: 1rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
}

.uui-text-rich-text blockquote {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-style: italic;
}

.uui-text-rich-text a {
  text-decoration: underline;
}

.uui-text-rich-text figcaption {
  color: var(--untitled-ui-gray500);
  text-align: left;
  margin-top: 1rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 0.875rem;
}

.uui-blogpost01_content {
  margin-bottom: 4rem;
}

.uui-blogpost01_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.uui-blogpost01_image-wrapper {
  width: 100%;
  text-align: center;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0%;
  position: static;
  overflow: auto;
}

.uui-blogpost01_content-top {
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 3rem;
  display: flex;
}

.uui-breadcrumb-link {
  color: var(--untitled-ui-gray600);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s;
}

.uui-breadcrumb-link:hover {
  color: var(--untitled-ui-gray700);
}

.uui-breadcrumb-link.w--current {
  color: var(--untitled-ui-primary700);
  font-weight: 600;
}

.uui-breadcrumb-link.w--current:hover {
  color: var(--untitled-ui-primary800);
}

.uui-breadcrumb-divider {
  width: 1rem;
  height: 1rem;
  color: var(--untitled-ui-gray500);
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  display: flex;
}

.uui-blogpost01_breadcrumb {
  width: 100%;
  align-items: center;
  margin-bottom: 1.5rem;
  display: flex;
}

.uui-blogpost01_title-wrapper {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 5rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.image-2 {
  text-align: center;
}

.text-span-3 {
  color: #8b60f1;
}

.bold-text {
  color: #7239f1;
}

.bold-text-2,
.bold-text-3,
.bold-text-4,
.bold-text-5 {
  color: #7f56d9;
}

.uui-button-14 {
  grid-column-gap: 0.5rem;
  border: 1px solid var(--untitled-ui-primary600);
  background-color: var(--untitled-ui-primary600);
  color: var(--untitled-ui-white);
  text-align: center;
  white-space: nowrap;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.125rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-button-14:hover {
  border-color: var(--untitled-ui-primary700);
  background-color: var(--untitled-ui-primary700);
}

.uui-button-14:focus {
  background-color: var(--untitled-ui-primary600);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05),
    0 0 0 4px var(--untitled-ui-primary100);
}

.uui-text-size-medium-9 {
  color: var(--untitled-ui-gray600);
  letter-spacing: normal;
  justify-content: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  display: flex;
}

.uui-icon-1x1-xsmall-9 {
  width: 1.5rem;
  height: 1.5rem;
}

.uui-pricing04_icon-wrapper {
  flex: none;
  align-self: flex-start;
  margin-right: 0.75rem;
}

.uui-pricing04_feature {
  justify-content: flex-start;
  display: flex;
}

.uui-pricing04_feature-list {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  margin-top: 2rem;
  margin-bottom: 2.5rem;
  display: grid;
}

.uui-pricing04_price-text {
  color: var(--untitled-ui-gray600);
  letter-spacing: normal;
  margin-left: 0.25rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
}

.uui-pricing04_price {
  color: var(--untitled-ui-gray900);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.2;
}

.uui-heading-xxsmall-8 {
  color: var(--untitled-ui-gray900);
  letter-spacing: normal;
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5;
}

.uui-text-align-center-9 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  text-align: left;
  flex-direction: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: flex-start;
  display: flex;
}

.uui-pricing04_content {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.uui-pricing04_plan {
  border: 1px solid var(--untitled-ui-gray200);
  border-radius: 1rem;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 2.5rem;
  padding: 2rem;
  position: relative;
  box-shadow: 0 12px 16px -4px rgba(0, 0, 0, 0.08),
    0 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.uui-pricing04_popular-label {
  z-index: 1;
  width: 100%;
  height: 2.5rem;
  background-color: var(--untitled-ui-primary700);
  outline-color: var(--untitled-ui-primary200);
  outline-offset: 0px;
  color: var(--untitled-ui-white);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  outline-width: 1px;
  outline-style: solid;
  justify-content: center;
  align-items: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  display: flex;
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0%;
}

.uui-pricing04_popular-plan {
  border: 1px solid var(--untitled-ui-gray200);
  border-radius: 1rem;
  padding: 4.5rem 2rem 2rem;
  position: relative;
  box-shadow: 0 12px 16px -4px rgba(0, 0, 0, 0.08),
    0 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.uui-pricing04_components {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: stretch;
  margin-top: 4rem;
}

.uui-text-size-large-9 {
  color: var(--untitled-ui-gray600);
  letter-spacing: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.125rem;
  line-height: 1.5;
}

.uui-space-xsmall-9 {
  width: 100%;
  min-height: 1rem;
}

.uui-heading-medium-10 {
  color: var(--untitled-ui-gray900);
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.3;
}

.uui-heading-subheading-10 {
  color: var(--untitled-ui-primary600);
  text-align: left;
  margin-bottom: 0.75rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
}

.uui-max-width-large-10 {
  width: 100%;
  max-width: 48rem;
}

.uui-max-width-large-10.align-center {
  margin-left: 0;
  margin-right: 0;
}

.align-center-9 {
  margin-left: auto;
  margin-right: auto;
}

.uui-padding-vertical-xhuge-16 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.uui-container-large-15 {
  width: 100%;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
}

.uui-page-padding-18 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.image-3,
.image-4,
.image-5,
.image-6 {
  max-width: 50%;
}

.image-7 {
  max-width: 80%;
  align-self: flex-start;
  padding-top: 70px;
}

.image-8 {
  padding-top: 50px;
}

.uui-layout25_lightbox-image {
  width: 110%;
  min-width: 110%;
  position: absolute;
  top: 8%;
}

.uui-lightbox_play-icon-5 {
  z-index: 2;
  width: 8rem;
  height: 8rem;
  -webkit-backdrop-filter: saturate(150%) blur(12px);
  backdrop-filter: saturate(150%) blur(12px);
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 100%;
  position: absolute;
}

.lightbox_video-overlay-layer-5 {
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(29, 41, 57, 0.1);
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.uui-layout25_lightbox {
  width: 100%;
  height: 40rem;
  background-color: var(--untitled-ui-gray100);
  justify-content: center;
  margin-top: 4rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.uui-button-15 {
  grid-column-gap: 0.5rem;
  border: 1px solid var(--untitled-ui-primary600);
  background-color: var(--untitled-ui-primary600);
  color: var(--untitled-ui-white);
  text-align: center;
  white-space: nowrap;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.125rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-button-15:hover {
  border-color: var(--untitled-ui-primary700);
  background-color: var(--untitled-ui-primary700);
}

.uui-button-15:focus {
  background-color: var(--untitled-ui-primary600);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05),
    0 0 0 4px var(--untitled-ui-primary100);
}

.uui-button-secondary-gray-13 {
  grid-column-gap: 0.5rem;
  border: 1px solid var(--untitled-ui-gray300);
  background-color: var(--untitled-ui-white);
  color: var(--untitled-ui-gray700);
  text-align: center;
  white-space: nowrap;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.125rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-button-secondary-gray-13:hover {
  background-color: var(--untitled-ui-gray50);
  color: var(--untitled-ui-gray800);
}

.uui-button-secondary-gray-13:focus {
  background-color: var(--untitled-ui-white);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05),
    0 0 0 4px var(--untitled-ui-gray100);
}

.uui-button-row-13 {
  grid-column-gap: 0.75rem;
  grid-row-gap: 0.75rem;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.uui-text-size-medium-10 {
  color: var(--untitled-ui-gray600);
  letter-spacing: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

.uui-space-xxsmall-8 {
  width: 100%;
  min-height: 0.5rem;
}

.uui-heading-xxsmall-9 {
  color: var(--untitled-ui-gray900);
  letter-spacing: normal;
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5;
}

.uui-space-xsmall-10 {
  width: 100%;
  min-height: 1rem;
}

.uui-icon-1x1-xsmall-10 {
  width: 1.5rem;
  height: 1.5rem;
}

.uui-icon-featured-outline-large-6 {
  width: 3.5rem;
  height: 3.5rem;
  border: 0.5rem solid var(--untitled-ui-primary50);
  background-color: var(--untitled-ui-primary100);
  color: var(--untitled-ui-primary600);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uui-layout25_item-list {
  grid-column-gap: 2rem;
  grid-row-gap: 3rem;
  grid-template-rows: auto;
  margin-bottom: 2.5rem;
}

.uui-text-size-large-10 {
  color: var(--untitled-ui-gray600);
  letter-spacing: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.125rem;
  line-height: 1.5;
}

.uui-heading-medium-11 {
  color: var(--untitled-ui-gray900);
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.3;
}

.uui-heading-subheading-11 {
  color: var(--untitled-ui-primary600);
  margin-bottom: 0.75rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
}

.uui-layout25_component {
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  display: grid;
}

.uui-padding-vertical-xhuge-17 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.uui-container-large-16 {
  width: 100%;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
}

.uui-page-padding-19 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.slider {
  height: 100%;
  min-height: 100%;
  background-color: #fff;
  padding-top: 0;
  padding-bottom: 0;
}

.slide {
  background-color: #fff;
  margin-bottom: 100px;
}

.mask {
  background-color: #fff;
}

.right-arrow {
  background-color: rgba(255, 255, 255, 0);
}

.icon {
  color: #7f56d9;
}

.icon-2 {
  color: #6f60be;
}

.left-arrow {
  color: rgba(51, 51, 51, 0);
}

.text-span-4 {
  color: var(--untitled-ui-primary800);
}

.uui-layout08_image-2 {
  width: 100%;
  border: 0.25rem solid var(--untitled-ui-gray900);
  border-radius: 0.5rem;
}

.uui-button-16 {
  grid-column-gap: 0.5rem;
  border: 1px solid var(--untitled-ui-primary600);
  background-color: var(--untitled-ui-primary600);
  color: var(--untitled-ui-white);
  text-align: center;
  white-space: nowrap;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.125rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-button-16:hover {
  border-color: var(--untitled-ui-primary700);
  background-color: var(--untitled-ui-primary700);
}

.uui-button-16:focus {
  background-color: var(--untitled-ui-primary600);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05),
    0 0 0 4px var(--untitled-ui-primary100);
}

.uui-button-secondary-gray-14 {
  grid-column-gap: 0.5rem;
  border: 1px solid var(--untitled-ui-gray300);
  background-color: var(--untitled-ui-white);
  color: var(--untitled-ui-gray700);
  text-align: center;
  white-space: nowrap;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.125rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-button-secondary-gray-14:hover {
  background-color: var(--untitled-ui-gray50);
  color: var(--untitled-ui-gray800);
}

.uui-button-secondary-gray-14:focus {
  background-color: var(--untitled-ui-white);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05),
    0 0 0 4px var(--untitled-ui-gray100);
}

.uui-button-row-14 {
  grid-column-gap: 0.75rem;
  grid-row-gap: 0.75rem;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.uui-text-size-large-11 {
  color: var(--untitled-ui-gray600);
  letter-spacing: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.125rem;
  line-height: 1.5;
}

.uui-icon-1x1-xsmall-11 {
  width: 1.5rem;
  height: 1.5rem;
}

.uui-layout08_item-icon-wrapper-2 {
  align-self: flex-start;
  margin-right: 0.75rem;
}

.uui-layout08_item-2 {
  display: flex;
}

.uui-layout08_item-list-2 {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  margin-top: 2rem;
  margin-bottom: 2.5rem;
  display: grid;
}

.uui-space-xsmall-11 {
  width: 100%;
  min-height: 1rem;
}

.uui-heading-medium-12 {
  color: var(--untitled-ui-gray900);
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.3;
}

.uui-heading-subheading-12 {
  color: var(--untitled-ui-primary600);
  margin-bottom: 0.75rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
}

.uui-layout08_component-2 {
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.uui-padding-vertical-xhuge-18 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.uui-container-large-17 {
  width: 100%;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
}

.uui-page-padding-20 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.text-span-5,
.text-span-6 {
  color: var(--untitled-ui-primary600);
}

.image-9 {
  max-width: 100%;
}

.uui-text-size-xlarge-10 {
  color: var(--untitled-ui-gray600);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 1.25rem;
  line-height: 1.5;
}

.uui-space-small-12 {
  width: 100%;
  min-height: 1.5rem;
}

.uui-heading-large-2 {
  color: var(--untitled-ui-gray900);
  margin-top: 0;
  margin-bottom: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.2;
}

.uui-text-align-center-10 {
  text-align: center;
}

.uui-container-small-2 {
  width: 100%;
  max-width: 48rem;
  margin-left: auto;
  margin-right: auto;
}

.html-embed {
  padding-left: 0;
  padding-right: 0;
}

@media screen and (max-width: 991px) {
  .menu-icon_line-bottom {
    width: 24px;
    height: 2px;
    background-color: var(--untitled-ui-gray700);
    border-radius: 1rem;
    padding-bottom: 0;
    padding-right: 0;
  }

  .menu-icon_line-middle-inner {
    width: 4px;
    height: 0;
    padding-bottom: 0;
    padding-right: 0;
  }

  .menu-icon_line-middle {
    width: 24px;
    height: 2px;
    background-color: var(--untitled-ui-gray700);
    border-radius: 1rem;
    justify-content: center;
    align-items: center;
    margin-top: 6px;
    margin-bottom: 6px;
    padding-bottom: 0;
    padding-right: 0;
    display: flex;
  }

  .menu-icon_line-top {
    width: 24px;
    height: 2px;
    background-color: var(--untitled-ui-gray700);
    border-radius: 1rem;
    padding-bottom: 0;
    padding-right: 0;
  }

  .menu-icon_component {
    width: 48px;
    height: 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: -0.5rem;
    padding-bottom: 0;
    padding-right: 0;
    display: flex;
  }

  .uui-navbar07_menu-button.w--open {
    background-color: rgba(0, 0, 0, 0);
  }

  .hide-tablet {
    display: none;
  }

  .uui-button-secondary-gray.show-tablet,
  .show-tablet {
    display: block;
  }

  .uui-navbar07_button-wrapper {
    flex-direction: column-reverse;
    align-items: stretch;
    display: flex;
  }

  .uui-navbar07_menu-right {
    flex-direction: column-reverse;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    align-items: stretch;
    margin-top: 1.5rem;
    padding-bottom: 7rem;
  }

  .uui-navbar07_link {
    width: 100%;
    color: var(--untitled-ui-gray900);
    padding: 0.75rem 0;
    font-size: 1.125rem;
  }

  .uui-navbar07_link:hover {
    color: var(--untitled-ui-gray900);
  }

  .uui-navbar07_dropdown-link {
    padding-left: 0;
    padding-right: 0;
  }

  .uui-navbar07_dropdown-link:hover {
    background-color: rgba(0, 0, 0, 0);
  }

  .uui-navbar07_dropdown-link-list {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .uui-navbar07_dropdown-list {
    position: static;
    overflow: hidden;
  }

  .uui-navbar07_dropdown-list.w--open {
    width: auto;
    box-shadow: none;
    border-style: none;
    border-color: rgba(0, 0, 0, 0);
    padding: 0;
  }

  .uui-dropdown-icon {
    color: var(--untitled-ui-gray500);
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
    top: auto;
    bottom: auto;
    left: auto;
    right: 0%;
  }

  .uui-navbar07_dropdown-toggle {
    color: var(--untitled-ui-gray900);
    align-items: center;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 0;
    font-size: 1.125rem;
    display: flex;
  }

  .uui-navbar07_dropdown-toggle:hover {
    color: var(--untitled-ui-gray900);
  }

  .uui-navbar07_menu-dropdown {
    width: 100%;
    position: relative;
  }

  .uui-navbar07_menu-left {
    grid-column-gap: 0px;
    grid-row-gap: 0.5rem;
    color: var(--untitled-ui-gray900);
    flex-direction: column;
    align-items: flex-start;
    display: flex;
  }

  .uui-navbar07_menu {
    height: 100vh;
    border-top: 1px solid var(--untitled-ui-gray100);
    -webkit-text-fill-color: inherit;
    background-color: #fff;
    background-clip: border-box;
    align-items: flex-start;
    margin-left: 0;
    padding: 1.5rem 2rem 5rem;
    position: absolute;
    overflow: auto;
  }

  .uui-space-large {
    min-height: 2.5rem;
  }

  .uui-heading-xlarge {
    font-size: 3.25rem;
  }

  .uui-padding-vertical-xhuge {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .uui-layout89_component {
    grid-template-columns: 1fr 1fr;
  }

  .uui-layout09_lightbox-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .uui-layout09_component {
    min-height: auto;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }

  .uui-padding-vertical-xhuge-2 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .uui-testimonial13_component {
    grid-template-columns: 1fr 1fr;
  }

  .uui-padding-vertical-xhuge-3 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .uui-layout13_image-01 {
    width: 100%;
    min-width: auto;
    top: 3%;
    right: -5%;
  }

  .uui-layout13_image-02 {
    display: none;
  }

  .uui-layout13_lightbox-image {
    width: 35rem;
    min-width: 35rem;
    left: 2rem;
  }

  .uui-layout13_lightbox {
    padding: 2rem;
  }

  .uui-layout13_component {
    grid-column-gap: 3rem;
  }

  .uui-heroheader14_image-background {
    width: 100vw;
  }

  .uui-heroheader14_card-image-05,
  .uui-heroheader14_card-image-04,
  .uui-heroheader14_card-image-03,
  .uui-heroheader14_card-image-02,
  .uui-heroheader14_card-image-01 {
    width: 32rem;
  }

  .uui-space-large-2 {
    min-height: 2.5rem;
  }

  .uui-heading-xlarge-2 {
    font-size: 3.25rem;
  }

  .uui-space-xhuge {
    min-height: 6rem;
  }

  .uui-heroheader04_image {
    width: 100%;
    min-width: auto;
    position: static;
  }

  .uui-heroheader04_image-wrapper {
    height: auto;
    min-height: auto;
  }

  .uui-heroheader04_content {
    text-align: center;
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .uui-heroheader04_component {
    min-height: auto;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }

  .uui-layout68_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .uui-layout68_component {
    min-height: auto;
    grid-column-gap: 3rem;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
  }

  .uui-heroheader01_fileupload-image-wrapper {
    left: -2.5%;
  }

  .uui-heroheader01_component {
    min-height: auto;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }

  .uui-layout47_component {
    grid-template-columns: 1fr 1fr;
  }

  .uui-padding-vertical-xhuge-4 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .uui-padding-vertical-xlarge {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .uui-contact11_component {
    grid-template-columns: 1fr 1fr;
  }

  .hide-tablet-2 {
    display: none;
  }

  .uui-space-large-3 {
    min-height: 2.5rem;
  }

  .uui-heading-xlarge-3 {
    font-size: 3.25rem;
  }

  .uui-heroheader03_content {
    margin-right: 0;
  }

  .uui-heroheader03_component {
    min-height: auto;
    grid-row-gap: 4rem;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }

  .uui-layout01_image {
    width: 35rem;
    min-width: 35rem;
    left: 2rem;
  }

  .uui-layout01_component {
    grid-column-gap: 3rem;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
  }

  .uui-padding-vertical-xhuge-5 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .uui-contact07_map-wrapper {
    height: 30rem;
  }

  .uui-contact07_component {
    grid-column-gap: 3rem;
  }

  .uui-padding-vertical-xhuge-6 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .uui-space-large-4 {
    min-height: 2.5rem;
  }

  .uui-heading-xlarge-4 {
    font-size: 3.25rem;
  }

  .uui-padding-vertical-xhuge-7 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .uui-space-large-5 {
    min-height: 2.5rem;
  }

  .uui-heading-xlarge-5 {
    font-size: 3.25rem;
  }

  .uui-padding-vertical-xhuge-8 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .uui-heading-xlarge-6 {
    font-size: 3.25rem;
  }

  .uui-heroheader08_component {
    min-height: auto;
    grid-column-gap: 0rem;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }

  .uui-padding-vertical-xhuge-9 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .uui-space-large-6 {
    min-height: 2.5rem;
  }

  .uui-padding-vertical-xhuge-10 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .uui-layout38_logo-row {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .nav-menu-wrapper {
    background-color: rgba(0, 0, 0, 0);
  }

  .nav-menu {
    background-color: #fff;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding-left: 0;
    display: flex;
  }

  .nav-link {
    padding-left: 5px;
    padding-right: 5px;
  }

  .nav-dropdown-list.shadow-three.w--open {
    position: absolute;
  }

  .nav-button-wrapper {
    width: 100%;
    margin-left: 0;
  }

  .menu-button {
    padding: 12px;
  }

  .menu-button.w--open {
    color: #fff;
    background-color: #a6b1bf;
  }

  .uui-layout62_image-01 {
    width: 100%;
    min-width: auto;
    top: 3%;
    right: -5%;
  }

  .uui-layout62_image-02 {
    display: none;
  }

  .uui-layout62_lightbox-image {
    width: 35rem;
    min-width: 35rem;
    right: 2rem;
  }

  .uui-layout62_component {
    grid-column-gap: 3rem;
  }

  .uui-padding-vertical-xhuge-11 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .uui-layout47_component-2 {
    grid-template-columns: 1fr 1fr;
  }

  .uui-layout32_timeline-step {
    grid-column-gap: 24px;
  }

  .uui-layout32_component {
    grid-column-gap: 2rem;
  }

  .uui-heading-xlarge-7 {
    font-size: 3.25rem;
  }

  .uui-heroheader08_component-2 {
    min-height: auto;
    grid-column-gap: 0rem;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }

  .uui-padding-vertical-xhuge-12 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .uui-layout03_image {
    width: 35rem;
    min-width: 35rem;
    left: 2rem;
  }

  .uui-layout03_component {
    min-height: auto;
    grid-column-gap: 3rem;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
  }

  .uui-padding-vertical-xhuge-13 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .uui-heroheader01_fileupload-image-wrapper-2 {
    left: -2.5%;
  }

  .uui-space-large-7 {
    min-height: 2.5rem;
  }

  .uui-heading-xlarge-8 {
    font-size: 3.25rem;
  }

  .uui-heroheader01_component-2 {
    min-height: auto;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }

  .uui-padding-vertical-xhuge-14 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .uui-space-large-8 {
    min-height: 2.5rem;
  }

  .uui-heading-xlarge-9 {
    font-size: 3.25rem;
  }

  .uui-heroheader05_component {
    min-height: auto;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }

  .uui-padding-vertical-xhuge-15 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .uui-blog01_list {
    grid-template-columns: 1fr 1fr;
  }

  .uui-heading-large {
    font-size: 2.75rem;
  }

  .uui-layout01_image-2 {
    width: 35rem;
    min-width: 35rem;
    left: 2rem;
  }

  .uui-layout01_component-2 {
    grid-column-gap: 3rem;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
  }

  .uui-layout08_component {
    min-height: auto;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }

  .uui-blogpost01_content {
    margin-bottom: 3rem;
  }

  .uui-blogpost01_image-wrapper {
    margin-bottom: 3rem;
    padding-top: 56.25%;
  }

  .uui-blogpost01_title-wrapper {
    margin-bottom: 4rem;
  }

  .uui-pricing04_plan {
    margin-top: 0;
  }

  .uui-pricing04_components {
    grid-template-columns: 1fr;
  }

  .uui-padding-vertical-xhuge-16 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .uui-layout25_lightbox {
    height: 27rem;
  }

  .uui-layout25_component {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    grid-template-columns: 1fr;
  }

  .uui-padding-vertical-xhuge-17 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .uui-layout08_component-2 {
    min-height: auto;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }

  .uui-padding-vertical-xhuge-18 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .uui-heading-large-2 {
    font-size: 2.75rem;
  }
}

@media screen and (max-width: 767px) {
  .uui-button {
    font-size: 1rem;
  }

  .uui-button:hover {
    border-color: var(--untitled-ui-primary600);
    background-color: var(--untitled-ui-primary600);
  }

  .uui-button.is-button-large {
    grid-column-gap: 0.5rem;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
  }

  .uui-button-tertiary-gray:hover {
    color: var(--untitled-ui-gray600);
    background-color: rgba(0, 0, 0, 0);
  }

  .uui-button-secondary-gray {
    padding-left: 1.25rem;
    font-size: 1rem;
  }

  .uui-button-secondary-gray:hover {
    background-color: var(--untitled-ui-white);
    color: var(--untitled-ui-gray700);
  }

  .uui-button-secondary-gray.is-button-large {
    grid-column-gap: 0.5rem;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
  }

  .uui-navbar07_link {
    font-size: 1rem;
  }

  .uui-navbar07_dropdown-link-list {
    max-width: none;
    grid-row-gap: 0.5rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .uui-navbar07_dropdown-toggle {
    font-size: 1rem;
  }

  .uui-navbar07_menu {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .uui-styleguide_logomark-bg {
    background-image: url("../images/untitled-ui-logomark.svg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .uui-navbar07_component {
    min-height: 4.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .uui-button-wrapper.max-width-full-mobile-landscape {
    width: 100%;
  }

  .max-width-full-mobile-landscape {
    width: 100%;
    max-width: none;
  }

  .uui-button-icon.is-icon-large {
    width: 1.25rem;
    height: 1.25rem;
  }

  .uui-button-row {
    align-self: stretch;
  }

  .uui-button-row.button-row-center.is-reverse-mobile-landscape,
  .uui-button-row.is-reverse-mobile-landscape {
    flex-direction: column-reverse;
  }

  .uui-space-large {
    min-height: 2rem;
  }

  .uui-text-size-xlarge {
    font-size: 1.125rem;
  }

  .uui-space-small {
    min-height: 1.25rem;
  }

  .uui-heading-xlarge {
    font-size: 2.25rem;
  }

  .uui-padding-vertical-xhuge {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .uui-page-padding {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .uui-button-link {
    font-size: 1rem;
  }

  .uui-button-link:hover {
    color: var(--untitled-ui-primary700);
  }

  .uui-heading-xxsmall {
    font-size: 1.125rem;
  }

  .uui-layout89_component {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .uui-layout70_image-wrapper {
    margin-bottom: 1.5rem;
  }

  .uui-layout70_component {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .uui-lightbox_play-icon {
    width: 4rem;
    height: 4rem;
  }

  .uui-button-2 {
    font-size: 1rem;
  }

  .uui-button-2:hover {
    border-color: var(--untitled-ui-primary600);
    background-color: var(--untitled-ui-primary600);
  }

  .uui-button-wrapper-2.max-width-full-mobile-landscape {
    width: 100%;
  }

  .max-width-full-mobile-landscape-2 {
    width: 100%;
    max-width: none;
  }

  .uui-button-secondary-gray-2 {
    padding-left: 1.25rem;
    font-size: 1rem;
  }

  .uui-button-secondary-gray-2:hover {
    background-color: var(--untitled-ui-white);
    color: var(--untitled-ui-gray700);
  }

  .uui-button-row-2 {
    align-self: stretch;
  }

  .uui-button-row-2.is-reverse-mobile-landscape {
    flex-direction: column-reverse;
  }

  .uui-text-size-large {
    font-size: 1rem;
  }

  .uui-layout09_item-list {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }

  .uui-space-xsmall {
    min-height: 0.75rem;
  }

  .uui-heading-medium {
    font-size: 1.75rem;
    line-height: 1.4;
  }

  .uui-layout09_component {
    grid-row-gap: 3rem;
  }

  .uui-padding-vertical-xhuge-2 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .uui-page-padding-2 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .uui-testimonial13_client-image-wrapper {
    margin-bottom: 1rem;
    margin-right: 0;
  }

  .uui-testimonial13_client {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

  .uui-heading-xxsmall-2 {
    font-size: 1.125rem;
  }

  .uui-testimonial13_content {
    padding: 1.5rem;
  }

  .uui-testimonial13_component {
    grid-row-gap: 1.5rem;
    grid-template-columns: 1fr;
    margin-top: 3rem;
  }

  .uui-space-small-2 {
    min-height: 1.25rem;
  }

  .uui-contact10_component {
    grid-row-gap: 2.5rem;
    grid-template-columns: 1fr;
    margin-top: 3rem;
  }

  .uui-button-3 {
    font-size: 1rem;
  }

  .uui-button-3:hover {
    border-color: var(--untitled-ui-primary600);
    background-color: var(--untitled-ui-primary600);
  }

  .uui-button-3.is-button-large {
    grid-column-gap: 0.5rem;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
  }

  .uui-button-row-3 {
    align-self: stretch;
  }

  .uui-button-row-3.button-row-center.is-reverse-mobile-landscape,
  .uui-button-row-3.is-reverse-mobile-landscape {
    flex-direction: column-reverse;
  }

  .uui-button-row-3.button-row-center-mobile-landscape {
    justify-content: center;
  }

  .uui-space-small-3 {
    min-height: 1.25rem;
  }

  .uui-text-size-large-2 {
    font-size: 1rem;
  }

  .uui-heading-xxsmall-3 {
    font-size: 1.125rem;
  }

  .avatar-stack_component {
    margin-bottom: 1.5rem;
  }

  .uui-faq01_cta {
    margin-top: 3rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .uui-space-medium {
    min-height: 1.5rem;
  }

  .uui-faq01_component {
    margin-top: 3rem;
  }

  .uui-space-xsmall-2 {
    min-height: 0.75rem;
  }

  .uui-heading-medium-2 {
    font-size: 1.75rem;
    line-height: 1.4;
  }

  .uui-padding-vertical-xhuge-3 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .uui-page-padding-3 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .uui-layout13_image-01 {
    width: 60%;
    min-width: 60%;
    top: 2%;
    right: 0%;
  }

  .uui-layout13_image-02 {
    width: 50%;
    min-width: auto;
    display: block;
    top: 25%;
    left: 5%;
  }

  .uui-layout13_image-wrapper {
    height: 24rem;
    min-height: auto;
  }

  .uui-layout13_lightbox-image {
    width: 100%;
    min-width: 100%;
    position: static;
  }

  .uui-lightbox_play-icon-2 {
    width: 4rem;
    height: 4rem;
  }

  .uui-layout13_lightbox {
    height: 24rem;
    min-height: auto;
    padding: 1.5rem 1rem;
  }

  .uui-heading-xsmall {
    font-size: 1.25rem;
  }

  .uui-layout13_tabs-link {
    padding-left: 1.5rem;
  }

  .uui-layout13_component {
    grid-column-gap: 0px;
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .uui-heroheader14_card-image-05,
  .uui-heroheader14_card-image-04,
  .uui-heroheader14_card-image-03,
  .uui-heroheader14_card-image-02,
  .uui-heroheader14_card-image-01 {
    width: 24rem;
    border-radius: 0.75rem;
  }

  .uui-heroheader14_image-wrapper {
    width: 100vw;
    height: 22rem;
  }

  .uui-button-wrapper-3.max-width-full-mobile-landscape {
    width: 100%;
  }

  .max-width-full-mobile-landscape-3 {
    width: 100%;
    max-width: none;
  }

  .uui-button-icon-2.is-icon-large {
    width: 1.25rem;
    height: 1.25rem;
  }

  .uui-button-secondary-gray-3 {
    padding-left: 1.25rem;
    font-size: 1rem;
  }

  .uui-button-secondary-gray-3:hover {
    background-color: var(--untitled-ui-white);
    color: var(--untitled-ui-gray700);
  }

  .uui-button-secondary-gray-3.is-button-large {
    grid-column-gap: 0.5rem;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
  }

  .uui-space-large-2 {
    min-height: 2rem;
  }

  .uui-text-size-xlarge-2 {
    font-size: 1.125rem;
  }

  .uui-heading-xlarge-2 {
    font-size: 2.25rem;
  }

  .uui-badge-group_badge {
    padding: 0.125rem 0.5rem;
    font-size: 0.75rem;
  }

  .uui-badge-group {
    font-size: 0.75rem;
  }

  .uui-space-xhuge {
    min-height: 4rem;
  }

  .uui-heroheader04_content {
    text-align: center;
    align-items: stretch;
  }

  .uui-button-link-2 {
    font-size: 1rem;
  }

  .uui-button-link-2:hover {
    color: var(--untitled-ui-primary700);
  }

  .uui-layout68_item-list {
    grid-row-gap: 2.5rem;
  }

  .uui-layout68_component {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .uui-contact02_component {
    margin-top: 3rem;
  }

  .uui-heroheader01_pattern-image {
    width: 14.9rem;
    top: auto;
    bottom: -15%;
    right: -10%;
  }

  .hide-mobile-landscape {
    display: none;
  }

  .uui-heroheader01_image {
    height: 22rem;
    border-top-left-radius: 6rem;
  }

  .uui-button-icon-3.is-icon-large {
    width: 1.25rem;
    height: 1.25rem;
  }

  .uui-button-link-3 {
    font-size: 1rem;
  }

  .uui-button-link-3:hover {
    color: var(--untitled-ui-primary700);
  }

  .uui-button-row-4 {
    align-self: stretch;
  }

  .uui-button-row-4.is-reverse-mobile-landscape {
    flex-direction: column-reverse;
  }

  .uui-space-small-4 {
    min-height: 1.25rem;
  }

  .uui-heading-xsmall-2 {
    font-size: 1.25rem;
  }

  .uui-layout47_component {
    grid-row-gap: 2.5rem;
    grid-template-columns: 1fr;
  }

  .uui-padding-vertical-xhuge-4 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .uui-page-padding-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .uui-footer07_legal-link:hover {
    color: var(--untitled-ui-gray400);
  }

  .uui-footer07_legal-list {
    margin-bottom: 1rem;
  }

  .uui-footer07_bottom-wrapper {
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 3rem;
  }

  .uui-footer07_link:hover {
    color: var(--untitled-ui-gray500);
  }

  .uui-footer07_link-list {
    grid-row-gap: 0.5rem;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    justify-items: center;
  }

  .uui-styleguide_logomark-bg-2 {
    background-image: url("../images/untitled-ui-logomark.svg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .uui-padding-vertical-xlarge {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .uui-heading-xxsmall-4 {
    font-size: 1.125rem;
  }

  .uui-contact11_component {
    grid-row-gap: 2.5rem;
    grid-template-columns: 1fr;
    margin-top: 3rem;
  }

  .uui-text-size-large-3 {
    font-size: 1rem;
  }

  .uui-space-xsmall-3 {
    min-height: 0.75rem;
  }

  .uui-heading-medium-3 {
    font-size: 1.75rem;
    line-height: 1.4;
  }

  .uui-heroheader03_image {
    height: 22rem;
    border-top-right-radius: 3rem;
    border-bottom-left-radius: 3rem;
  }

  .uui-button-4 {
    font-size: 1rem;
  }

  .uui-button-4:hover {
    border-color: var(--untitled-ui-primary600);
    background-color: var(--untitled-ui-primary600);
  }

  .uui-button-4.is-button-large {
    grid-column-gap: 0.5rem;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
  }

  .uui-button-wrapper-4.max-width-full-mobile-landscape {
    width: 100%;
  }

  .max-width-full-mobile-landscape-4 {
    width: 100%;
    max-width: none;
  }

  .uui-button-secondary-gray-4 {
    padding-left: 1.25rem;
    font-size: 1rem;
  }

  .uui-button-secondary-gray-4:hover {
    background-color: var(--untitled-ui-white);
    color: var(--untitled-ui-gray700);
  }

  .uui-button-secondary-gray-4.is-button-large {
    grid-column-gap: 0.5rem;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
  }

  .uui-space-large-3 {
    min-height: 2rem;
  }

  .uui-text-size-xlarge-3 {
    font-size: 1.125rem;
  }

  .uui-heading-xlarge-3 {
    font-size: 2.25rem;
  }

  .uui-layout01_image {
    width: 100%;
    min-width: 100%;
    position: static;
  }

  .uui-layout01_image-wrapper {
    min-height: auto;
    padding: 1.5rem 1rem;
  }

  .uui-button-5 {
    font-size: 1rem;
  }

  .uui-button-5:hover {
    border-color: var(--untitled-ui-primary600);
    background-color: var(--untitled-ui-primary600);
  }

  .uui-button-wrapper-5.max-width-full-mobile-landscape {
    width: 100%;
  }

  .max-width-full-mobile-landscape-5 {
    width: 100%;
    max-width: none;
  }

  .uui-button-secondary-gray-5 {
    padding-left: 1.25rem;
    font-size: 1rem;
  }

  .uui-button-secondary-gray-5:hover {
    background-color: var(--untitled-ui-white);
    color: var(--untitled-ui-gray700);
  }

  .uui-button-row-5 {
    align-self: stretch;
  }

  .uui-button-row-5.is-reverse-mobile-landscape {
    flex-direction: column-reverse;
  }

  .uui-space-medium-2 {
    min-height: 1.5rem;
  }

  .uui-text-size-large-4 {
    font-size: 1rem;
  }

  .uui-space-xsmall-4 {
    min-height: 0.75rem;
  }

  .uui-heading-medium-4 {
    font-size: 1.75rem;
    line-height: 1.4;
  }

  .uui-layout01_component {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .uui-padding-vertical-xhuge-5 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .uui-page-padding-5 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .uui-contact07_map-wrapper {
    height: 25rem;
    margin-top: 3rem;
  }

  .uui-heading-xxsmall-5 {
    font-size: 1.125rem;
  }

  .uui-contact07_contact-list {
    grid-row-gap: 2.5rem;
  }

  .uui-text-size-large-5 {
    font-size: 1rem;
  }

  .uui-space-xsmall-5 {
    min-height: 0.75rem;
  }

  .uui-heading-medium-5 {
    font-size: 1.75rem;
    line-height: 1.4;
  }

  .uui-contact07_component {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .uui-padding-vertical-xhuge-6 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .uui-page-padding-6 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .uui-button-6 {
    font-size: 1rem;
  }

  .uui-button-6:hover {
    border-color: var(--untitled-ui-primary600);
    background-color: var(--untitled-ui-primary600);
  }

  .uui-button-6.is-button-large {
    grid-column-gap: 0.5rem;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
  }

  .uui-button-wrapper-6.max-width-full-mobile-landscape {
    width: 100%;
  }

  .max-width-full-mobile-landscape-6 {
    width: 100%;
    max-width: none;
  }

  .uui-button-icon-4.is-icon-large {
    width: 1.25rem;
    height: 1.25rem;
  }

  .uui-button-secondary-gray-6 {
    padding-left: 1.25rem;
    font-size: 1rem;
  }

  .uui-button-secondary-gray-6:hover {
    background-color: var(--untitled-ui-white);
    color: var(--untitled-ui-gray700);
  }

  .uui-button-secondary-gray-6.is-button-large {
    grid-column-gap: 0.5rem;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
  }

  .uui-button-row-6 {
    align-self: stretch;
  }

  .uui-button-row-6.button-row-center.is-reverse-mobile-landscape,
  .uui-button-row-6.is-reverse-mobile-landscape {
    flex-direction: column-reverse;
  }

  .uui-space-large-4 {
    min-height: 2rem;
  }

  .uui-text-size-xlarge-4 {
    font-size: 1.125rem;
  }

  .uui-space-small-5 {
    min-height: 1.25rem;
  }

  .uui-heading-xlarge-4 {
    font-size: 2.25rem;
  }

  .uui-padding-vertical-xhuge-7 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .uui-page-padding-7 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .uui-button-7 {
    font-size: 1rem;
  }

  .uui-button-7:hover {
    border-color: var(--untitled-ui-primary600);
    background-color: var(--untitled-ui-primary600);
  }

  .uui-button-7.is-button-large {
    grid-column-gap: 0.5rem;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
  }

  .uui-button-wrapper-7.max-width-full-mobile-landscape {
    width: 100%;
  }

  .max-width-full-mobile-landscape-7 {
    width: 100%;
    max-width: none;
  }

  .uui-button-icon-5.is-icon-large {
    width: 1.25rem;
    height: 1.25rem;
  }

  .uui-button-secondary-gray-7 {
    padding-left: 1.25rem;
    font-size: 1rem;
  }

  .uui-button-secondary-gray-7:hover {
    background-color: var(--untitled-ui-white);
    color: var(--untitled-ui-gray700);
  }

  .uui-button-secondary-gray-7.is-button-large {
    grid-column-gap: 0.5rem;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
  }

  .uui-button-row-7 {
    align-self: stretch;
  }

  .uui-button-row-7.button-row-center.is-reverse-mobile-landscape,
  .uui-button-row-7.is-reverse-mobile-landscape {
    flex-direction: column-reverse;
  }

  .uui-space-large-5 {
    min-height: 2rem;
  }

  .uui-text-size-xlarge-5 {
    font-size: 1.125rem;
  }

  .uui-space-small-6 {
    min-height: 1.25rem;
  }

  .uui-heading-xlarge-5 {
    font-size: 2.25rem;
  }

  .uui-padding-vertical-xhuge-8 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .uui-page-padding-8,
  .uui-page-padding-9,
  .uui-page-padding-10 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .uui-heroheader08_image {
    height: 22rem;
  }

  .uui-button-8 {
    font-size: 1rem;
  }

  .uui-button-8:hover {
    border-color: var(--untitled-ui-primary600);
    background-color: var(--untitled-ui-primary600);
  }

  .uui-button-8.is-button-large {
    grid-column-gap: 0.5rem;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
  }

  .uui-heroheader08_form {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .uui-text-size-xlarge-6 {
    font-size: 1.125rem;
  }

  .uui-space-small-7 {
    min-height: 1.25rem;
  }

  .uui-heading-xlarge-6 {
    font-size: 2.25rem;
  }

  .uui-padding-vertical-xhuge-9 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .uui-page-padding-11 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .uui-button-wrapper-8.max-width-full-mobile-landscape {
    width: 100%;
  }

  .max-width-full-mobile-landscape-8 {
    width: 100%;
    max-width: none;
  }

  .uui-button-icon-6.is-icon-large {
    width: 1.25rem;
    height: 1.25rem;
  }

  .uui-button-secondary-gray-8 {
    padding-left: 1.25rem;
    font-size: 1rem;
  }

  .uui-button-secondary-gray-8:hover {
    background-color: var(--untitled-ui-white);
    color: var(--untitled-ui-gray700);
  }

  .uui-button-secondary-gray-8.is-button-large {
    grid-column-gap: 0.5rem;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
  }

  .uui-button-row-8 {
    align-self: stretch;
  }

  .uui-button-row-8.button-row-center.is-reverse-mobile-landscape,
  .uui-button-row-8.is-reverse-mobile-landscape {
    flex-direction: column-reverse;
  }

  .uui-space-large-6 {
    min-height: 2rem;
  }

  .uui-heading-xxsmall-6 {
    font-size: 1.125rem;
  }

  .uui-layout74_list {
    grid-row-gap: 2.5rem;
    grid-template-columns: 1fr;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .uui-text-size-large-6 {
    font-size: 1rem;
  }

  .uui-space-xsmall-6 {
    min-height: 0.75rem;
  }

  .uui-heading-medium-6 {
    font-size: 1.75rem;
    line-height: 1.4;
  }

  .uui-layout74_component {
    align-items: stretch;
  }

  .uui-padding-vertical-xhuge-10 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .uui-page-padding-12 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .uui-layout38_logo-row {
    margin-bottom: 3rem;
    padding-left: 0;
    padding-right: 0;
  }

  .uui-space-medium-3 {
    min-height: 1.5rem;
  }

  .navbar-brand {
    padding-left: 0;
  }

  .nav-menu {
    flex-direction: column;
    padding-bottom: 30px;
    padding-left: 0;
  }

  .nav-link {
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
  }

  .nav-dropdown {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .nav-dropdown-toggle {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .nav-dropdown-list.shadow-three {
    box-shadow: 0 8px 50px rgba(0, 0, 0, 0.05);
  }

  .nav-dropdown-list.shadow-three.w--open {
    position: relative;
  }

  .nav-dropdown-list.shadow-three.mobile-shadow-hide {
    box-shadow: none;
  }

  .mobile-margin-top-10 {
    margin-top: 10px;
  }

  .uui-layout62_image-01 {
    width: 60%;
    min-width: 60%;
    top: 2%;
    right: 0%;
  }

  .uui-layout62_image-02 {
    width: 50%;
    min-width: auto;
    display: block;
    top: 25%;
    left: 5%;
  }

  .uui-layout62_image-wrapper {
    height: 24rem;
    min-height: auto;
  }

  .uui-layout62_lightbox-image {
    width: 100%;
    min-width: 100%;
    position: static;
  }

  .uui-lightbox_play-icon-3 {
    width: 4rem;
    height: 4rem;
  }

  .uui-layout62_lightbox {
    min-height: auto;
    padding: 1.5rem 1rem;
  }

  .uui-heading-xsmall-3 {
    font-size: 1.25rem;
  }

  .uui-layout62_tabs-link {
    padding-left: 1.5rem;
  }

  .uui-layout62_component {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .uui-padding-vertical-xhuge-11 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .uui-page-padding-13 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .uui-button-link-4 {
    font-size: 1rem;
  }

  .uui-button-link-4:hover {
    color: var(--untitled-ui-primary700);
  }

  .uui-button-row-9 {
    align-self: stretch;
  }

  .uui-button-row-9.is-reverse-mobile-landscape {
    flex-direction: column-reverse;
  }

  .uui-space-small-8 {
    min-height: 1.25rem;
  }

  .uui-layout47_component-2 {
    grid-row-gap: 2.5rem;
    grid-template-columns: 1fr;
  }

  .uui-heading-xxsmall-7 {
    font-size: 1.125rem;
  }

  .uui-button-9 {
    font-size: 1rem;
  }

  .uui-button-9:hover {
    border-color: var(--untitled-ui-primary600);
    background-color: var(--untitled-ui-primary600);
  }

  .uui-button-wrapper-9.max-width-full-mobile-landscape {
    width: 100%;
  }

  .max-width-full-mobile-landscape-9 {
    width: 100%;
    max-width: none;
  }

  .uui-button-secondary-gray-9 {
    padding-left: 1.25rem;
    font-size: 1rem;
  }

  .uui-button-secondary-gray-9:hover {
    background-color: var(--untitled-ui-white);
    color: var(--untitled-ui-gray700);
  }

  .uui-space-medium-4 {
    min-height: 1.5rem;
  }

  .uui-heading-medium-7 {
    font-size: 1.75rem;
    line-height: 1.4;
  }

  .uui-layout32_component {
    grid-row-gap: 2rem;
    grid-template-columns: 1fr;
  }

  .uui-heroheader08_image-2 {
    height: 22rem;
  }

  .uui-button-10 {
    font-size: 1rem;
  }

  .uui-button-10:hover {
    border-color: var(--untitled-ui-primary600);
    background-color: var(--untitled-ui-primary600);
  }

  .uui-heroheader08_form-2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .uui-text-size-xlarge-7 {
    font-size: 1.125rem;
  }

  .uui-space-small-9 {
    min-height: 1.25rem;
  }

  .uui-heading-xlarge-7 {
    font-size: 2.25rem;
  }

  .uui-padding-vertical-xhuge-12 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .uui-page-padding-14 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .uui-layout03_image {
    width: 100%;
    min-width: 100%;
    position: static;
  }

  .uui-lightbox_play-icon-4 {
    width: 4rem;
    height: 4rem;
  }

  .uui-layout03_lightbox {
    min-height: auto;
    padding: 1.5rem 1rem;
  }

  .uui-button-11 {
    font-size: 1rem;
  }

  .uui-button-11:hover {
    border-color: var(--untitled-ui-primary600);
    background-color: var(--untitled-ui-primary600);
  }

  .uui-button-wrapper-10.max-width-full-mobile-landscape {
    width: 100%;
  }

  .max-width-full-mobile-landscape-10 {
    width: 100%;
    max-width: none;
  }

  .uui-button-secondary-gray-10 {
    padding-left: 1.25rem;
    font-size: 1rem;
  }

  .uui-button-secondary-gray-10:hover {
    background-color: var(--untitled-ui-white);
    color: var(--untitled-ui-gray700);
  }

  .uui-button-row-10 {
    align-self: stretch;
  }

  .uui-button-row-10.is-reverse-mobile-landscape {
    flex-direction: column-reverse;
  }

  .uui-space-medium-5 {
    min-height: 1.5rem;
  }

  .uui-text-size-large-7 {
    font-size: 1rem;
  }

  .uui-space-xsmall-7 {
    min-height: 0.75rem;
  }

  .uui-heading-medium-8 {
    font-size: 1.75rem;
    line-height: 1.4;
  }

  .uui-layout03_component {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .uui-padding-vertical-xhuge-13 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .uui-page-padding-15 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .uui-heroheader01_pattern-image-2 {
    width: 14.9rem;
    top: auto;
    bottom: -15%;
    right: -10%;
  }

  .hide-mobile-landscape-2 {
    display: none;
  }

  .uui-heroheader01_image-2 {
    height: 22rem;
    border-top-left-radius: 6rem;
  }

  .uui-button-12 {
    font-size: 1rem;
  }

  .uui-button-12:hover {
    border-color: var(--untitled-ui-primary600);
    background-color: var(--untitled-ui-primary600);
  }

  .uui-button-12.is-button-large {
    grid-column-gap: 0.5rem;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
  }

  .uui-button-wrapper-11.max-width-full-mobile-landscape {
    width: 100%;
  }

  .max-width-full-mobile-landscape-11 {
    width: 100%;
    max-width: none;
  }

  .uui-button-icon-8.is-icon-large {
    width: 1.25rem;
    height: 1.25rem;
  }

  .uui-button-secondary-gray-11 {
    padding-left: 1.25rem;
    font-size: 1rem;
  }

  .uui-button-secondary-gray-11:hover {
    background-color: var(--untitled-ui-white);
    color: var(--untitled-ui-gray700);
  }

  .uui-button-secondary-gray-11.is-button-large {
    grid-column-gap: 0.5rem;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
  }

  .uui-button-row-11 {
    align-self: stretch;
  }

  .uui-button-row-11.is-reverse-mobile-landscape {
    flex-direction: column-reverse;
  }

  .uui-space-large-7 {
    min-height: 2rem;
  }

  .uui-text-size-xlarge-8 {
    font-size: 1.125rem;
  }

  .uui-space-small-10 {
    min-height: 1.25rem;
  }

  .uui-heading-xlarge-8 {
    font-size: 2.25rem;
  }

  .uui-padding-vertical-xhuge-14 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .uui-page-padding-16 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .uui-heroheader05_image-blob {
    width: 120%;
    min-width: 28rem;
    top: 22%;
  }

  .uui-heroheader05_image {
    height: 40rem;
    margin-left: 1.5rem;
  }

  .uui-heroheader05_image-wrapper {
    height: 22rem;
  }

  .uui-button-wrapper-12.max-width-full-mobile-landscape {
    width: 100%;
  }

  .uui-button-row-12 {
    align-self: stretch;
  }

  .uui-button-row-12.is-reverse-mobile-landscape {
    flex-direction: column-reverse;
  }

  .uui-space-large-8 {
    min-height: 2rem;
  }

  .uui-text-size-xlarge-9 {
    font-size: 1.125rem;
  }

  .uui-space-small-11 {
    min-height: 1.25rem;
  }

  .uui-heading-xlarge-9 {
    font-size: 2.25rem;
  }

  .uui-badge-group_badge-2 {
    padding: 0.125rem 0.5rem;
    font-size: 0.75rem;
  }

  .uui-badge-group-2 {
    font-size: 0.75rem;
  }

  .uui-padding-vertical-xhuge-15 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .uui-page-padding-17 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .uui-heading-xsmall-4 {
    font-size: 1.25rem;
  }

  .uui-blog01_image-link {
    margin-bottom: 1.5rem;
  }

  .uui-blog01_image-link:hover {
    box-shadow: none;
  }

  .uui-blog01_list {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .uui-blog01_menu-link:hover {
    color: var(--untitled-ui-gray500);
    border-bottom-style: none;
  }

  .uui-blog01_menu-link.last-link {
    margin-right: 2rem;
  }

  .uui-blog01_category-menu {
    width: 100vw;
    margin-left: -5vw;
    padding-left: 5vw;
    overflow: scroll;
  }

  .uui-blog01_category-menu.no-scrollbar {
    justify-content: flex-start;
    margin-bottom: 3rem;
  }

  .uui-blog01_component {
    margin-top: 4rem;
  }

  .uui-text-size-large-8 {
    font-size: 1rem;
  }

  .uui-heading-large {
    font-size: 2.25rem;
  }

  .uui-layout01_image-2 {
    width: 100%;
    min-width: 100%;
    position: static;
  }

  .uui-layout01_image-wrapper-2 {
    min-height: auto;
    padding: 1.5rem 1rem;
  }

  .uui-button-13 {
    font-size: 1rem;
  }

  .uui-button-13:hover {
    border-color: var(--untitled-ui-primary600);
    background-color: var(--untitled-ui-primary600);
  }

  .max-width-full-mobile-landscape-12 {
    width: 100%;
    max-width: none;
  }

  .uui-button-secondary-gray-12 {
    padding-left: 1.25rem;
    font-size: 1rem;
  }

  .uui-button-secondary-gray-12:hover {
    background-color: var(--untitled-ui-white);
    color: var(--untitled-ui-gray700);
  }

  .uui-space-medium-6 {
    min-height: 1.5rem;
  }

  .uui-space-xsmall-8 {
    min-height: 0.75rem;
  }

  .uui-heading-medium-9 {
    font-size: 1.75rem;
    line-height: 1.4;
  }

  .uui-layout01_component-2 {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .uui-layout08_item-list {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }

  .uui-layout08_component {
    grid-row-gap: 3rem;
  }

  .uui-blogpost01_author-heading {
    font-size: 1rem;
  }

  .uui-blogpost01_author-image {
    width: 3rem;
    height: 3rem;
    min-height: 3rem;
    min-width: 3rem;
  }

  .uui-blogpost01_author-image-wrapper {
    margin-right: 0.75rem;
  }

  .uui-blogpost01_divider {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .uui-badge {
    padding: 0.125rem 0.5rem;
  }

  .uui-text-rich-text figure {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .uui-text-rich-text figcaption {
    margin-top: 0.75rem;
  }

  .uui-blogpost01_content {
    margin-bottom: 2rem;
  }

  .uui-blogpost01_content-top {
    margin-top: 2rem;
  }

  .uui-blogpost01_title-wrapper {
    margin-bottom: 3rem;
  }

  .uui-button-14 {
    font-size: 1rem;
  }

  .uui-button-14:hover {
    border-color: var(--untitled-ui-primary600);
    background-color: var(--untitled-ui-primary600);
  }

  .uui-pricing04_feature-list {
    margin-bottom: 2rem;
  }

  .uui-pricing04_price {
    font-size: 2.25rem;
  }

  .uui-heading-xxsmall-8 {
    font-size: 1.125rem;
  }

  .uui-pricing04_plan,
  .uui-pricing04_popular-plan {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .uui-pricing04_components {
    margin-top: 3rem;
  }

  .uui-text-size-large-9 {
    font-size: 1rem;
  }

  .uui-space-xsmall-9 {
    min-height: 0.75rem;
  }

  .uui-heading-medium-10 {
    font-size: 1.75rem;
    line-height: 1.4;
  }

  .uui-padding-vertical-xhuge-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .uui-page-padding-18 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .uui-layout25_lightbox-image {
    width: 100%;
    min-width: 100%;
    border-radius: 0.5rem;
    position: static;
  }

  .uui-lightbox_play-icon-5 {
    width: 4rem;
    height: 4rem;
  }

  .uui-layout25_lightbox {
    height: auto;
    margin-top: 3rem;
    padding: 1.5rem 1rem;
  }

  .uui-button-15 {
    font-size: 1rem;
  }

  .uui-button-15:hover {
    border-color: var(--untitled-ui-primary600);
    background-color: var(--untitled-ui-primary600);
  }

  .uui-button-wrapper-13.max-width-full-mobile-landscape {
    width: 100%;
  }

  .max-width-full-mobile-landscape-13 {
    width: 100%;
    max-width: none;
  }

  .uui-button-secondary-gray-13 {
    padding-left: 1.25rem;
    font-size: 1rem;
  }

  .uui-button-secondary-gray-13:hover {
    background-color: var(--untitled-ui-white);
    color: var(--untitled-ui-gray700);
  }

  .uui-button-row-13 {
    align-self: stretch;
  }

  .uui-button-row-13.is-reverse-mobile-landscape {
    flex-direction: column-reverse;
  }

  .uui-heading-xxsmall-9 {
    font-size: 1.125rem;
  }

  .uui-space-xsmall-10 {
    min-height: 0.75rem;
  }

  .uui-layout25_item-list {
    margin-bottom: 2rem;
  }

  .uui-text-size-large-10 {
    font-size: 1rem;
  }

  .uui-heading-medium-11 {
    font-size: 1.75rem;
    line-height: 1.4;
  }

  .uui-layout25_component {
    grid-row-gap: 3rem;
  }

  .uui-padding-vertical-xhuge-17 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .uui-page-padding-19 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .uui-button-16 {
    font-size: 1rem;
  }

  .uui-button-16:hover {
    border-color: var(--untitled-ui-primary600);
    background-color: var(--untitled-ui-primary600);
  }

  .uui-button-wrapper-14.max-width-full-mobile-landscape {
    width: 100%;
  }

  .max-width-full-mobile-landscape-14 {
    width: 100%;
    max-width: none;
  }

  .uui-button-secondary-gray-14 {
    padding-left: 1.25rem;
    font-size: 1rem;
  }

  .uui-button-secondary-gray-14:hover {
    background-color: var(--untitled-ui-white);
    color: var(--untitled-ui-gray700);
  }

  .uui-button-row-14 {
    align-self: stretch;
  }

  .uui-button-row-14.is-reverse-mobile-landscape {
    flex-direction: column-reverse;
  }

  .uui-text-size-large-11 {
    font-size: 1rem;
  }

  .uui-layout08_item-list-2 {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }

  .uui-space-xsmall-11 {
    min-height: 0.75rem;
  }

  .uui-heading-medium-12 {
    font-size: 1.75rem;
    line-height: 1.4;
  }

  .uui-layout08_component-2 {
    grid-row-gap: 3rem;
  }

  .uui-padding-vertical-xhuge-18 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .uui-page-padding-20 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .uui-text-size-xlarge-10 {
    font-size: 1.125rem;
  }

  .uui-space-small-12 {
    min-height: 1.25rem;
  }

  .uui-heading-large-2 {
    font-size: 2.25rem;
  }
}

@media screen and (max-width: 479px) {
  .uui-navbar07_dropdown-link {
    padding-left: 0;
  }

  .uui-layout09_component {
    margin-top: 50px;
    margin-left: 0;
    margin-right: 0;
  }

  .uui-layout13_image-01 {
    width: 80%;
    right: -7%;
  }

  .uui-layout13_image-02 {
    top: 20%;
  }

  .uui-heroheader14_card-image-05,
  .uui-heroheader14_card-image-04,
  .uui-heroheader14_card-image-03,
  .uui-heroheader14_card-image-02,
  .uui-heroheader14_card-image-01 {
    width: 14rem;
    border-radius: 0.5rem;
  }

  .uui-heroheader14_image-wrapper {
    height: 12.75rem;
  }

  .uui-heroheader04_component,
  .uui-layout68_component,
  .form-radio-2col {
    grid-template-columns: 1fr;
  }

  .form-field-2col {
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
  }

  .uui-heroheader01_pattern-image {
    width: 12.125rem;
    bottom: -25%;
    right: -20%;
  }

  .uui-heroheader01_image {
    height: 15rem;
    border-top-left-radius: 4rem;
  }

  .uui-heroheader01_component {
    grid-template-columns: 1fr;
  }

  .uui-footer07_link-list {
    grid-template-rows: auto auto auto;
  }

  .uui-heroheader03_image {
    height: 15rem;
    border-top-right-radius: 2rem;
    border-bottom-left-radius: 2rem;
  }

  .uui-heroheader03_component,
  .uui-contact07_component {
    grid-template-columns: 1fr;
  }

  .uui-heroheader08_image {
    height: 17.5rem;
  }

  .ratings_wrapper {
    flex: none;
    margin-left: 0.75rem;
  }

  .uui-signup-form_wrapper {
    grid-row-gap: 0.75rem;
    flex-direction: column;
    grid-template-columns: 1fr;
  }

  .uui-heroheader08_component {
    grid-template-columns: 1fr;
  }

  .uui-section_heroheader08 {
    overflow: hidden;
  }

  .section {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .uui-heroheader13_component-4 {
    overflow: scroll;
  }

  .uui-section_heroheader13-4 {
    overflow: visible;
  }

  .nav-menu {
    flex-direction: column;
  }

  .nav-button-wrapper {
    width: auto;
  }

  .uui-layout62_image-01 {
    width: 80%;
    right: -7%;
  }

  .uui-layout62_image-02 {
    top: 20%;
  }

  .uui-heroheader08_image-2 {
    height: 17.5rem;
  }

  .ratings_wrapper-2 {
    flex: none;
    margin-left: 0.75rem;
  }

  .uui-signup-form_wrapper-2 {
    grid-row-gap: 0.75rem;
    flex-direction: column;
    grid-template-columns: 1fr;
  }

  .uui-heroheader08_component-2 {
    grid-template-columns: 1fr;
  }

  .uui-section_heroheader08-2 {
    overflow: hidden;
  }

  .table_component {
    width: 800px;
    overflow: scroll;
  }

  .uui-heroheader01_pattern-image-2 {
    width: 12.125rem;
    bottom: -25%;
    right: -20%;
  }

  .uui-heroheader01_image-2 {
    height: 15rem;
    border-top-left-radius: 4rem;
  }

  .uui-heroheader01_component-2 {
    grid-template-columns: 1fr;
  }

  .uui-padding-vertical-xhuge-14 {
    overflow: scroll;
  }

  .uui-heroheader05_component {
    grid-template-columns: 1fr;
  }

  .uui-blogpost01_author-wrapper {
    margin-bottom: 1rem;
  }

  .uui-blogpost01_tag-list {
    justify-content: flex-start;
  }

  .uui-blogpost01_tag-list-wrapper {
    width: 100%;
  }

  .uui-blogpost01_share-wrapper {
    width: 100%;
    margin-bottom: 1.5rem;
  }

  .uui-blogpost01_content-bottom,
  .uui-blogpost01_content-top {
    flex-direction: column;
    align-items: flex-start;
  }

  .uui-layout25_item-list {
    grid-row-gap: 2.5rem;
    grid-template-columns: 1fr;
  }

  .slide {
    margin-bottom: 60px;
  }

  .icon {
    color: rgba(127, 86, 217, 0);
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .icon-2 {
    color: rgba(111, 96, 190, 0);
  }

  .image-9 {
    max-width: 90%;
  }
}

#w-node-_74030901-a80b-d98e-04d2-50f5c9935a6b-c9935a65 {
  justify-self: center;
}

#w-node-_6a7c6b23-a33c-3b1a-3872-a57ec893ae14-c893ae05,
#w-node-_6a7c6b23-a33c-3b1a-3872-a57ec893ae1e-c893ae05 {
  align-self: start;
}

#w-node-_7c106fde-2954-a121-2389-51742e0bc5ca-2e0bc5c3 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_7c106fde-2954-a121-2389-51742e0bc5d0-2e0bc5c3,
#w-node-_7c106fde-2954-a121-2389-51742e0bc5d6-2e0bc5c3,
#w-node-_7c106fde-2954-a121-2389-51742e0bc5dc-2e0bc5c3 {
  align-self: start;
}

#w-node-_7c106fde-2954-a121-2389-51742e0bc5e9-2e0bc5c3 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_7c106fde-2954-a121-2389-51742e0bc5ef-2e0bc5c3,
#w-node-_7c106fde-2954-a121-2389-51742e0bc5f5-2e0bc5c3,
#w-node-_7c106fde-2954-a121-2389-51742e0bc5fb-2e0bc5c3 {
  align-self: start;
}

#w-node-_7c106fde-2954-a121-2389-51742e0bc608-2e0bc5c3 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_7c106fde-2954-a121-2389-51742e0bc60e-2e0bc5c3,
#w-node-_7c106fde-2954-a121-2389-51742e0bc614-2e0bc5c3,
#w-node-_7c106fde-2954-a121-2389-51742e0bc61a-2e0bc5c3 {
  align-self: start;
}

#w-node-_7c106fde-2954-a121-2389-51742e0bc627-2e0bc5c3 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_7c106fde-2954-a121-2389-51742e0bc62d-2e0bc5c3,
#w-node-_7c106fde-2954-a121-2389-51742e0bc633-2e0bc5c3,
#w-node-_7c106fde-2954-a121-2389-51742e0bc639-2e0bc5c3 {
  align-self: start;
}

#w-node-_7c106fde-2954-a121-2389-51742e0bc646-2e0bc5c3 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_7c106fde-2954-a121-2389-51742e0bc64c-2e0bc5c3,
#w-node-_7c106fde-2954-a121-2389-51742e0bc652-2e0bc5c3,
#w-node-_7c106fde-2954-a121-2389-51742e0bc658-2e0bc5c3 {
  align-self: start;
}

#w-node-b537aa51-c461-3d49-70c9-d52dacc6f07b-446eff09,
#w-node-_423b5b12-72da-3a17-b5a0-600f39172271-446eff09,
#w-node-_5a344e49-d151-3855-9397-3c41ba8e5ac0-446eff09,
#w-node-fa6eeee3-fbe9-ce4e-9c06-9e20615a85dd-446eff09 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-b62c15c1-2f66-1401-60c5-2750f3d8a632-4bc1c432,
#w-node-b62c15c1-2f66-1401-60c5-2750f3d8a642-4bc1c432,
#w-node-b62c15c1-2f66-1401-60c5-2750f3d8a652-4bc1c432,
#w-node-_802e68f2-87d4-467e-2ed3-e04f04f8bd8e-4bc1c432 {
  align-self: start;
}

#w-node-_42bbbbbc-579c-618f-9b09-50596882b682-6882b676,
#w-node-_2ca2d588-a438-60c6-a413-40e3f0694c6b-a5e62066 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_332813ba-8c27-c05f-20a1-bdb6677b599a-a5e62066,
#w-node-_332813ba-8c27-c05f-20a1-bdb6677b59a0-a5e62066,
#w-node-_332813ba-8c27-c05f-20a1-bdb6677b59a6-a5e62066,
#w-node-_332813ba-8c27-c05f-20a1-bdb6677b59ac-a5e62066,
#w-node-_332813ba-8c27-c05f-20a1-bdb6677b59b2-a5e62066,
#w-node-_332813ba-8c27-c05f-20a1-bdb6677b59b8-a5e62066,
#w-node-_332813ba-8c27-c05f-20a1-bdb6677b59be-a5e62066,
#w-node-_332813ba-8c27-c05f-20a1-bdb6677b59c4-a5e62066,
#w-node-_332813ba-8c27-c05f-20a1-bdb6677b59ca-a5e62066,
#w-node-_4b876c9a-cfc3-d176-6f1e-069185f9789e-a5e62066,
#w-node-_4b876c9a-cfc3-d176-6f1e-069185f978a3-a5e62066,
#w-node-_4b876c9a-cfc3-d176-6f1e-069185f978a8-a5e62066,
#w-node-_2de960b9-135b-853d-68a6-abfb253997d9-a5e62066,
#w-node-_87669b3a-308e-8af7-6f47-db7673e6923f-a5e62066,
#w-node-_92bb4ce1-9128-c5d6-c682-7fd4edb0806b-a5e62066,
#w-node-_4b876c9a-cfc3-d176-6f1e-069185f978ad-a5e62066,
#w-node-_4b876c9a-cfc3-d176-6f1e-069185f978ca-a5e62066,
#w-node-_4b876c9a-cfc3-d176-6f1e-069185f978cf-a5e62066,
#w-node-_4b876c9a-cfc3-d176-6f1e-069185f978d4-a5e62066,
#w-node-_4b876c9a-cfc3-d176-6f1e-069185f978d9-a5e62066,
#w-node-_5853350a-2d38-c5ec-baba-e646c87d49f0-a5e62066,
#w-node-_0c3f1820-3be9-b28c-125a-f34b2cee1032-a5e62066,
#w-node-aaa837ae-3fc7-81d7-bf3b-f8a27f32464b-a5e62066,
#w-node-_4b876c9a-cfc3-d176-6f1e-069185f978f3-a5e62066,
#w-node-_4b876c9a-cfc3-d176-6f1e-069185f978f8-a5e62066,
#w-node-_4b876c9a-cfc3-d176-6f1e-069185f978fd-a5e62066,
#w-node-_4b876c9a-cfc3-d176-6f1e-069185f97902-a5e62066,
#w-node-af343e0b-eb34-a1e8-68bc-c8225e3167ef-a5e62066,
#w-node-ddd6c1e6-20c4-27b9-49eb-8f0f8cec1cf1-a5e62066,
#w-node-e2029d0d-ee5e-5571-c3ca-d48f71d61bef-a5e62066 {
  align-self: start;
}

#w-node-eaad19bd-ed2c-6afb-d93b-b56ad5435669-f0f94205,
#w-node-e664ecc2-4f43-fde2-de18-90bf6b029a0d-369336ac,
#w-node-e664ecc2-4f43-fde2-de18-90bf6b029a0f-369336ac,
#w-node-e664ecc2-4f43-fde2-de18-90bf6b029a11-369336ac,
#w-node-e664ecc2-4f43-fde2-de18-90bf6b029a13-369336ac,
#w-node-e664ecc2-4f43-fde2-de18-90bf6b029a3a-369336ac,
#w-node-e664ecc2-4f43-fde2-de18-90bf6b029a3c-369336ac,
#w-node-e664ecc2-4f43-fde2-de18-90bf6b029a3e-369336ac,
#w-node-e664ecc2-4f43-fde2-de18-90bf6b029a40-369336ac,
#w-node-eaad19bd-ed2c-6afb-d93b-b56ad5435669-25ef45b8 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_750b3ddf-98fb-4a5a-d18d-6884c2c462e2-25ef45b8,
#w-node-_750b3ddf-98fb-4a5a-d18d-6884c2c462e8-25ef45b8,
#w-node-_750b3ddf-98fb-4a5a-d18d-6884c2c462ee-25ef45b8,
#w-node-_28b6b126-6e94-90c1-4411-ef49dcc1e0ef-25ef45b8,
#w-node-_21805c69-7ff6-4cd4-c442-7485c2e929f9-25ef45b8,
#w-node-_21805c69-7ff6-4cd4-c442-7485c2e92a01-25ef45b8,
#w-node-_21805c69-7ff6-4cd4-c442-7485c2e92a09-25ef45b8,
#w-node-_21805c69-7ff6-4cd4-c442-7485c2e92a11-25ef45b8 {
  align-self: start;
}

#w-node-eaad19bd-ed2c-6afb-d93b-b56ad5435669-ad493674 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_21805c69-7ff6-4cd4-c442-7485c2e929f9-ad493674,
#w-node-_21805c69-7ff6-4cd4-c442-7485c2e92a01-ad493674,
#w-node-_21805c69-7ff6-4cd4-c442-7485c2e92a09-ad493674,
#w-node-_21805c69-7ff6-4cd4-c442-7485c2e92a11-ad493674,
#w-node-fc54ff66-7c69-6940-08a7-5f038137cf67-ad493674 {
  align-self: start;
}

#w-node-_5c6fd7de-71f2-778e-b61f-c2d9e96678dc-ad493674 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

@media screen and (max-width: 991px) {
  #w-node-_74030901-a80b-d98e-04d2-50f5c9935a6b-c9935a65 {
    justify-self: center;
  }
}

@media screen and (max-width: 767px) {
  #w-node-b62c15c1-2f66-1401-60c5-2750f3d8a630-4bc1c432 {
    order: -9999;
  }

  #w-node-_6ab544a1-8a96-4bea-30f3-44f35274750b-f0f94205 {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }
}

@media screen and (max-width: 479px) {
  #w-node-_74030901-a80b-d98e-04d2-50f5c9935a6b-c9935a65 {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }

  #w-node-_7c106fde-2954-a121-2389-51742e0bc5ca-2e0bc5c3,
  #w-node-_7c106fde-2954-a121-2389-51742e0bc5e9-2e0bc5c3,
  #w-node-_7c106fde-2954-a121-2389-51742e0bc608-2e0bc5c3,
  #w-node-_7c106fde-2954-a121-2389-51742e0bc627-2e0bc5c3,
  #w-node-_7c106fde-2954-a121-2389-51742e0bc646-2e0bc5c3 {
    justify-self: center;
  }

  #w-node-b537aa51-c461-3d49-70c9-d52dacc6f07b-446eff09,
  #w-node-_423b5b12-72da-3a17-b5a0-600f39172271-446eff09,
  #w-node-_5a344e49-d151-3855-9397-3c41ba8e5ac0-446eff09,
  #w-node-fa6eeee3-fbe9-ce4e-9c06-9e20615a85dd-446eff09 {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }
}