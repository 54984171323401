.verifyemail{
    color: #000e5e !important;
    font-weight: 600;
    padding-bottom: 7px;
    font-family: 'Poppins', sans-serif;

}
#verifyemail span{
    text-align: center;
    font-size: 12px;
    padding: 0px 40px;
}
.text-cl{
    color: #4b4848;
    font-weight: 600;
}
.text-cl a{
    color: #7F56D9;
    font-weight: bold;
}
.main-content{
    height: 100vh;
}
.loginbtn{
    margin-bottom: 1.5rem;
    margin-top: 0.5rem;
}