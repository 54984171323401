ul.navbar-nav li {
	margin-right: 22px;
	color: #444444;
}

li.nav-item i {
	font-size: 20px;
	color: #7f56d9;
}

.fontSize-2rem {
	font-size: 1.5rem !important;
}

.dropdown-item i {
	font-size: 15px;
}


nav#navbarBlur {
	background: #fdfdfd;
	margin: 5px 10px !important;
}

.main-menu {
	background-color: rgb(245, 245, 251);
}

.dropdown .dropdown-toggle:after,
.dropup .dropdown-toggle:after {
	content: none;
}

.primary-menu ul li {
	margin-right: 30px;
	font-weight: 600;
	font-size: 15px;
	padding-top: 5px;
}

.dropdown ul:not(:last-child) {
	padding-bottom: 5px;
}

.dropdown ul li {
	margin-right: 0px !important;
}

.dropdown ul li a {
	color: #7f56d9;
	font-weight: 600;
}

.dropdown ul li:not(:last-child) {
	border-bottom: 1px solid #ddd;
}

li.nav-item svg {
	margin-top: -3px;
	width: 20px;
	height: 20px;
}

.company-name2 {
	color: #7f56d9;
	font-weight: 700;
	text-transform: uppercase;
	margin-top: 7px !important;
}

.navbar-brand p {
	font-size: 15px;
	text-transform: uppercase;
	font-weight: 600;
}

.text-gradient.text-primary {
	background-image: linear-gradient(310deg, #7928ca, #6941c6) !important;
}

.dropdown.dropdown-hover:hover>.dropdown-menu,
.dropdown .dropdown-menu.show {
	opacity: 1;
	pointer-events: auto;
	visibility: visible;
	transform: perspective(999px) rotateX(0deg) translateZ(0) translate3d(0, 37px, 0px) !important;
}

#mobile-header {
	display: none;
}

.profilePhoto {
	height: 38px;
	width: 38px;
	margin-right: 5px;
}

.profilePhoto img {
	background-size: cover;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.nav-link span {
	color: #8892a7;
}

.avatar-dropdown-menu {
	position: absolute;
	z-index: 999;
	width: 210px;
	padding: 8px !important;
	left: 2px;
	top: 48px;
	/* margin-top: 10px; */
	border-radius: 6px;
}

.avatar-dropdown-menu li i {
	font-size: 14px;
}

.avatar-dropdown-menu ul li:hover {
	background: #e9e9e9ba;
	/* margin-bottom: 5px; */
	border-radius: 6px;
	transition: 0.3s;
}

.avatar-dropdown-menu ul {
	list-style: none;
	margin: 0px;
	padding: 0px 2px;
}

.avatar-dropdown-menu ul li {
	/* padding: 0px 10px; */
	cursor: pointer;
	padding: 6px 14px;
	margin-bottom: 5px;
	padding-right: 0px !important;
	margin-right: 0px !important;
}

.avatar-dropdown-menu ul li {
	padding: 5px 13px;
}

/* mobile view */
@media only screen and (max-width: 767px) {
	#mobile-header {
		display: block;
	}

	#desktop-header {
		display: none;
	}

	.footer-div {
		position: relative;
	}

	#mobile-header #navbar ul li {
		margin-left: 16px;
	}

	#mobile-header #navbarBlur {
		margin: 5px 0px !important;
	}

	.navbar-vertical.navbar-expand-xs {
		display: unset;
		max-width: fit-content;
		position: unset !important;
	}
}

.notificationConatiner {
	max-height: calc(100vh - 200px) !important;
	overflow-y: auto;
	min-width: 250px;
}