@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&family=Poppins:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700&family=Urbanist:wght@100;200;300;400;500;600;700;800&display=swap');
.main-content{
background-color: rgb(245, 245, 251);
}
.bg-gradient-dark {
    /* background-image: linear-gradient(310deg, #ccbeec 0%, #6941c6 100%) !important; */
    background-image: linear-gradient(310deg, #7F56D9 0%, #9E77ED 100%) !important;
}
.dropdown-toggle::after{
    content: none;
}
.customerUserTable tr th {
    font-size: 0.8rem ;
    color: #000333 ;
    font-family: 'Poppins', sans-serif;
}
#adduser{
    font-size: 0.9rem;
}

