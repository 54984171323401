.selectOption {
	display: flex;
	justify-content: space-between;
}

.allProgramFilter {
	position: relative;
	width: 100%;
	background-color: #fff;
	height: 100%;
}
.allProgramFilter input {
	width: 100%;
	padding-left: 20px;
	padding-right: 40px;
	height: 100%;
	border: 1px solid #E1E1E1;
}
.allProgramFilter input:focus {
	border: 2px solid blue;
	outline: none;
}
.allProgramFilterDownArrow {
	position: absolute;
	top: 10px;
	right: 13px;
}
.allProgramFilterOptions {
	z-index: 999;
	position: absolute;
	width: 100%;
	height: 300px;
	overflow-y: scroll;
	background-color: #fff;
	top: 50px;
    border: 1px solid #E1E1E1;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}


.allProFilteroption {
    transition: .4s all;
	background-color: #fff;
	
	padding: 10px;
	display: flex;
	justify-content: space-between;
}

.selectFilterFieldValue{
    background-color: transparent;
	
	padding: 10px;
	display: flex;
	justify-content: space-between;
}
.selectFilterField{
    position: absolute;
    width: 90%;
    top: 0;
    right: 2px;
}
.allProFilteroption:hover{
    background-color: #2484FF;
}
.allProFilteroption:hover > .optName{
   color: #fff;
}
.optName {
    padding-left: 20px;
	font-weight: 500;
    color: #000;
}
.optNum { 
    color: #000;
    font-weight: 500;
	background-color: #f5f5f5;
	font-size: 11px;
	margin-right: 30px;
	padding: 6px 10px;
	border-radius: 9px;
}
.allProFilteroption:hover > .optNum{
   
   background-color: #fff;
}
